import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Modal,
  SelectChangeEvent,
  Hidden,
  Dialog,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DynamicTable from "../../Dynamic-table";
import { toast } from "react-toastify";
import MedicineForm from "../add-new-medicine/medicine-form";
import AddIcon from "@mui/icons-material/Add";
import MedicineScheduleForm from "../../Modals/delete-modal/medicine-schedule-modal";
import MedicineAdministrationModal from "../../Modals/adm-route-modal";
import debounce from "lodash.debounce";
import { log } from "node:console";
import CustomAutocomplete from "../../autocomplete-field";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchProjectProfileData } from "../../../redux/reducers/projectProfileSlice";
import dayjs from "dayjs";

export interface MedicineDose {
  givenBy?: any;
  name: string;
  dosage: string;
  duration: string;
  durationUnit?: string;
  frequency?: {
    abr_notation: string;
    eng_notation: string;
    math_notation: string;
    urdu_notation: string;
  };
  note: string;
  medicineId: string;
  id: number;
  instructions?: string;
  medicineUnit?: string;
  strength?: number;
  routAdministrationId?: string;
  dateTime?: any;
  doctorName?: string;
}

export interface MedicineDetails {
  dosage?: string;
  duration?: string;
  durationUnit?: string;
  frequency?: {
    abr_notation: string;
    eng_notation: string;
    math_notation: string;
    urdu_notation: string;
  };
  instructions?: string;
  routAdministrationId?: string;
  note: string;
}
export interface Schedule {
  eng_notation: any;
  math_notation: any;
  urdu_notation: any;
  abr_notation: any;
  _id: string;
  frequency: {
    abr_notation: string;
    eng_notation: string;
    math_notation: string;
    urdu_notation: string;
  };
  projectId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  isActive: boolean;
}
interface Props {
  onMedicineDoses: (medicineDoses: MedicineDose[], deletedDose?: any) => void;
  medicineDataIfExist?: MedicineDose[];
  setMedicineData: (value: any[]) => void;
  medicineData: any;
  treatment?: boolean | undefined;
  dateTime?: any;
  doctorName?: any
  // Callback function type definition
}
const MedicineDoses: React.FC<Props> = ({
  onMedicineDoses,
  medicineDataIfExist = [],
  setMedicineData,
  medicineData,
  treatment,
  dateTime,
  doctorName
}) => {
  const [allMedicine, setAllMedicine] = useState<any[]>([]); // Array of all medicines
  const [selectedMedicine, setSelectedMedicine] = useState<any>(null); // Selected medicine
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [shortCourse, setShortCourse] = useState<MedicineDetails>({
    dosage: undefined,
    duration: undefined,
    durationUnit: "Days", // Ensure this is set to a default value
    frequency: {
      abr_notation: "",
      eng_notation: "",
      math_notation: "",
      urdu_notation: "",
    },
    instructions: "", // Ensure this is an empty string if not provided
    note: "",
    routAdministrationId: "",
  });
  const [medicineDoses, setMedicineDoses] = useState<MedicineDose[]>([]); // Array of added medicine doses
  const [open, setOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [routeAdm, setRouteAdm] = useState<any>();
  const [openFreq, setOpenFreq] = useState<boolean>(false);
  const [openRoute, setOpenRoute] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const noteFieldRef = useRef<HTMLInputElement>(null);
  const [isMultiline, setIsMultiline] = useState(false);
  const profile = useSelector((state: any) => state.profileSlice.profile);
  const dispatch = useDispatch()
  useEffect(() => {
    if (treatment) {
      dispatch(fetchProjectProfileData() as any)
    }
  }, [dispatch, treatment])
  const handleFocus = () => {
    setIsMultiline(true);
  };

  const handleBlur = () => {
    setIsMultiline(false);
  };
  useEffect(() => {
    if (selectedMedicine && noteFieldRef.current) {
      noteFieldRef.current.focus(); // Focus on the note field when a medicine is selected
    }
  }, [selectedMedicine]);

  const handleCloseRoute = () => setOpenRoute(!openRoute);

  const handleCloseFreq = () => setOpenFreq(!openFreq);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (medicineDataIfExist?.length > 0) {
      setMedicineDoses(medicineDataIfExist);
    }
  }, [medicineDataIfExist]);

  useEffect(() => {
    fetchSchedules();
  }, []);
  const debouncedGetMedicine = useCallback(
    debounce(async (search: string) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_OPD_API_URL}/medicine/getAllSelectAbleMedicine?search=${search}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const fetchedMedicine = response.data?.data || [];
        setAllMedicine(fetchedMedicine);
      } catch (error) {
        console.error("Error fetching medicine:", error);
      }
    }, 500), // Debounce time: 500ms
    []
  );

  useEffect(() => {
    // Fetch medicines when the component mounts
    debouncedGetMedicine(searchTerm);
  }, [debouncedGetMedicine, searchTerm]);

  const handleInputChange = (event: any, newInputValue: string) => {
    setSearchTerm(newInputValue);

    debouncedGetMedicine(newInputValue);
  };

  const fetchMedicineDetails = async (medicineId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/medicine/getById/${medicineId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const medicineDetails = response.data?.data || {};

      setShortCourse({
        dosage: medicineDetails?.dosage || "", // Ensure default value
        duration: medicineDetails?.duration || "", // Ensure default value
        durationUnit: medicineDetails?.durationUnit || "Days", // Ensure default value
        frequency: medicineDetails?.frequency || {
          abr_notation: "",
          eng_notation: "",
          math_notation: "",
          urdu_notation: "",
        },
        instructions: medicineDetails?.instruction || "", // Ensure default value
        note: medicineDetails?.note || "", // Ensure default value
        routAdministrationId: medicineDetails?.routAdministrationId || "",
      });
    } catch (error) {
      console.error("Error fetching medicine details:", error);
    }
  };

  useEffect(() => {
    getRouteAdm();
  }, []);
  useEffect(() => {
    setMedicineDoses(medicineData);
    onMedicineDoses(medicineData);
  }, [medicineData]);
  const handleSelectChangeMedicine = (newValue: any) => {
    setSearchTerm("");
    if (newValue) {
      const isDuplicate = medicineData.some(
        (medicine: any) => medicine.name === newValue.name
      );
      if (!isDuplicate) {
        setSelectedMedicine(newValue);
      } else {
        toast.error(
          "Please select some different medicine it is already included."
        );
        setSelectedMedicine(null);
        return;
      }
    }
    setSelectedMedicine(newValue);

    if (newValue) {
      fetchMedicineDetails(newValue._id);
    } else {
      setShortCourse({
        dosage: "",
        duration: "",
        durationUnit: "Days",
        frequency: {
          abr_notation: "",
          eng_notation: "",
          math_notation: "",
          urdu_notation: "",
        },
        instructions: "",
        routAdministrationId: "",
        note: "",
      });
    }
  };

  const addUpdatedDetailsOfMedicine = async (shortCourseData: any) => {
    try {
      // Extract relevant data from selectedMedicine and shortCourseData
      const {
        routAdministrationId = "",
        dosage,
        frequency,
        duration,
        durationUnit,
        note,
        instructions = "", // Use the instruction from shortCourseData
      } = shortCourseData;

      // Construct the payload according to the API requirements
      const payload = {
        routAdministrationId: routAdministrationId,
        dosage: dosage,
        frequency: {
          abr_notation: frequency.abr_notation,
          eng_notation: frequency.eng_notation,
          urdu_notation: frequency.urdu_notation,
          math_notation: frequency.math_notation,
        },
        duration: duration, // Append unit to duration
        durationUnit: durationUnit,
        note: note,
        instruction: instructions, // Use instructions from shortCourseData
      };

      // Make the API request
      const response = await axios.patch(
        `${process.env.REACT_APP_OPD_API_URL}/medicine/updateMedicineDosage/${selectedMedicine?._id || selectedMedicine?.medicineId
        }`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Handle the response if needed
      // Optionally, show a success message or update state
    } catch (error) {
      console.error("Error updating medicine details:", error);
      // Optionally, show an error message
    }
  };

  const handleAddButtonClick = () => {
    const currentDate = dayjs().format('DD.MM.YYYY hh:mm A');

    if (selectedMedicine) {

      const newMedicineDose: MedicineDose = {
        name: selectedMedicine.medicineName,
        medicineId: selectedMedicine?._id || selectedMedicine?.medicineId,
        dosage: shortCourse.dosage as string,
        duration: shortCourse.duration
          ? ((shortCourse.duration + " " + shortCourse.durationUnit) as string)
          : "",
        // durationUnit: shortCourse.durationUnit as string,
        frequency: shortCourse.frequency,
        instructions: shortCourse.instructions as string,
        note: shortCourse.note as string,
        routAdministrationId: shortCourse?.routAdministrationId,
        strength: selectedMedicine.strength as number,
        medicineUnit: selectedMedicine.medicineUnit as string,
        id: Date.now(),
        dateTime: currentDate, // Default to null if not treatment
        doctorName: profile?.data?.fullName
      };

      // Check for duplicate medicineId
      const isDuplicate = medicineData.some(
        (medicine: any) => medicine.medicineId === newMedicineDose.medicineId
      );

      if (!isDuplicate) {
        const updatedMedicineDoses = [...medicineDoses, newMedicineDose];
        setMedicineData(updatedMedicineDoses);
        setMedicineDoses(updatedMedicineDoses);
        onMedicineDoses(updatedMedicineDoses);
        addUpdatedDetailsOfMedicine(shortCourse);
      } else {
        toast.error(
          "Please select some different medicine it is already included."
        );
      }

      setSelectedMedicine(null);
      setShortCourse({
        dosage: "",
        duration: "",
        durationUnit: "Days",
        frequency: {
          abr_notation: "",
          eng_notation: "",
          math_notation: "",
          urdu_notation: "",
        },
        instructions: "",
        note: "",
      });
    }
  };

  const fetchSchedules = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_OPD_API_URL}/dosage-schedules/getAllByPagination?count=50000`,
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setSchedules(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRouteAdm = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/getAllByPagination`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          count: 50000,
          pageNo: 1,
        },
      }
    );
    setRouteAdm(response.data?.data);
  };
  const handleEditClick = (doseToEdit: any) => {
    // const doseToEdit = medicineDoses.find((dose) => dose.medicineId === id);
    if (selectedMedicine) {
      toast.error(
        `${selectedMedicine.medicineName} Already selected for update.`
      );
      return;
    }
    if (doseToEdit) {
      setSelectedMedicine({
        ...doseToEdit,
        medicineName: doseToEdit.name,
      });
      setShortCourse({
        dosage: doseToEdit?.dosage,
        duration: doseToEdit?.duration?.split(" ")[0],
        durationUnit:
          doseToEdit?.duration && doseToEdit?.duration?.split("")[1]
            ? doseToEdit?.duration?.split(" ")[1]
            : "Days",
        frequency: doseToEdit?.frequency,
        routAdministrationId: doseToEdit?.routAdministrationId,
        instructions: doseToEdit?.instructions,
        note: doseToEdit?.note,
      });
      setMedicineDoses(
        medicineDoses?.filter(
          (dose) => dose?.medicineId !== doseToEdit?.medicineId
        )
      );
      setMedicineData(
        medicineDoses?.filter(
          (dose) => dose?.medicineId !== doseToEdit?.medicineId
        )
      );
    }
  };

  const handleDeleteClick = (id: string) => {
    const deletedDose = medicineDoses?.find((dose) => dose.medicineId === id); // Find the deleted medicine
    const updatedMedicineDoses = medicineDoses?.filter((dose) => dose.medicineId !== id);
    if (deletedDose) {
      // const updatedMedicineDoses = medicineDoses?.filter((dose) => dose.medicineId !== id);
      setMedicineData(updatedMedicineDoses);
      setMedicineDoses(updatedMedicineDoses);

      // Get the current date and time
      const deletedTime = dayjs().format('YYYY-MM-DD HH:mm:ss');

      // Get the username from the profile
      const deletedBy = profile?.data?.fullName; // Default if not available

      // Call the parent's onMedicineDoses with updated data and the deleted dose info
      onMedicineDoses(updatedMedicineDoses, {
        medicine: deletedDose,
        deletedTime,
        deletedBy,
      });
    }
    setMedicineData(updatedMedicineDoses);
    setMedicineDoses(updatedMedicineDoses);

    // Call the parent's onMedicineDoses with updated data and the deleted dose
    onMedicineDoses(updatedMedicineDoses);

    // setMedicineData(medicineDoses.filter((dose) => dose.medicineId !== id));
    // setMedicineDoses(medicineDoses.filter((dose) => dose.medicineId !== id));
    // onMedicineDoses(medicineDoses.filter((dose) => dose.medicineId !== id)); // Send updated data to parent component
  };

  const handleScheduleChange = (
    event: SelectChangeEvent<any>,
    child: ReactNode
  ) => {
    const selectedScheduleId = event.target.value as string; // Assuming value is the _id of the selected schedule

    // Find the selected schedule object based on its _id
    const selectedSchedule = schedules.find(
      (schedule) => schedule._id === selectedScheduleId
    );

    if (selectedSchedule) {
      setShortCourse({
        ...shortCourse,
        frequency: {
          abr_notation: selectedSchedule.abr_notation,
          eng_notation: selectedSchedule.eng_notation,
          math_notation: selectedSchedule.math_notation,
          urdu_notation: selectedSchedule.urdu_notation,
        },
      });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      if (shortCourse.frequency?.abr_notation)
        handleAddButtonClick();
    }
  };
  return (
    <Box>
      <form onKeyPress={handleKeyPress}>

        <Grid container spacing={1}>
          <Grid item md={3} sm={4} xs={6}>
            {/* <Typography
            sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
          >
            Medicine :
          </Typography> */}
            {!selectedMedicine && (
              <Box>
                <Autocomplete
                  size="small"
                  value={selectedMedicine}
                  onChange={(event, newValue) => {
                    handleSelectChangeMedicine(newValue);
                  }}
                  options={allMedicine}
                  getOptionLabel={(option) =>
                    option
                      ? `${option.medicineName} (${option?.genericName || ""})`
                      : ""
                  }
                  filterOptions={(options, state) => {
                    const searchTerm = state.inputValue.toLowerCase();
                    if (searchTerm === "") {
                      // If search term is empty, show all medicines
                      return allMedicine;
                    }
                    // Filter based on search term
                    return options?.filter(
                      (option) =>
                        option.medicineName?.toLowerCase().includes(searchTerm) ||
                        option.genericName?.toLowerCase().includes(searchTerm)
                    );
                  }}
                  renderOption={(props, option) => (
                    <Grid
                      container
                      component="li"
                      sx={{
                        px: 2,
                        border: "2px solid #D8D8D8",
                        background: "#F5F5FB",
                      }}
                      {...props}
                    >
                      <Grid item md={12}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "900",
                            color: "#000",
                          }}
                        >
                          {option.medicineName}{" "}
                          <span
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "normal",
                              color: "#7D7D7D",
                            }}
                          >
                            {option.strength} {option.medicineUnit}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Typography sx={{ fontSize: "8px", color: "#7D7D7D" }}>
                          {option.genericName}
                          {/* <span
                          style={{
                            float: "right",
                            fontSize: "10px",
                            fontWeight: "normal",
                          }}
                        >
                          {option.companyName}
                        </span> */}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Medicine"
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                            <AddIcon
                              onClick={() => {
                                handleOpen();
                              }}
                              sx={{
                                background: "#FFFFFF",
                                color: "#055FFC",
                                borderRadius: "50%",
                                p: 0.2,
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            />
                          </React.Fragment>
                        ),
                      }}
                      className="search-field"
                      onChange={(e) => {
                        handleInputChange(e, e.target.value);
                      }}
                    />
                  )}
                />
              </Box>
            )}

            {selectedMedicine && (
              <Box
                sx={{
                  px: 2,
                  borderRadius: "12px",
                  border: "2px solid #D8D8D8",
                  background: "#F5F5FB",
                  position: "relative",
                  height: "38px",
                }}
              // onClick={() => handleSelectChangeMedicine(null)}
              >
                <button
                  style={{
                    background: "#F3F3F4",
                    color: "#055FFC",
                    borderRadius: "50%",
                    padding: "1.6px",
                    paddingRight: "8px",
                    paddingLeft: "8px",
                    fontWeight: "500",
                    cursor: "pointer",
                    fontSize: "14px",
                    position: "absolute",
                    top: "-12px",
                    right: "-10px",
                  }}
                  onClick={() => handleSelectChangeMedicine(null)}
                >
                  x
                </button>

                <Typography
                  sx={{ fontSize: "10px", fontWeight: "900", color: "#000" }}
                >
                  {selectedMedicine.medicineName}{" "}
                  <span
                    style={{
                      float: "right",
                      fontSize: "8px",
                      fontWeight: "normal",
                      color: "#7D7D7D",
                    }}
                  >
                    {selectedMedicine.strength}
                    {selectedMedicine.medicineUnit}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: "8px", color: "#7D7D7D" }}>
                  {selectedMedicine?.genericName}
                  {/* <span
                  style={{
                    float: "right",
                    fontSize: "10px",
                    fontWeight: "normal",
                  }}
                >
                  {selectedMedicine.genericName}
                </span> */}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* <Typography
          sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
        >
          Instruction :
        </Typography> */}
          {/* <Grid item md={3} sm={4} xs={12}>
          <Box>
            <TextField
              name="instructions"
              placeholder="Instruction"
              value={shortCourse?.instructions || ""} // Ensure default value
              onChange={(e) =>
                setShortCourse({ ...shortCourse, instructions: e.target.value })
              }
              fullWidth
              size="small"
              className="search-field"
            />
          </Box>
        </Grid> */}
          <Grid item md={treatment ? 1 : 1.7} sm={4} xs={6}>
            {/* <Typography
            sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
          >
            Dosage :
          </Typography> */}
            <Box>
              <TextField
                inputRef={noteFieldRef}
                size="small"
                name="dosage"
                placeholder="Dosage"
                type="text"
                value={shortCourse.dosage}
                onChange={(e) =>
                  setShortCourse({
                    ...shortCourse,
                    dosage: e.target.value || undefined,
                  })
                }
                fullWidth
                required
                className="search-field"
              />
            </Box>
          </Grid>

          <Grid item md={treatment ? 1.4 : 2.4} sm={4} xs={6}>
            {/* <Typography
            sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
          >
            Route <span style={{ fontSize: "8px" }}>(optional)</span>:
          </Typography> */}
            <FormControl
              fullWidth
              size="small"
              sx={
                {
                  // '& .MuiInputBase-root': {
                  //     background: '#F5F5FB',
                  //     borderRadius: "12px",
                  //     border: '1px solid #D8D8D8',
                  //     fontSize: '12px'
                  // }
                }
              }
            >
              <Autocomplete
                options={routeAdm || []}
                getOptionLabel={(option) => option.RA_Abbreviation || ""}
                value={
                  routeAdm?.find(
                    (route: any) => route._id === shortCourse.routAdministrationId
                  ) || null
                }
                onChange={(event, newValue) => {
                  setShortCourse({
                    ...shortCourse,
                    routAdministrationId: newValue ? newValue._id : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Route"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                          <AddIcon
                            onClick={() => setOpenRoute(true)}
                            sx={{
                              background: "#FFFFFF",
                              color: "#055FFC",
                              borderRadius: "50%",
                              p: 0.2,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          />
                        </React.Fragment>
                      ),
                    }}
                    className="search-field"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item md={treatment ? 1.4 : 2.4} sm={4} xs={6}>
            {/* <Typography
            sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
          >
            Frequency :
          </Typography> */}
            <Box>
              <Autocomplete
                size="small"
                value={shortCourse?.frequency?.abr_notation || ""}
                onChange={(event, newValue) => {
                  // Ensure newValue is a string, not an object
                  if (newValue) {
                    const selectedSchedule = schedules?.find(
                      (schedule) => schedule?.abr_notation === newValue
                    );
                    if (selectedSchedule) {
                      setShortCourse({
                        ...shortCourse,
                        frequency: {
                          abr_notation: selectedSchedule.abr_notation,
                          eng_notation: selectedSchedule.eng_notation,
                          math_notation: selectedSchedule.math_notation,
                          urdu_notation: selectedSchedule.urdu_notation,
                        },
                      });
                    }
                  } else {
                    setShortCourse({
                      ...shortCourse,
                      frequency: {
                        abr_notation: "",
                        eng_notation: "",
                        math_notation: "",
                        urdu_notation: "",
                      },
                    });
                  }
                }}
                options={
                  schedules
                    ? schedules.map((schedule) => schedule.abr_notation)
                    : []
                } // Ensure options is always an array
                getOptionLabel={(option) => option || ""} // Display the option as a string
                filterOptions={(options, { inputValue }) =>
                  options.filter((option: any) =>
                    option?.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Freq."
                    variant="outlined"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                          <AddIcon
                            onClick={() => setOpenFreq(true)}
                            sx={{
                              background: "#FFFFFF",
                              color: "#055FFC",
                              borderRadius: "50%",
                              p: 0.2,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          />
                        </React.Fragment>
                      ),
                    }}
                    className="search-field"
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item md={2.5} sm={treatment ? 3 : 4} xs={12}>
            {/* <Typography
            sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
          >
            Duration :
          </Typography> */}
            <Box display="flex" gap={1}>
              {/* <TextField
                size="small"
                name="durationNumber"
                placeholder="Dur."
                type="number"
                value={shortCourse?.duration}
                onChange={(e) =>
                  setShortCourse({
                    ...shortCourse,
                    duration: parseInt(e.target.value) >= 0 ? e.target.value : "",
                  })
                }
                required
                className="search-field"
              /> */}
              <TextField
                size="small"
                name="durationNumber"
                placeholder="Dur."
                type="number"
                value={shortCourse?.duration}
                onChange={(e) => {
                  // Parse the value to integer
                  const value = parseInt(e.target.value);
                  // Ensure value is a valid number and <= 99
                  if (!isNaN(value) && value >= 0 && value <= 99) {
                    setShortCourse({
                      ...shortCourse,
                      duration: value.toString(),
                    });
                  } else if (e.target.value === "") {
                    // Allow empty input
                    setShortCourse({
                      ...shortCourse,
                      duration: "",
                    });
                  }
                }}
                required
                className="search-field"
              />


              <Select
                labelId="duration-unit-label"
                value={shortCourse.durationUnit || "Days"} // Ensure default value
                onChange={(e) =>
                  setShortCourse({ ...shortCourse, durationUnit: e.target.value })
                }
                placeholder="Units"
                name="durationUnit"
                required
                size="small"
                sx={{
                  width: "67%",
                  borderRadius: "12px",
                  // border: "1px solid #D8D8D8",
                  background: "#F5F5FB",
                  fontSize: "12px",
                  boxShadow: "none", // Remove any default shadow that might look like a border
                  "& fieldset": {
                    // Target the fieldset for better border control
                    border: "none", // Remove default border if present
                  },
                }}
              >
                <MenuItem value="Minutes">Minutes</MenuItem>
                <MenuItem value="Hours">Hours</MenuItem>
                <MenuItem value="Days">Days</MenuItem>
                <MenuItem value="Weeks">Weeks</MenuItem>
                <MenuItem value="Months">Months</MenuItem>
                <MenuItem value="Years">Years</MenuItem>
                <MenuItem value="Lifetime">Lifetime</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item md={treatment ? 1.6 : 10.5} sm={treatment ? 3.3 : 10.5} xs={10}>
            <Box>
              {/* <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Note :
            </Typography> */}
              <TextField
                name="note"
                placeholder="Note"
                value={shortCourse.note}
                onChange={(e) =>
                  setShortCourse({ ...shortCourse, note: e.target.value })
                }
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "12px",
                    background: "#F5F5FB",
                    fontSize: "13px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    top: "-2px",
                  },
                }}
                onFocus={

                  handleFocus
                }
                onBlur={handleBlur}
                multiline={isMultiline}
                minRows={isMultiline ? 3 : 1}
              />
            </Box>
          </Grid>
          <Grid item md={1} sm={treatment ? 1.6 : 2} xs={2}>
            <Box mt={.6}>
              <Button size="small"
                // sx={{ px: 1, py: 0.7 }}
                disabled={
                  !selectedMedicine || !shortCourse.frequency?.abr_notation
                }
                variant="contained"
                color="primary"
                onClick={handleAddButtonClick}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            width: "50vw",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <MedicineForm
            selectedRowData={selectedRowData}
            onSubmit={handleClose}
          />
        </div>
      </Modal>
      {/* Table to display added medicine doses */}
      {/* <DynamicTable headers={["SN", "Medicine", "Strength", "Unit", "Dosage", "Route", "Frequency", "Duration", "Action"]}
                data={medicineData?.map((item: any, index: number) => [
                    index + 1,
                    item?.name,
                    item?.strength,
                    item?.medicineUnit,
                    item?.dosage,
                    (() => {
                        const route = routeAdm?.find((route: any) => route._id === item?.routAdministrationId);
                        return route ? `${route.RA_Abbreviation}` : '-';
                    })(),
                    item?.frequency?.abr_notation,
                    item.duration,
                    // item.durationUnit,
                    // item?.instructions,
                ])}
                actionButtons={[
                    {
                        icon: <EditIcon sx={{ color: '#FFB800', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(133, 96, 1)' } }} />,
                        onClick: (rowIndex: number) => handleEditClick(medicineData[rowIndex]?.medicineId)
                    },
                    {
                        icon: <DeleteIcon sx={{ color: '#FF5454', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(191, 44, 44)' } }} />,
                        onClick: (rowIndex: number) => handleDeleteClick(medicineData[rowIndex]?.medicineId)
                    }
                ]}
            /> */}

      <Box
        mt={1}
        mb={1}
        sx={{
          background: "#fff",
          borderRadius: "8px",
          maxHeight: "17rem",
          // overflowY: 'auto',
          // '&::-webkit-scrollbar': {
          //     width: '4px', // Make the scrollbar thinner
          // },
          // '&::-webkit-scrollbar-track': {
          //     background: '#fff',
          //     borderRadius: '12px',
          // },
          // '&::-webkit-scrollbar-thumb': {
          //     backgroundColor: '#077DD3',
          //     borderRadius: '12px',
          // },
          // scrollbarWidth: 'thin', // For Firefox
          // scrollbarColor: '#077DD3 #fff', // For Firefox
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "17rem",
            "&::-webkit-scrollbar": {
              width: "4px", // Make the scrollbar thinner
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
              borderRadius: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#077DD3",
              borderRadius: "12px",
            },
            scrollbarWidth: "thin", // For Firefox
            scrollbarColor: "#077DD3 #fff", // For Firefox
          }}
        >
          <Table stickyHeader aria-label="medicine table">
            <TableHead>
              <TableRow>
                {[
                  "SN",
                  "Medicine",
                  // "Strength",
                  // "Unit",
                  "Dosage",
                  "Route",
                  "Frequency",
                  "Duration",
                  treatment ? "Doctor Name" : null,
                  treatment ? "Date & Time" : null,
                  "Action",
                ]
                  ?.filter(header => header) // Filter out any null headers
                  ?.map((header) => (
                    <TableCell
                      key={header}
                      style={{
                        backgroundColor: "#1976d2",
                        color: "white",
                        fontSize: 12,
                        padding: "4px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {medicineData?.map((item: any, index: any) => (
                <TableRow
                  key={index}
                  style={{ backgroundColor: index % 2 ? "#f5f5f5" : "white" }}
                >
                  <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {item?.name}
                  </TableCell>
                  {/* <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {item?.strength}
                  </TableCell>
                  <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {item?.medicineUnit}
                  </TableCell> */}
                  <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {item?.dosage}
                  </TableCell>
                  <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {(() => {
                      const route = routeAdm?.find(
                        (route: any) =>
                          route?._id === item?.routAdministrationId
                      );
                      return route ? route?.RA_Abbreviation : "-";
                    })()}
                  </TableCell>
                  <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {item?.frequency?.abr_notation}
                  </TableCell>
                  <TableCell style={{ fontSize: 12, padding: "0px" }}>
                    {item?.duration}
                  </TableCell>
                  {treatment && (
                    <>
                      <TableCell style={{ fontSize: 12, padding: "0px" }}>
                        {item?.doctorName || "-"}
                      </TableCell>
                      <TableCell style={{ fontSize: 12, padding: "0px" }}>
                        {item?.dateAndTime ? dayjs(item?.dateAndTime).format('DD.MM.YYYY hh:mm A') : item?.dateTime}
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left" style={{ fontSize: 12, padding: "0px", }}>
                    <IconButton onClick={() => handleEditClick(item)}>
                      <EditIcon
                        sx={{
                          color: "#FFB800",
                          fontSize: 17,
                          "&:hover": {
                            background: "none",
                            color: "rgb(133, 96, 1)",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteClick(item?.medicineId)}
                    >
                      <DeleteIcon
                        sx={{
                          color: "#FF5454",
                          fontSize: 17,
                          "&:hover": {
                            background: "none",
                            color: "rgb(191, 44, 44)",
                          },
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        open={openFreq}
        onClose={handleCloseFreq}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <MedicineScheduleForm onClose={handleCloseFreq} />
      </Dialog>
      <Dialog
        open={openRoute}
        onClose={handleCloseRoute}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <MedicineAdministrationModal onClose={handleCloseRoute} />
      </Dialog>
    </Box>
  );
};

export default MedicineDoses;
