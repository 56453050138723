import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  FormControl,
  Button,
  Grid,
  Autocomplete,
  Divider,
  Radio,
  RadioGroup,
  Chip,
  SelectChangeEvent,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Examination } from '../Examination';
import { toast } from 'react-toastify';
import axios from 'axios';
import DynamicTable from '../../../../Dynamic-table';
import { Investigation } from '../Investegation';

interface PAFProps {
  onChange: (data: any) => void;
  data: any;
  otherTabsData: any;
  profile: any
  opdData: any
}
export const PatientAssessmentForm: React.FC<PAFProps> = ({ onChange, data, otherTabsData, profile, opdData }) => {
  const [formData, setFormData] = useState<any>({
    investigation: {
      CBC: false,
      BSR: false,
      SE: false,
      LFTs: false,
      RFTs: false,
      UrineCE: false,
      PT: false,
      HBsAg: false,
      AntiHCV: false,
      CXR: false,
      ECG: false,
    },
    history: {
      HTN: false,
      DM: false,
      CLD: false,
      IHD: false,
      COPD: false,
      Asthma: false,
      Smoking: false,
      Addiction: false,
      Drug: false,
      Others: false,
    },
    score: {
      mallam_pati_Grade: 0,
      ASA_Score: 1,
      surgical_Risk_Score: 'Low',
    },
    details: "",
    npo: '',
    blood_required: '',
    past_Surgical_History: '',
    blood_Group: {
      type: "",
      rhFactor: "",
    },
    anaesthesia_plan: ''
  });
  const [ipdExamination, setIpdExamination] = useState<any>({
    GPE: [],
    systemic_Examination: []
  })
  const [medicines, setMedicines] = useState<any[]>([])
  const [examination, setExamination] = useState<any[]>([])
  useEffect(() => {
    setExamination(opdData?.signs)
  }, [opdData])
  const currentDate = dayjs();

  const fetchFormData = async (formName: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${otherTabsData?._id}?formName=${formName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const fetchData = response?.data?.data?.formData;
      if (formName === "PreSurgical") {
        setMedicines(fetchData?.medicines);
      } else if (formName === 'History') {
        setFormData((prevState: any) => ({
          ...prevState,
          past_Surgical_History: fetchData?.past_surgical_History || '',
        }));
      } else if (formName === 'Examination') {
        setIpdExamination({
          GPE: fetchData?.examinations,
          systemic_Examination: fetchData?.examinations_Without_GPE,
        })
      }
    } catch (error) {
      toast.error('Failed to fetch form data.');
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await fetchFormData('PreSurgical');
        await fetchFormData('Examination');
        await fetchFormData('History');
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchAllData();
  }, []);  // This will trigger once when the component mounts

  useEffect(() => {
    if (data) {
      setFormData({
        blood_Group: data?.blood_Group,
        blood_required: data?.blood_required,
        details: data?.details,
        npo: data?.npo,
        history: data?.history,
        investigation: data?.investigation,
        score: data?.score,
        anaesthesia_plan: data?.anaesthesia_plan
      })
    } else {
      onChange({ ...formData, dateTime: currentDate });
    }
  }, [data])

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (formData.history && name in formData.history) {
      const updatedHistory = {
        ...formData.history,
        [name]: type === 'checkbox' ? checked : value,
      };
      setFormData((prev: any) => ({ ...prev, history: updatedHistory }));
      return; // early return to avoid further processing
    }
    if (formData.investigation && name in formData.investigation) {
      const updatedInvestigation = {
        ...formData.investigation,
        [name]: type === 'checkbox' ? checked : value,
      };
      setFormData((prev: any) => ({ ...prev, investigation: updatedInvestigation }));
      return; // early return to avoid further processing
    }
    if (formData.score && name in formData.score) {
      const updatedScore = {
        ...formData?.score,
        [name]: value,
      };
      setFormData((prev: any) => ({ ...prev, score: updatedScore }));
      return; // early return to avoid further processing
    }
    if (formData?.blood_Group && name in formData?.blood_Group) {
      const updatedBloodGroup = {
        ...formData?.blood_Group,
        [name]: value,
      };
      setFormData((prev: any) => ({ ...prev, blood_Group: updatedBloodGroup }));
      return; // early return to avoid further processing
    }
    if (name in formData || name === 'details' || name === 'npo' || name === 'blood_required' || name === 'anaesthesia_plan') {
      setFormData((prev: any) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  useEffect(() => {
    const updatedData = {
      ...formData,
      history: formData?.history,
      investigation: formData?.investigation,
      score: formData?.score,
      blood_Group: formData?.blood_Group,
      details: formData?.details, // Ensure details are included
      npo: formData?.npo,         // Ensure npo is included
      blood_required: formData?.blood_required, // Ensure blood_required is included
      past_Surgical_History: formData?.past_Surgical_History, // Ensure pastSurgicalHistory is included
    };
    onChange(updatedData);
  }, [formData]);

  return (
    <Box >
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{ background: "#F5F5F5", borderRadius: "13px" }}>
            <Typography
              sx={{
                background: "#E5E5E5",
                textAlign: "center",
                padding: 1,
                borderRadius: "13px 13px 0 0"
              }}
            >
              PRE-ANESTHESIA ASSESSMENT
            </Typography>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <Box sx={{ padding: 1 }} >
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600", py: 0.5 }}>
                      Blood Group
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <FormControl fullWidth size="small">
                          <Select
                            name="type"
                            value={formData?.blood_Group?.type || "A"}
                            onChange={handleChange}
                            sx={{
                              borderRadius: "12px",
                              border: "0px solid #D8D8D8",
                              background: "#F5F5FB",
                              fontSize: "12px",
                              height: "38px",
                              "& fieldset": {
                                border: "none",
                              },
                            }}
                          >
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="B">B</MenuItem>
                            <MenuItem value="AB">AB</MenuItem>
                            <MenuItem value="O">O</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth size="small">
                          <Select
                            name="rhFactor"
                            value={formData?.blood_Group?.rhFactor || "+"}
                            onChange={handleChange}
                            sx={{
                              borderRadius: "12px",
                              border: "0px solid #D8D8D8",
                              background: "#F5F5FB",
                              fontSize: "12px",
                              height: "38px",
                              "& fieldset": {
                                border: "none",
                              },
                            }}
                          >
                            <MenuItem value="+">+</MenuItem>
                            <MenuItem value="-">-</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: .3 }}>
                    <Typography sx={{ flex: 1 }}>ASA Score</Typography>
                    <TextField name='ASA_Score' value={formData?.score?.ASA_Score} onChange={(e: any) => {
                      const value = e.target.value;
                      if (value > 0 && value <= 6) {
                        handleChange(e)
                      }
                    }} className='search-field' sx={{ border: "1px solid grey", width: "30%" }} size='small' type='number' inputProps={{ min: 1, max: 6 }} />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: .3 }}>
                    <Typography sx={{ flex: 1 }}>Surgical Risk Score</Typography>
                    <Select
                      name="surgical_Risk_Score"
                      value={formData?.score?.surgical_Risk_Score || ''}
                      onChange={handleChange}
                      sx={{
                        borderRadius: "12px",
                        border: "1px solid #000",
                        background: "#F5F5FB",
                        fontSize: "12px",
                        height: "38px",
                        width: '30%',
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="Low">Low</MenuItem>
                      <MenuItem value="Intermediate">Intermediate</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                    </Select>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ flex: 1 }}>Mallampati Grade</Typography>
                    <TextField name='mallam_pati_Grade' value={formData?.score?.mallam_pati_Grade}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        if (value <= 4) {
                          handleChange(e)
                        }
                      }}
                      className='search-field' sx={{ border: "1px solid grey", width: "30%" }} size='small' type='number' />
                  </Box>
                  <Box mt={1}>
                    {formData?.past_Surgical_History && <TextField
                      name='past_Surgical_History'
                      className='search-field'
                      sx={{ background: '#fff' }}
                      size='small'
                      value={formData?.past_Surgical_History}
                      label='Past Srgical History'
                    />}
                  </Box>
                  {formData?.past_Surgical_History && <Box >
                    <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Anaesthesia given during previous surgery:</Typography>
                    <Select
                      placeholder="Previous Anaesthesia Given"
                      name="anaesthesia_plan"
                      value={formData?.anaesthesia_plan}
                      onChange={handleChange}
                      size='small'
                      fullWidth
                      sx={{
                        height: '38px',
                        borderRadius: "12px",
                        background: "#F5F5FB",
                        fontSize: "12px",
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      displayEmpty
                    >
                      <MenuItem value=""><>Select Anesthesia Type</></MenuItem>
                      <MenuItem value="Local">Local</MenuItem>
                      <MenuItem value="GA">GA</MenuItem>
                      <MenuItem value="Spinal">Spinal</MenuItem>
                      <MenuItem value="Nerve Block">Nerve Block</MenuItem>
                      <MenuItem value="Epidural">Epidural</MenuItem>
                    </Select>
                  </Box>}
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={0.5}>
                  {Object.keys(formData?.history ?? {})?.map((key) => (  // Default to empty object if formData?.history is null or undefined
                    <Grid item xs={6} key={key}> {/* Each checkbox takes half the width on small screens and up */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={formData?.history[key] ?? false}  // Default to false if undefined
                            onChange={handleChange}
                            name={key}
                          />
                        }
                        label={key}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Box mt={0.5} pb={0.5}>
                  {
                    (formData?.history?.Drug || formData?.history?.Others) && <Box>
                      <TextField
                        name='details'
                        className='search-field'
                        sx={{ background: '#fff' }}
                        size='small'
                        value={formData?.details}
                        onChange={handleChange}
                        label='Details'
                      />
                    </Box>
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid >
        <Grid item md={6} sm={12} xs={12}>
          <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"column"} gap={1}>
            <Box sx={{ background: "#F5F5F5", width: "100%", borderRadius: "13px", overflow: "hidden" }}>
              <Typography width={"100%"} sx={{ background: "#E5E5E5", textAlign: "center", p: 0.5 }} >Examination</Typography>
              <Box padding={0.5}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography sx={{ marginBottom: 0.2, mr: 2 }}>GPE:</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography sx={{
                      fontSize: '12px',
                      color: 'black', // White text
                      fontWeight: '500',
                      backgroundColor: '#80CBC4', // Blue background
                      padding: '2px 8px', // Add some padding for better visual spacing
                      borderRadius: '10px', // Optional: Rounded corners
                      display: 'inline-block',
                      alignSelf: "center"
                    }}>
                      {examination?.slice(0, 2).join(', ') ? examination?.slice(0, 2).join(', ') : "No Prev Examination"}
                      {examination?.length > 2 && (
                        <Tooltip title={examination?.slice(2)?.join(', ')} arrow placement='top'>
                          <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                            +{examination?.length - 2} more
                          </span>
                        </Tooltip>
                      )}
                    </Typography>
                  </Box>
                </Box>
                {ipdExamination?.GPE?.map((sign: any) => (
                  <Chip
                    key={sign?._id}
                    label={sign}
                    sx={{
                      mr: .4,
                      background: "#CFCFCF",
                      borderRadius: "8px",
                      fontSize: "12px",
                      height: "24px",
                      "&:hover": {
                        background: "#055FFC",
                        color: "#fff",
                        "& .MuiChip-deleteIcon": {
                          color: "#fff",
                        },
                        "& .MuiChip-icon": {
                          color: "#fff",
                        },
                      },
                    }}
                  />
                ))}

                <Typography sx={{ marginBottom: 0.2 }}>GSE:</Typography>
                <Box mt={0.3} display='flex' gap={0.2} flexWrap='wrap'>
                  {Object.entries(ipdExamination?.systemic_Examination || {}).map(([sign, value]) => (
                    <Chip
                      key={sign}
                      label={`${sign}: ${value}`} // Display both sign and its value
                      sx={{
                        background: "#CFCFCF",
                        borderRadius: "8px",
                        fontSize: "12px",
                        height: "24px",
                        "&:hover": {
                          background: "#055FFC",
                          color: "#fff",
                          "& .MuiChip-deleteIcon": {
                            color: "#fff",
                          },
                          "& .MuiChip-icon": {
                            color: "#fff",
                          },
                        },
                      }}
                    />
                  ))}

                </Box>
              </Box>
            </Box>
            <Box sx={{ background: "#F5F5F5", width: "100%", borderRadius: "12px", overflow: "hidden" }}>
              <Typography width={"100%"} sx={{ background: "#E5E5E5", textAlign: "center", p: 0.5 }} >Investigations</Typography>
              <Box display={"flex"} padding={"10px"} mb={1}>
                <FormGroup row>
                  <Grid container>
                    {Object?.keys(formData?.investigation ?? {})?.map((key, index) => (
                      <Grid item xs={2} sm={2} md={2} lg={2} key={key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={formData?.investigation[key]}
                              onChange={handleChange}
                              name={key}
                            />
                          }
                          label={<span style={{ fontSize: '14px' }}>{key}</span>}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Box>
            </Box>
            <Grid container spacing={1.5}>

              <Grid item md={6} sm={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Typography sx={{ mr: 1 }}>NPO</Typography>
                  <Checkbox
                    checked={formData?.npo}
                    onChange={handleChange}
                    name='npo'
                    size='small'
                  />
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Blood Required :</Typography>
                <TextField
                  fullWidth
                  placeholder="Blood Required"
                  type='number'
                  name="blood_required"
                  value={formData?.blood_required}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === '' || /^[0-9]*$/.test(value)) {
                      const numericValue = Number(value);
                      if (numericValue >= 0 && numericValue <= 15) {
                        handleChange(event);
                      }
                    }
                  }}
                  size='small'
                  className='search-field'
                  InputProps={{
                    endAdornment: <InputAdornment position="end">pint</InputAdornment>,
                  }}
                  inputProps={{
                    min: 0,
                    max: 15,
                    step: 1,
                  }}
                />
              </Grid>
            </Grid>

          </Box>

        </Grid>
      </Grid >
      <Box mt={1}>
        <Grid container >
          <Grid item md={12} sm={12} xs={12}>
            <DynamicTable
              headers={[
                "SN",
                "Medicine Name",
                "Schedule",
                "Duration",
                "Note",
              ]}
              data={medicines?.map((item: any, index: any) => [
                index + 1,
                item?.name,
                item?.dosage,
                item?.duration,
                item?.note,
              ])}
            />
          </Grid>

        </Grid>
      </Box>

    </Box >
  );
};
