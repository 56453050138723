import React, { useState } from 'react';
import { Fab, Tooltip, Box, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';

const WhatsAppButtonWithTooltip: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const handleWhatsAppClick = async () => {
        setLoading(true);
        try {
            // Use environment variable for the base URL
            const apiUrl = `${process.env.REACT_APP_BASE_URL}/cms/getWhatsappNumber`;

            // Fetch the WhatsApp link from the API
            const response = await axios.get(apiUrl, {
                headers: { Accept: '*/*' },
                responseType: 'text', // Treat response as plain text
            });

            const whatsappLink = response.data; // Extract the link from the response

            if (whatsappLink) {
                // Open the link in a new tab
                window.open(whatsappLink, '_blank');
            } else {
                alert('Unable to fetch WhatsApp link.');
                console.error('WhatsApp link not found in the response.');
            }
        } catch (error) {
            alert('Failed to open WhatsApp. Please try again later.');
            console.error('Error fetching WhatsApp link:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#0F79CC', // Blue background for tooltip
            color: 'white',
          
        }}
        >
            {/* Tooltip wrapper */}
            <Tooltip
                title={
                    <Typography
                        sx={{
                            fontSize: '14px',
                            padding: '4px 8px',
                            color: '#fff',
                            backgroundColor: '#0F79CC',
                            borderRadius: '8px',
                            display: 'inline-block',
                        }}
                    >
                        Need Help?
                    </Typography>
                }
                placement="right"
                arrow
            >
                <Fab
                    color="primary"
                    aria-label="WhatsApp"
                    onClick={handleWhatsAppClick}
                    disabled={loading} // Disable the button while loading
                    style={{
                        position: 'fixed',
                        bottom: 20,
                        left: 20, // Position the button on the bottom-left
                        zIndex: 1000,
                        backgroundColor: '#25D366', // WhatsApp green color
                        color: 'white',
                        width: '40px', // Smaller width
                        height: '40px', // Smaller height
                        minHeight: 'unset', // Override default minHeight
                    }}
                >
                    <WhatsAppIcon style={{ fontSize: '20px' }} />
                </Fab>
            </Tooltip>
        </Box>
    );
};

export default WhatsAppButtonWithTooltip;
