import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { FormControl, FormControlLabel, Grid, InputAdornment, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import DynamicTable from '../../../../Dynamic-table';
import { toast } from 'react-toastify';
import InputMask from "react-input-mask";
import dayjs from 'dayjs';

interface AnasthemiaProps {
  onChange: (data: any) => void;
  data: any; // Data fetched from the backend
  otherTabsData: any;
  profile: any
}

export const PostAnathesia: React.FC<AnasthemiaProps> = ({ onChange, data, otherTabsData, profile }) => {
  // const [selected_Scores, setSelectedScores] = React.useState<{ [key: string]: number }>({});
  const [total_Score, setTotalScore] = React.useState<number>(0);
  const [anaesthetic_Event, setAnaestheticEvent] = React.useState<any>({
    adverse_Event: 'No',
    event_Details: ''
  })
  const [formData, setFormData] = React.useState<any>({
    bp: '',
    hr: '',
    rr: '',
    consciousness: '',
  });
  const [savedData, setSavedData] = React.useState<any[]>([]);
  const [surgical_Site, setSurgicalSite] = React.useState<string>('');

  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const currentTime = dayjs().format('DD.MM.YYYY hh:mm A');
    const newData = {
      ...formData,
      currentTime,
      doctor: profile?.data?.fullName
    };
    setSavedData((prev) => [...prev, newData]);
    setFormData({
      bp: '',
      hr: '',
      rr: '',
      consciousness: '',
    })
  };

  const handleDelete = (index: number) => {
    setSavedData((prev) => prev?.filter((_, i) => i !== index));
  };

  const handleEdit = (index: number) => {
    const dataToEdit = savedData[index];
    setFormData(dataToEdit);
    handleDelete(index);
  };

  const handleRadioChange = (category: string, score: number) => {
    setSelectedScores((prev: any) => ({ ...prev, [category]: score }));
  };

  React.useEffect(() => {
    if (data && data?.anaesthetic_Event) {
      setAnaestheticEvent({
        adverse_Event: data?.anaesthetic_Event?.adverse_Event || '',
        event_Details: data?.anaesthetic_Event?.event_Details || '',
      });
      // onChange({
      //   anaesthetic_Event: {
      //     adverse_Event: data?.anaesthetic_Event?.adverse_Event || '',
      //     event_Details: data?.anaesthetic_Event?.event_Details || '',
      //   }
      // });
    }
    if (data?.surgical_Site) {
      setSurgicalSite(data?.surgical_Site)
    }
    // if (data && data?.total_Score && data?.selected_Scores) {
    //   setTotalScore(data?.total_Score)
    //   setSelectedScores(data?.selected_Scores)
    //   // onChange({ total_Score, selected_Scores });
    // }
    if (data && data?.assessment_Result) {
      setSavedData(data?.assessment_Result)
      // onChange(data?.assessment_Result);
    }
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSurgicalSiteChange = (value: string) => {
    // setFormData((prev: any) => ({
    //   ...prev,
    //   surgical_Site: value,
    // }));
    setSurgicalSite(value)
  };

  const handleAdverseEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedEvent = {
      ...anaesthetic_Event,
      adverse_Event: value,
      event_Details: value === 'Yes' ? anaesthetic_Event?.event_Details : '', // Reset event_Details if "No" is selected
    };
    setAnaestheticEvent(updatedEvent);
  };

  const handleEventDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedEvent = {
      ...anaesthetic_Event,
      event_Details: value,
    };
    setAnaestheticEvent(updatedEvent);
  };

  const healthAssesmentdata = [
    {
      category: 'SPO2',
      items: [
        { label: '92% On Room Air', score: 2 },
        { label: '90% On O2', score: 1 },
        { label: '< 92% On O2', score: 0 },
      ],
    },
    {
      category: 'Respiration',
      items: [
        { label: 'Breaths Deeply & Can Cough', score: 2 },
        { label: 'Shallow Irregular Breaths', score: 1 },
        { label: 'Apnea', score: 0 },
      ],
    },
    {
      category: 'B.P',
      items: [
        { label: '+20 mmHg of Normal', score: 2 },
        { label: '+20 - 50 mmHg of Normal', score: 1 },
        { label: '> 50 mmHg of Normal', score: 0 },
      ],
    },
    {
      category: 'Consciousness',
      items: [
        { label: 'Fully Awake', score: 2 },
        { label: 'Arousal on Calling', score: 1 },
        { label: 'Not Responsive', score: 0 },
      ],
    },
    {
      category: 'Activity',
      items: [
        { label: 'Move All Extremities', score: 2 },
        { label: 'Move 2 Extremities', score: 1 },
        { label: 'No Move', score: 0 },
      ],
    },
  ];

  const [selected_Scores, setSelectedScores] = React.useState<{ [key: string]: number }>(() => {
    const initialScores: { [key: string]: number } = {};
    healthAssesmentdata?.forEach((row) => {
      initialScores[row.category] = row.items[0]?.score; // Select the first item's score by default
    });
    return initialScores;
  });

  React.useEffect(() => {
    onChange({ total_Score, selected_Scores, assessment_Result: savedData, anaesthetic_Event, surgical_Site });
  }, [savedData, total_Score, selected_Scores, anaesthetic_Event, surgical_Site])
  React.useEffect(() => {
    const newTotal = Object.values(selected_Scores)?.reduce((acc: any, score: any) => acc + score, 0);
    setTotalScore(newTotal);
  }, [selected_Scores]);


  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={8} sm={12} xs={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Box display='flex' alignItems='center' gap={2}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: '600' }}>
                  Surgical Site Bleeding, Swelling Hematoma
                </Typography>
                <RadioGroup row value={surgical_Site} onChange={(e) => handleSurgicalSiteChange(e.target.value)}>
                  <FormControlLabel value="Yes" control={<Radio size='small' />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio size='small' />} label="No" />
                </RadioGroup>
              </Box>

            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {/* BP Field */}
            <Grid item md={2.4} sm={2.4} xs={6}>
              <TextField
                placeholder="300/0"
                name="bp"
                label='BP'
                type="text"
                value={formData?.bp}
                fullWidth
                // onChange={handleInputChange}
                onChange={(e: any) => {
                  const value = e.target.value;

                  if (value === "") {
                    handleInputChange(e);
                    return;
                  }

                  const regex = /^(\d{1,3})\/?(\d{0,3})$/;

                  if (regex.test(value)) {
                    const parts = value.split('/');
                    const firstPart = parseInt(parts[0], 10);
                    const secondPart = parts[1] ? parseInt(parts[1], 10) : 0;
                    if (firstPart >= 0 && firstPart <= 300 && secondPart <= firstPart) {
                      handleInputChange(e);
                    }
                  }
                }}
                className='search-field'
                size='small'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography sx={{ fontWeight: "500", fontSize: '10px' }}>/mmHg</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: { fontSize: '10px' }
                }}
              />
            </Grid>

            <Grid item md={2.4} sm={2.4} xs={6}>
              <TextField
                label="HR" // Label for the input
                placeholder="50-250"
                size="small"
                name='hr'
                value={formData?.hr}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (/^\d{0,3}$/.test(value)) { // Allow only up to 3 digits
                    handleInputChange(e);
                  }
                }}
                className='search-field'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography sx={{ fontWeight: "500", fontSize: '12px' }}>/Min</Typography>
                    </InputAdornment>
                  ),
                  sx: {
                    fontSize: '12px',
                  },
                }}
              />
            </Grid>

            {/* RR Field */}
            <Grid item md={2.4} sm={2.4} xs={6}>
              <TextField
                label="RR" // Label for the input
                placeholder="1-80"
                size="small"
                className='search-field'
                name='rr'
                value={formData?.rr}
                onChange={(e: any) => {
                  const value = e.target.value;
                  // Check if input is a number within the range of 1 to 80
                  if (/^\d*$/.test(value) && (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 80))) {
                    handleInputChange(e);
                  }
                }}
                fullWidth
                error={!!formData.rr && (parseInt(formData.rr) < 1 || parseInt(formData.rr) > 80)}
                helperText={
                  formData.rr &&
                    (parseInt(formData.rr) < 1 || parseInt(formData.rr) > 80)
                    ? 'Please enter a value between 1 and 80'
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography sx={{ fontWeight: "500", fontSize: '12px' }}>/Min</Typography>
                    </InputAdornment>
                  ),
                  sx: {
                    fontSize: '12px',
                  },
                }}
              />
            </Grid>

            {/* Consciousness Field */}
            <Grid item md={3} sm={2.8} xs={4}>
              <FormControl fullWidth variant="outlined" size='small'>
                <InputLabel htmlFor="consciousness" sx={{ fontSize: '14px' }}>Consciousness</InputLabel>
                <Select
                  name="consciousness"
                  id="consciousness"
                  value={formData?.consciousness}
                  onChange={handleSelectChange}
                  sx={{
                    borderRadius: "12px",
                    border: "0px solid #D8D8D8",
                    background: "#F5F5FB",
                    fontSize: "12px",
                    height: "38px",
                    "& fieldset": { border: "none" },
                  }}
                >
                  {/* <MenuItem value="">Select</MenuItem> */}
                  <MenuItem value="No Pain">No Pain</MenuItem>
                  <MenuItem value="Mild Pain">Mild Pain</MenuItem>
                  <MenuItem value="Severe Pain">Severe Pain</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Surgical Site Bleeding Field */}


            {/* Save Button */}
            <Grid item md={1} sm={2} xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                startIcon={<SaveIcon />}
                size='small'
                sx={{
                  mt: 0.5,
                  fontSize: '12px',
                  borderRadius: '8px',
                  // padding: '8px 16px',
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          <Box mt={1}>
            <Box sx={{ bgcolor: '#F5F5F5' }}>
              <Typography variant="h6" align="center" sx={{ fontSize: '14px', fontWeight: '600' }}>Assessment Results</Typography>
              <DynamicTable headers={["SN", "Date & Time", "BP", "HR", "RR", "Consciousness", "Created By", "Action"]}
                data={savedData?.map((item: any, index: number) => [
                  index + 1,
                  item?.currentTime,
                  item?.bp,
                  item?.hr,
                  item?.rr,
                  item?.consciousness,
                  item?.doctor
                  // item?.surgical_Site,
                ])}
                actionButtons={[
                  {
                    icon: <EditIcon sx={{ color: '#FFB800', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(133, 96, 1)' } }} />,
                    onClick: (rowIndex: number) => handleEdit(rowIndex)
                  },
                  {
                    icon: <DeleteIcon sx={{ color: '#FF5454', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(191, 44, 44)' } }} />,
                    onClick: (rowIndex: number) => handleDelete(rowIndex)
                  }
                ]}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Box sx={{ border: '1px solid #ddd', borderRadius: "12px 12px 0 0" }}>
            <Box sx={{
              // bgcolor: "#F5F5F5",
              padding: 0.2,
              borderRadius: "12px 12px 0 0",
              background: "#E5E5E5",
            }}>
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Extubation/Recovery
              </Typography>
            </Box>
            <Box sx={{}}>
              <TableContainer >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: 0 }}><Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Parameter</Typography></TableCell>
                      <TableCell sx={{ padding: 0 }}><Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Condition</Typography></TableCell>
                      <TableCell align="center" sx={{ padding: 0 }}><Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Score</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {healthAssesmentdata?.map((row: any, index: any) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell rowSpan={row.items.length} sx={{ fontSize: '10px', fontWeight: 'bold', padding: 0 }} align="center">
                            {row?.category}
                          </TableCell>
                          <TableCell sx={{ padding: 0 }}>
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 0,
                                margin: 0,
                                gap: 1,
                              }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      height: 12,
                                      width: 12,
                                    },
                                    "&.MuiRadio-root": {
                                      padding: 0,
                                    },
                                    "& .MuiButtonBase-root": {
                                      padding: 0,
                                      "&:hover": {
                                        background: "none",
                                      },
                                    },
                                  }}
                                  checked={selected_Scores[row?.category] === row?.items[0]?.score}
                                  onChange={() => handleRadioChange(row?.category, row?.items[0]?.score)}
                                />
                              }
                              label={<span style={{ fontSize: '10px' }}> {row.items[0].label} </span>}
                            />
                          </TableCell>
                          <TableCell align="center" sx={{ padding: 0, fontSize: '10px' }}>{row?.items[0]?.score}</TableCell>
                        </TableRow>
                        {row?.items?.slice(1)?.map((item: any, idx: any) => (
                          <TableRow key={idx}>
                            <TableCell sx={{ padding: 0 }}>
                              <FormControlLabel
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: 0,
                                  margin: 0,
                                  gap: 1,
                                }}
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        height: 12,
                                        width: 12,
                                      },
                                      "&.MuiRadio-root": {
                                        padding: 0,
                                      },
                                      "& .MuiButtonBase-root": {
                                        padding: 0,
                                        "&:hover": {
                                          background: "none",
                                        },
                                      },
                                    }}
                                    checked={selected_Scores[row?.category] === item?.score}
                                    onChange={() => handleRadioChange(row?.category, item?.score)}
                                  />
                                }
                                label={<span style={{ fontSize: '10px' }}> {item.label} </span>}
                              />
                            </TableCell>
                            <TableCell align="center" sx={{ padding: 0, fontSize: '10px' }}>{item?.score}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>

                </Table>
              </TableContainer>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "11px", fontWeight: "600" }}>
                Total Score : {total_Score}
              </Typography>

            </Box>

            <Box sx={{ border: '1px solid lightgray', }}>
              <h4 style={{ fontSize: '12px', fontWeight: '600' }}>Adverse Anaesthesia Event</h4>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 1 }}>
                <RadioGroup
                  row
                  aria-labelledby="adverse-event-radio-group"
                  name="adverse_Event"
                  value={anaesthetic_Event?.adverse_Event}
                  onChange={handleAdverseEventChange}
                >
                  <FormControlLabel value="Yes" control={<Radio sx={{
                    "& .MuiSvgIcon-root": {
                      height: 12,
                      width: 12,
                    }
                  }} />} label={<span style={{ fontSize: '12px' }}>Yes</span>} />
                  <FormControlLabel value="No" control={<Radio sx={{
                    "& .MuiSvgIcon-root": {
                      height: 12,
                      width: 12,
                    }
                  }} />} label={<span style={{ fontSize: '12px' }}>No</span>} />
                </RadioGroup>
                {anaesthetic_Event?.adverse_Event === 'Yes' && (
                  <TextField
                    label="Event Details"
                    variant="outlined"
                    value={anaesthetic_Event?.event_Details}
                    onChange={handleEventDetailsChange}
                    className='search-field'
                    size='small'
                    sx={{ width: '50%' }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

    </Box>
  );
};

