// import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
// import { ChangeEvent, useEffect, useState } from "react";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import DynamicTable from "../../../../Dynamic-table";
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/DeleteOutlined';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import InputMask from "react-input-mask";
// import { toast } from "react-toastify";
// import axios from "axios";

// interface OtAnaesthesiaMonitoring {
//     patient_Identified: string;
//     mrNoChecked: string;
//     consentChecked: string;
//     siteChecked: string;
//     patient_Oriented: boolean;
// }
// function convertTime(inputTime: string): string {
//     // Create a Date object from the input time string
//     const date = new Date(inputTime);

//     // Format the date to DD.MM.YYYY:HH:MM AM/PM
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const year = date.getFullYear();

//     // Get the hour and minutes from the date object
//     let hours = date.getHours();
//     const minutes = date.getMinutes();
//     const ampm = hours >= 12 ? 'PM' : 'AM';

//     // Convert to 12-hour format
//     hours = hours % 12;
//     if (hours === 0) {
//         hours = 12; // 0 becomes 12 (midnight or noon)
//     }

//     // Format minutes
//     const minute = String(minutes).padStart(2, '0');

//     // Return the formatted string with the actual time
//     return `${day}.${month}.${year}:${String(hours).padStart(2, '0')}:${minute} ${ampm}`;
// }
// interface TemplateData {
//     details: string;
// }

// // Define the type for each Template
// interface Template {
//     _id: string;
//     templateName: string;
//     templateData: TemplateData;  // This includes the template details
//     isActive: boolean;
//     createdAt: string;
//     updatedAt: string;
//     isDeleted: boolean;
// }
// interface maintenance_Of_Anesthesia {
//     HR: string;
//     cardiac_Rhythm: string;
//     RR: string;
//     BP: string;
//     airway_Security_Patency: string;
//     SpO2: string;
//     level_Of_Anaesthesia: string;
//     drugs_Misc: any
//     misc: any
// }
// interface Vitals {
//     Hr: string;

//     Rr: string;
//     Bp: string;

//     spO2: string;

//     temp: string
// }
// interface maintenance_Of_Anesthesia {
//     HR: string;
//     RR: string;
//     BP: string;
//     SpO2: string;
//     airway_Security_Patency: string;
//     cardiac_Rhythm: string;
//     level_Of_Anaesthesia: string;
//     drugs_Misc: any;
//     misc: any;
// }

// interface FormData {
//     ot_anesthesia_monitoring: OtAnaesthesiaMonitoring;
//     maintenance_Of_Anesthesia: maintenance_Of_Anesthesia;
//     vitals: Vitals
//     anesthesia_Plan: string,
//     detail: string
// }
// interface OtRoomAnaesthesiaMonitoringProps {
//     onChange: (data: any) => void;
//     data: FormData;
//     otherTabsData: any;
//     profile: any;
// }
// const OtRoomAnaesthesiaMonitoring: React.FC<OtRoomAnaesthesiaMonitoringProps> = ({ onChange, data, otherTabsData, profile }) => {
//     const [formdata, setFormdata] = useState({
//         sign_in: {
//             conformation: true,
//             site_Marked: true,
//             safety_Check: true,
//             pulse_Oximeter: true,
//             Known_Allergy: "yes",
//             difficulty_Risk: "yes",
//             risk_Of_Blood_Loss: "yes",
//             saved_At: null,
//             patient_Oriented: true
//         },
//         maintenance_Of_Anesthesia: {
//             HR: '',
//             cardiac_Rhythm: 'Regular',
//             RR: '',
//             BP: '',
//             airway_Security_Patency: 'Patent',
//             SpO2: '',
//             level_Of_Anaesthesia: 'Deep',
//             drugs_Misc: '',
//             misc: ''
//         },
//         vitals: {
//             Hr: '',
//             Rr: '',
//             Bp: '',
//             spO2: '',
//             temp: '',
//         },
//         anesthesia_Plan: '',
//         detail: ""
//     });
//     const [anesthesiaRecords, setAnesthesiaRecords] = useState<any>([]);
//     const [isOpen, setIsOpen] = useState(false)
//     const [editingIndex, setEditingIndex] = useState(null);
//     const [isMultiline, setIsMultiline] = useState(false);
//     const [isMultilinetwo, setIsMultilinetwo] = useState(false);
//     const handleFocustwo = () => {
//         setIsMultilinetwo(true);
//     };

//     const handleBlurtwo = () => {
//         setIsMultilinetwo(false);
//     };
//     const handleFocus = () => {
//         setIsMultiline(true);
//     };

//     const handleBlur = () => {
//         setIsMultiline(false);
//     };
//     const [templates, setTemplates] = useState<Template[]>([]); // An array of Template objects

//     // Fetch templates from the API on component mount
//     useEffect(() => {
//         const fetchTemplates = async () => {
//             try {
//                 const response = await axios.get(
//                     `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/getAllTemplates?ope_type=Anesthesia`,
//                     {
//                         headers: {
//                             accept: '*/*',
//                             Authorization: `Bearer ${localStorage.getItem("token")}`, // Replace with actual token
//                         },
//                     }
//                 );

//                 if (response.data?.isSuccess) {
//                     setTemplates(response?.data?.data); // Store the fetched templates
//                 }
//             } catch (error) {
//                 console.error('Error fetching templates:', error);
//             }
//         };

//         fetchTemplates();
//     }, []);

//     // Handle the change in the "Anesthesia Plan" select dropdown
//     const handleAnesthesiaPlanChange = (event: SelectChangeEvent<string>) => {
//         const selectedTemplate = event.target.value;

//         // Find the template data corresponding to the selected template name
//         const selectedTemplateData = templates?.find(
//             (template) => template.templateName === selectedTemplate
//         );

//         // If template data is found, update formdata
//         if (selectedTemplateData) {
//             setFormdata((prevData) => ({
//                 ...prevData,
//                 anesthesia_Plan: selectedTemplate, // Store the selected template name
//                 detail: selectedTemplateData.templateData.details || '', // Autofill the detail field with template details
//             }));
//         }
//     };

//     const handleSaveMaintenance = () => {
//         // const newRecord = { ...formdata.maintenance_Of_Anesthesia };
//         const newRecord = {
//             ...formdata.maintenance_Of_Anesthesia,
//             // timestamp: new Date().toLocaleString() // Save current date and time
//             timestamp: new Date().toLocaleString('en-US', {
//                 year: 'numeric',
//                 month: 'numeric',
//                 day: 'numeric',
//                 hour: 'numeric',
//                 minute: 'numeric',
//                 hour12: true
//             })
//         };
//         if (editingIndex !== null) {
//             setAnesthesiaRecords((prevRecords: any) =>
//                 prevRecords?.map((record: any, index: any) =>
//                     index === editingIndex ? newRecord : record
//                 )
//             );
//             setEditingIndex(null);
//         } else {
//             setAnesthesiaRecords((prevRecords: any) => [...prevRecords, newRecord]);
//         }

//         setFormdata((prev) => ({
//             ...prev,
//             maintenance_Of_Anesthesia: {
//                 HR: '',
//                 cardiac_Rhythm: 'Regular',
//                 RR: '',
//                 BP: '',
//                 airway_Security_Patency: 'Patent',
//                 SpO2: '',
//                 level_Of_Anaesthesia: 'Deep',
//                 drugs_Misc: '',
//                 misc: ''
//             },
//         }));
//     };

//     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const { name, value } = event.target;
//         // if (name in formdata?.ot_anesthesia_monitoring) {
//         //     setFormdata((prev) => ({
//         //         ...prev,
//         //         ot_anesthesia_monitoring: {
//         //             ...prev?.ot_anesthesia_monitoring,
//         //             [name]: value,
//         //         },
//         //     }));

//         // } 
//         if (name in formdata?.maintenance_Of_Anesthesia) {
//             setFormdata((prev) => ({
//                 ...prev,
//                 maintenance_Of_Anesthesia: {
//                     ...prev?.maintenance_Of_Anesthesia,
//                     [name]: value,
//                 },
//             }));
//         } else if (name in formdata?.vitals) {
//             setFormdata((prev) => ({
//                 ...prev,
//                 vitals: {
//                     ...prev.vitals,
//                     [name]: value,
//                 },
//             }));
//         }
//     };

//     const handleSignInChange = (event: any) => {
//         const { name, value, checked, type } = event.target;
//         setFormdata((prevState) => ({
//             ...prevState,
//             sign_in: {
//                 ...prevState.sign_in,
//                 [name]: type === 'checkbox' ? checked : value,
//             },
//         }));
//     };
//     const handleSave = () => {
//         const currentTime = new Date().toISOString();
//         // const currentTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Karachi" });
//         setFormdata((prevState: any) => ({
//             ...prevState,
//             sign_in: {
//                 ...prevState.sign_in,
//                 savedAt: currentTime,
//             },
//         }));
//         handleClose();
//     };

//     const handleClose = () => {
//         setIsOpen(false)
//     };
//     const handleOpen = () => {
//         setIsOpen(true)
//     };
//     useEffect(() => {

//         onChange({maintenance_Of_Anesthesia: anesthesiaRecords, vitals : formdata?.vitals, anesthesia_Plan: formdata?.anesthesia_Plan, detail : formdata?.detail })
//     }, [formdata, anesthesiaRecords])
//     useEffect(() => {
//         if (data) {
//             setFormdata((prevData: any) => {
//                 const updatedData = { ...prevData, ...data };
//                 // onChange(updatedData);
//                 return updatedData;
//             });
//         }
//         if (data?.maintenance_Of_Anesthesia) {
//             setAnesthesiaRecords(data?.maintenance_Of_Anesthesia)
//             setFormdata((prev) => ({
//                 ...prev,
//                 maintenance_Of_Anesthesia: {
//                     HR: '',
//                     cardiac_Rhythm: '',
//                     RR: '',
//                     BP: '',
//                     airway_Security_Patency: '',
//                     SpO2: '',
//                     level_Of_Anaesthesia: '',
//                     drugs_Misc: '',
//                     misc: ''
//                 },
//             }));
//         }
//     }, [data]);

//     const handleDelete = (index: any) => {
//         setAnesthesiaRecords((prevRecords: any) =>
//             prevRecords.filter((_: any, i: any) => i !== index)
//         );
//     };

//     const handleEdit = (index: any) => {
//         setEditingIndex(index);
//         setFormdata((prev: any) => ({
//             ...prev,
//             maintenance_Of_Anesthesia: anesthesiaRecords[index],
//         }));
//     };
//     return (
//         <>
//             <Box>
//                 <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ mb: 1 }} flexDirection={"row"}>
//                     <Typography variant="h6">OT Room Anaesthesia Monitoring</Typography>
//                     {/* <Button variant="contained" onClick={handleOpen}>sign_in</Button> */}
//                     <Button variant="contained"
//                         sx={{
//                             background: formdata?.sign_in && Object.values(formdata.sign_in).some(value => value === true || value === "yes" || value === "no") ? '#009E65' : '#FFBF00',
//                             "&:hover": {
//                                 background: formdata?.sign_in && Object.values(formdata.sign_in).some(value => value === true || value === "yes" || value === "no") ? '#009E65' : '#FFBF00',
//                             },
//                         }}
//                         onClick={handleOpen}>sign_in
//                     </Button>
//                 </Box>
//                 <Grid container spacing={1}>
//                     <Grid item md={5.5} sm={12} xs={12}>
//                         <Box sx={{ bgcolor: "rgba(229, 229, 229, 1)", textAlign: "center", }}>
//                             <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600", py: .5 }}>
//                                 Vitals
//                             </Typography>
//                         </Box>
//                         <Box sx={{ bgcolor: "rgba(245, 245, 245, 1)", p: 1 }}>
//                             <Grid container spacing={1}>
//                             <Grid item md={2.4} sm={4} xs={6}>
//                                     <TextField
//                                         label="Temp" // Label for the input
//                                         placeholder="92-107"
//                                         size="small"
//                                         className="search-field"
//                                         value={formdata?.vitals?.temp}
//                                         name="temp"
//                                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
//                                             const value = e.target.value;
//                                             // Allow only numeric inputs
//                                             if (/^\d*$/.test(value)) {
//                                                 const numericValue = value ? Number(value) : null;

//                                                 // Update state and validate range
//                                                 if (numericValue === null || (numericValue >= 92 && numericValue <= 107)) {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         vitals: {
//                                                             ...prev.vitals,
//                                                             temp: value, // Update if valid
//                                                         },
//                                                     }));
//                                                 } else {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         vitals: {
//                                                             ...prev.vitals,
//                                                             temp: value, // Allow invalid value to show red border
//                                                         },
//                                                     }));
//                                                 }
//                                             }
//                                         }}
//                                         fullWidth
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <span style={{ fontSize: "10px" }}>F</span>
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                         sx={{
//                                             backgroundColor: "white",
//                                             ...(formdata?.vitals?.temp &&
//                                                 (Number(formdata.vitals.temp) < 92 || Number(formdata.vitals.temp) > 107)
//                                                 ? { border: "1px solid red" } // Add red border if invalid
//                                                 : {}),
//                                             borderRadius: "4px",
//                                         }}
//                                     />
//                                 </Grid>

//                                 <Grid item md={2.4} sm={4} xs={6}>
//                                     <TextField
//                                         label="HR"
//                                         placeholder="50-250"
//                                         size="small"
//                                         name="Hr"
//                                         value={formdata?.vitals?.Hr}
//                                         className="search-field"
//                                         onChange={(e: any) => {
//                                             const value = e.target.value;
//                                             // Allow only numeric input
//                                             if (/^\d*$/.test(value)) {
//                                                 const numericValue = value ? Number(value) : null;

//                                                 // Update state if value is within range
//                                                 if (numericValue === null || (numericValue >= 50 && numericValue <= 250)) {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         vitals: {
//                                                             ...prev.vitals,
//                                                             Hr: value, // Update if valid
//                                                         },
//                                                     }));
//                                                 } else {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         vitals: {
//                                                             ...prev.vitals,
//                                                             Hr: value, // Allow invalid value to show red border
//                                                         },
//                                                     }));
//                                                 }
//                                             }
//                                         }}
//                                         fullWidth
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <span style={{ fontSize: "12px" }}>/Min</span>
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                         sx={{
//                                             backgroundColor: "white",
//                                             ...(formdata?.vitals?.Hr &&
//                                                 (Number(formdata.vitals.Hr) < 50 || Number(formdata.vitals.Hr) > 250)
//                                                 ? { border: "1px solid red" } // Add red border if invalid
//                                                 : {}),
//                                             borderRadius: "4px",
//                                         }}
//                                     />
//                                 </Grid>

//                                 <Grid item md={2.4} sm={4} xs={6}>
//                                     <TextField
//                                         label="RR" // Label for the input
//                                         placeholder="1-80"
//                                         size="small"
//                                         name="Rr"
//                                         value={formdata?.vitals?.Rr}
//                                         className="search-field"
//                                         onChange={(e: any) => {
//                                             const value = e.target.value;
//                                             // Allow only numeric input
//                                             if (/^\d*$/.test(value)) {
//                                                 const numericValue = value ? Number(value) : null;

//                                                 // Update state if value is within range
//                                                 if (numericValue === null || (numericValue >= 1 && numericValue <= 80)) {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         vitals: {
//                                                             ...prev.vitals,
//                                                             Rr: value, // Update if valid
//                                                         },
//                                                     }));
//                                                 } else {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         vitals: {
//                                                             ...prev.vitals,
//                                                             Rr: value, // Update state for invalid value to show red border
//                                                         },
//                                                     }));
//                                                 }
//                                             }
//                                         }}
//                                         fullWidth
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <span style={{ fontSize: "10px" }}>Min</span>
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                         sx={{
//                                             backgroundColor: "white",
//                                             border: formdata?.vitals?.Rr && (Number(formdata.vitals.Rr) < 1 || Number(formdata.vitals.Rr) > 80)
//                                                 ? "1px solid red" // Red border for invalid input
//                                                 : "none",
//                                             borderRadius: "4px",
//                                         }}
//                                     />
//                                 </Grid>

//                                 <Grid item md={2.4} sm={4} xs={6}>
//                                     <TextField
//                                         label="BP" // Label for the input
//                                         placeholder="120/80"
//                                         size="small"
//                                         className='search-field'
//                                         value={formdata.vitals.Bp}
//                                         name="Bp"
//                                         onChange={handleChange}
//                                         fullWidth
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position='end'>
//                                                     <span style={{ fontSize: '10px' }}>mgHg</span>
//                                                 </InputAdornment>
//                                             )
//                                         }}
//                                         sx={{ backgroundColor: "white" }}
//                                     />
//                                 </Grid>
//                                 <Grid item md={2.4} sm={4} xs={6}>
//                                     <Box display="flex" alignItems="center" className="search-field" bgcolor={"white"}>
//                                         <TextField
//                                             label="SPO₂" // Label for the input
//                                             placeholder="SPO2"
//                                             name="spO2"
//                                             size="small"
//                                             value={formdata.vitals.spO2}
//                                             onChange={(e: any) => {
//                                                 const value = e.target.value;
//                                                 if (/^\d*\.?\d*$/.test(value) && (value === '' || parseFloat(value) <= 100)) {
//                                                     handleChange(e);
//                                                 }
//                                             }}
//                                             fullWidth
//                                             sx={{
//                                                 '& .MuiInputBase-root': {
//                                                     fontSize: '10px',
//                                                     borderRadius: '12px',
//                                                     backgroundColor: "white",
//                                                 },
//                                             }}
//                                         />
//                                         <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px', }}>
//                                             %
//                                         </Typography>
//                                     </Box>
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                         <Grid container spacing={1}>
//                             {/* Anesthesia Plan Select */}
//                             <Grid item md={4} xs={6} sm={6}>
//                                 <Typography sx={{ fontSize: '14px', color: '#3F3F3F', fontWeight: '600', py: 0.5 }}>
//                                     Anesthesia Given
//                                 </Typography>
//                                 <FormControl fullWidth size="small">
//                                     <Select
//                                         name="anesthesia_Plan"
//                                         value={formdata.anesthesia_Plan || 'plan'} // Bound to state
//                                         onChange={handleAnesthesiaPlanChange} // Update formdata on selection
//                                         sx={{
//                                             borderRadius: '12px',
//                                             border: '0px solid #D8D8D8',
//                                             background: '#F5F5FB',
//                                             fontSize: '12px',
//                                             height: '38px',
//                                             '& fieldset': {
//                                                 border: 'none',
//                                             },
//                                         }}
//                                     >
//                                         <MenuItem value="plan">Select Anesthesia Plan</MenuItem>
//                                         {templates?.map((template) => (
//                                             <MenuItem key={template?._id} value={template?.templateName}>
//                                                 {template?.templateName}
//                                             </MenuItem>
//                                         ))}
//                                     </Select>
//                                 </FormControl>
//                             </Grid>

//                             {/* Anesthesia Plan Details */}
//                             <Grid item md={8} xs={6} sm={6}>
//                                 <Typography sx={{ fontSize: '14px', color: '#3F3F3F', fontWeight: '600', py: 0.5 }}>
//                                     Details:
//                                 </Typography>
//                                 <TextField
//                                     size="small"
//                                     label="Detail"
//                                     value={formdata?.detail} // Bind value of the detail field
//                                     onFocus={handleFocustwo}
//                                     onChange={(e) =>
//                                         setFormdata((prev) => ({
//                                             ...prev,
//                                             detail: e.target.value,
//                                         }))
//                                     }
//                                     onBlur={handleBlurtwo}
//                                     multiline={isMultilinetwo}
//                                     minRows={isMultilinetwo ? 3 : 1}
//                                     InputProps={{
//                                         style: {
//                                             borderRadius: "12px",
//                                             background: "#F5F5FB",
//                                             fontSize: "12px",
//                                         },
//                                     }}
//                                     sx={{
//                                         "& .MuiOutlinedInput-root": {
//                                             "& fieldset": {
//                                                 border: "none",
//                                             },
//                                             "&:hover fieldset": {
//                                                 border: "none",
//                                             },
//                                             "&.Mui-focused fieldset": {
//                                                 border: "none",
//                                             },
//                                         },
//                                         "& .MuiInputLabel-root": {
//                                             top: "-2px",
//                                         },

//                                     }}
//                                     fullWidth
//                                 />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                     <Grid item md={6.5} sm={12} xs={12}>
//                         <Box sx={{ bgcolor: "rgba(229, 229, 229, 1)", textAlign: "center", }}>
//                             <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600", py: .5 }}>
//                                 Maintenance of Anesthesia
//                             </Typography>
//                         </Box>
//                         <Box sx={{ bgcolor: "rgba(245, 245, 245, 1)", p: 1 }}>
//                             <Grid container spacing={1}>
//                                 <Grid item md={2} sm={4} xs={6}>
//                                     <Box>
//                                         <TextField required placeholder="Drugs" name="drugs_Misc" fullWidth
//                                             label="Drugs"
//                                             size="small"
//                                             sx={{
//                                                 "& .MuiOutlinedInput-root": {
//                                                     "& fieldset": {
//                                                         border: "none",
//                                                     },
//                                                     "&:hover fieldset": {
//                                                         border: "none",
//                                                     },
//                                                     "&.Mui-focused fieldset": {
//                                                         border: "none",
//                                                     },
//                                                 },
//                                                 "& .MuiInputLabel-root": {
//                                                     top: "-2px",
//                                                 },
//                                                 bgcolor: "white"
//                                             }}
//                                             value={formdata?.maintenance_Of_Anesthesia?.drugs_Misc} // Bind to state
//                                             onChange={handleChange} // Maintain existing handler
//                                             onFocus={handleFocus}
//                                             onBlur={handleBlur}
//                                             multiline={isMultiline}
//                                             minRows={isMultiline ? 3 : 1}
//                                             InputProps={{
//                                                 style: {
//                                                     borderRadius: "12px",
//                                                     // background: "#F5F5FB",
//                                                     fontSize: "12px",
//                                                 },
//                                             }}
//                                         />
//                                     </Box>
//                                 </Grid>
//                                 <Grid item md={2.5} sm={4} xs={6} >

//                                     <TextField
//                                         label="HR" // Label for the input
//                                         placeholder="50-250"
//                                         size="small"
//                                         sx={{ backgroundColor: "white" }}
//                                         className='search-field'
//                                         value={formdata?.maintenance_Of_Anesthesia?.HR}
//                                         name="HR"
//                                         onChange={(e: any) => {
//                                             const value = e.target.value;
//                                             // Allow only digits (and decimal point if needed)
//                                             if (/^\d*\.?\d*$/.test(value) || value === '') {
//                                                 const numericValue = value ? Number(value) : null; // Convert to number if not empty
//                                                 // Update state if the value is valid (0-300)
//                                                 if (numericValue === null || (numericValue >= 0 && numericValue <= 250)) {
//                                                     handleChange(e); // Call the existing handler
//                                                 }
//                                             }
//                                         }}
//                                         fullWidth
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position='end'>
//                                                     <span style={{ fontSize: '12px' }}>/Min</span>
//                                                 </InputAdornment>
//                                             )
//                                         }}
//                                     />
//                                 </Grid>
//                                 <Grid item md={2.5} sm={4} xs={6}>
//                                     <TextField
//                                         label="RR" // Label for the input
//                                         placeholder="1-80"
//                                         size="small"
//                                         name="RR"
//                                         value={formdata?.maintenance_Of_Anesthesia?.RR} // Bind to state
//                                         className="search-field"
//                                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
//                                             const value = e.target.value;

//                                             // Allow only numeric inputs
//                                             if (/^\d*$/.test(value)) {
//                                                 const numericValue = value ? Number(value) : null;

//                                                 // Update state and validate range
//                                                 if (numericValue === null || (numericValue >= 1 && numericValue <= 80)) {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         maintenance_Of_Anesthesia: {
//                                                             ...prev.maintenance_Of_Anesthesia,
//                                                             RR: value, // Update if valid
//                                                         },
//                                                     }));
//                                                 } else {
//                                                     setFormdata((prev) => ({
//                                                         ...prev,
//                                                         maintenance_Of_Anesthesia: {
//                                                             ...prev.maintenance_Of_Anesthesia,
//                                                             RR: value, // Update for invalid value to show feedback
//                                                         },
//                                                     }));
//                                                 }
//                                             }
//                                         }}
//                                         fullWidth
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <span style={{ fontSize: "12px" }}>ml</span>
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                         sx={{
//                                             backgroundColor: "white",
//                                             border: formdata?.maintenance_Of_Anesthesia?.RR &&
//                                                 (Number(formdata.maintenance_Of_Anesthesia.RR) < 1 || Number(formdata.maintenance_Of_Anesthesia.RR) > 80)
//                                                 ? "1px solid red" // Add red border if invalid
//                                                 : "1px solid transparent", // Transparent border for valid input
//                                             borderRadius: "4px",
//                                         }}
//                                     />
//                                 </Grid>

//                                 <Grid item md={2.5} sm={4} xs={6}>
//                                     <TextField
//                                         label="BP" // Label for the input
//                                         placeholder="120/80"
//                                         size="small"
//                                         className='search-field'
//                                         value={formdata?.maintenance_Of_Anesthesia?.BP} // Bind to state
//                                         onChange={handleChange} // Maintain existing handler
//                                         name="BP"
//                                         fullWidth
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position='end'>
//                                                     <span style={{ fontSize: '12px' }}>mgHg</span>
//                                                 </InputAdornment>
//                                             )
//                                         }}
//                                         sx={{ backgroundColor: "white" }}
//                                     />
//                                 </Grid>
//                                 <Grid item md={2.5} sm={4} xs={6}>
//                                     <Box display="flex" alignItems="center" className="search-field" bgcolor={"white"}>
//                                         <TextField
//                                             label="SPO₂" // Label for the input
//                                             placeholder="SPO2"
//                                             name="SpO2"
//                                             size="small"
//                                             value={formdata?.maintenance_Of_Anesthesia?.SpO2}
//                                             onChange={(e: any) => {
//                                                 const value = e.target.value;
//                                                 if (/^\d*\.?\d*$/.test(value) && (value === '' || parseFloat(value) <= 100)) {
//                                                     handleChange(e);
//                                                 }
//                                             }}
//                                             fullWidth
//                                             sx={{
//                                                 '& .MuiInputBase-root': {
//                                                     fontSize: '12px',
//                                                     borderRadius: '12px',
//                                                     backgroundColor: "white",
//                                                 },
//                                             }}
//                                         />
//                                         <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px', }}>
//                                             %
//                                         </Typography>
//                                     </Box>
//                                 </Grid>

//                                 <Grid item md={4} sm={4} xs={6}>
//                                     <Typography sx={{ fontSize: "12px", color: "#3F3F3F", fontWeight: "600" }}>
//                                         Airway Security Patency   :                                </Typography>
//                                     <RadioGroup
//                                         name="airway_Security_Patency"
//                                         row
//                                         value={formdata?.maintenance_Of_Anesthesia?.airway_Security_Patency} // Bind to state
//                                         onChange={handleChange} // Maintain existing handler
//                                         sx={{ alignItems: 'center' }}
//                                     >
//                                         <Box sx={{ display: "flex", alignItems: "center" }}>
//                                             <FormControlLabel value="Patent" control={<Radio sx={{
//                                                 "& .MuiSvgIcon-root": {
//                                                     height: 15,
//                                                     width: 15,
//                                                 }
//                                             }} />} label={<span style={{ fontSize: '10px' }}>Patent</span>} />
//                                             <FormControlLabel value="NotPatent" control={<Radio sx={{
//                                                 "& .MuiSvgIcon-root": {
//                                                     height: 15,
//                                                     width: 15,
//                                                 }
//                                             }} />} label={<span style={{ fontSize: '10px' }}> Not Patent </span>} /></Box>
//                                     </RadioGroup>
//                                 </Grid>
//                                 <Grid item md={4} sm={4} xs={6}>
//                                     <Typography sx={{ fontSize: "12px", color: "#3F3F3F", fontWeight: "600" }}>
//                                         Cardiac Rhythm    :
//                                     </Typography>
//                                     <RadioGroup
//                                         name="cardiac_Rhythm"
//                                         row
//                                         value={formdata?.maintenance_Of_Anesthesia?.cardiac_Rhythm}
//                                         onChange={handleChange}
//                                     >
//                                         <Box sx={{ display: "flex", alignItems: "center" }}>
//                                             <FormControlLabel value="Regular" control={<Radio sx={{
//                                                 "& .MuiSvgIcon-root": {
//                                                     height: 15,
//                                                     width: 15,
//                                                 }
//                                             }} />} label={<span style={{ fontSize: '10px' }}>Regular</span>} />
//                                             <FormControlLabel value="Irregular" control={<Radio sx={{
//                                                 "& .MuiSvgIcon-root": {
//                                                     height: 15,
//                                                     width: 15,
//                                                 }
//                                             }} />} label={<span style={{ fontSize: '10px' }}>Irregular</span>} /></Box>
//                                     </RadioGroup>
//                                 </Grid>
//                                 <Grid item md={4} sm={4} xs={6}>
//                                     <Typography sx={{ fontSize: "12px", color: "#3F3F3F", fontWeight: "600" }}>
//                                         Level of Anaesthesia :                                </Typography>
//                                     <RadioGroup
//                                         name="level_Of_Anaesthesia"
//                                         row
//                                         value={formdata.maintenance_Of_Anesthesia.level_Of_Anaesthesia} // Bind to state
//                                         onChange={handleChange} // Maintain existing handler
//                                     >
//                                         <Box sx={{ display: "flex", alignItems: "center" }}>
//                                             <FormControlLabel value="Deep" sx={{
//                                                 display: 'flex',
//                                                 alignItems: "center",

//                                             }} control={<Radio sx={{
//                                                 "& .MuiSvgIcon-root": {
//                                                     height: 15,
//                                                     width: 15,
//                                                 }
//                                             }} />} label={<span style={{ fontSize: "10px" }}>Deep</span>} />
//                                             <FormControlLabel value="superficial" control={<Radio sx={{
//                                                 "& .MuiSvgIcon-root": {
//                                                     height: 15,
//                                                     width: 15,
//                                                 }
//                                             }} />} label={<span style={{ fontSize: '10px' }}>SuperFicial</span>} />
//                                         </Box>
//                                     </RadioGroup>
//                                 </Grid>
//                                 <Grid item md={2.5} sm={12} xs={12}>
//                                     <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
//                                         MISC(Optional)
//                                     </Typography>
//                                     <TextField
//                                         required
//                                         placeholder="Misc"
//                                         name="misc"
//                                         fullWidth
//                                         className="search-field"
//                                         size="small"
//                                         value={formdata.maintenance_Of_Anesthesia.misc} // Bind to state
//                                         onChange={handleChange} // Maintain existing handler
//                                         sx={{ border: "1px solid rgba(229, 229, 229, 1)", bgcolor: "white" }}
//                                     />
//                                 </Grid>
//                                 <Grid item md={1} sx={{ mt: 2.5 }}>
//                                     <Button
//                                         disabled={!formdata?.maintenance_Of_Anesthesia?.SpO2 || !formdata?.maintenance_Of_Anesthesia?.BP || !formdata?.maintenance_Of_Anesthesia?.RR || !formdata?.maintenance_Of_Anesthesia?.HR || !formdata?.maintenance_Of_Anesthesia?.drugs_Misc}
//                                         size='small' onClick={handleSaveMaintenance} variant="contained">Save</Button>
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                     </Grid>
//                 </Grid>
//                 <Grid container spacing={1} sx={{ mt: 1 }}>

//                     <Grid item md={12} sm={12} xs={12}>
//                         <DynamicTable headers={["SN", "Date & Time", "HR", "BP", "RR", "SpO2", "Cardiac Rhythm", "Airway Sec.Pt", "Lvl of Anesthesia", "Drugs", "Misc", "Action"]}
//                             data={anesthesiaRecords?.map((item: any, index: number) => [
//                                 index + 1,
//                                 convertTime(item?.timestamp),
//                                 item?.HR,
//                                 item?.BP,
//                                 item?.RR,
//                                 item?.SpO2,
//                                 item?.cardiac_Rhythm,
//                                 item?.airway_Security_Patency,
//                                 item?.level_Of_Anaesthesia,
//                                 item?.drugs_Misc,
//                                 item?.misc,

//                             ])}
//                             actionButtons={[
//                                 {
//                                     icon: <EditIcon sx={{ color: '#FFB800', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(133, 96, 1)' } }} />,
//                                     onClick: (rowIndex: number) => handleEdit(rowIndex)
//                                 },
//                                 {
//                                     icon: <DeleteIcon sx={{ color: '#FF5454', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(191, 44, 44)' } }} />,
//                                     onClick: (rowIndex: number) => handleDelete(rowIndex)
//                                 }
//                             ]}
//                         />
//                     </Grid>

//                 </Grid>
//                 <Dialog open={isOpen}>
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                         <DialogTitle>
//                             Anesthesia Plan
//                         </DialogTitle>
//                         <CancelOutlinedIcon sx={{ cursor: 'pointer', mr: 2 }} onClick={handleClose} />
//                     </Box>
//                     <DialogContent>
//                         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//                             <Box sx={{ border: '1px solid #000', height: '100%', width: '100%', p: 2 }}>
//                                 <Box sx={{ backgroundColor: '#000', color: '#fff', p: 1, mb: 2 }}>
//                                     <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
//                                         SIGN IN
//                                     </Typography>
//                                 </Box>
//                                 <FormGroup sx={{ display: 'flex', flexDirection: 'column' }}>
//                                     {/* PATIENT HAS CONFIRMED */}
//                                     <Box sx={{ display: 'flex', alignItems: "flex-start", flexDirection: 'row', gap: .5 }}>
//                                         <FormControlLabel
//                                             control={<Checkbox name="conformation" checked={formdata.sign_in.conformation} onChange={handleSignInChange} size="small" />}
//                                             label={""}
//                                         />
//                                         <Typography variant="body2">
//                                             Patient has confirmed:
//                                             <ul style={{ margin: 0, paddingLeft: '20px' }}>
//                                                 <li>
//                                                     <FiberManualRecordIcon
//                                                         sx={{ fontSize: "11px" }}
//                                                     />Name : {otherTabsData?.patientData?.patientName}
//                                                 </li>
//                                                 <li>
//                                                     <FiberManualRecordIcon
//                                                         sx={{ fontSize: "11px" }}
//                                                     />MRN : {otherTabsData?.patientData?.mrn}
//                                                 </li>
//                                                 <li>
//                                                     <FiberManualRecordIcon
//                                                         sx={{ fontSize: "11px" }}
//                                                     />Identity
//                                                 </li>
//                                                 <li>
//                                                     <FiberManualRecordIcon
//                                                         sx={{ fontSize: "11px" }}
//                                                     /> Site
//                                                 </li>

//                                                 <li>
//                                                     <FiberManualRecordIcon
//                                                         sx={{ fontSize: "11px" }}
//                                                     /> Procedure

//                                                 </li>

//                                                 <li>
//                                                     <FiberManualRecordIcon
//                                                         sx={{ fontSize: "11px" }}
//                                                     /> Consent

//                                                 </li>

//                                             </ul>
//                                         </Typography>
//                                     </Box>
//                                     <Divider />

//                                     {/* SITE MARKED */}
//                                     <FormControlLabel
//                                         control={<Checkbox name="site_Marked" checked={formdata.sign_in.site_Marked} onChange={handleSignInChange} size="small" />}
//                                         label={<Typography variant="body2">Site Marked/Not Applicable</Typography>}
//                                     />
//                                     <Divider />

//                                     {/* ANAESTHESIA SAFETY CHECK */}
//                                     <FormControlLabel
//                                         control={<Checkbox name="safety_Check" checked={formdata.sign_in.safety_Check} onChange={handleSignInChange} size="small" />}
//                                         label={<Typography variant="body2">Anaesthesia Safety Check Completed</Typography>}
//                                     />
//                                     <Divider />

//                                     {/* PULSE OXIMETER */}
//                                     <FormControlLabel
//                                         control={<Checkbox name="pulse_Oximeter" checked={formdata.sign_in.pulse_Oximeter} onChange={handleSignInChange} size="small" />}
//                                         label={<Typography variant="body2">Pulse Oximeter on Patient and Functioning</Typography>}
//                                     />
//                                     {/* <FormControlLabel
//                                         control={<Checkbox name="patient_Oriented" checked={formdata.sign_in.patient_Oriented} onChange={handleSignInChange} size="small" />}
//                                         label={<Typography variant="body2">Patient Oriented?</Typography>}
//                                     /> */}
//                                     <Divider />
//                                     {/* Patient Oriented */}
//                                     {/* Patient Oriented */}
//                                     {/* Patient Oriented */}
//                                     <Typography variant="body2">Is the Patient Oriented?</Typography>
//                                     <FormControlLabel
//                                         control={
//                                             <Checkbox
//                                                 name="patient_Oriented"
//                                                 checked={formdata.sign_in.patient_Oriented === true}
//                                                 onChange={() =>
//                                                     setFormdata({
//                                                         ...formdata,
//                                                         sign_in: { ...formdata.sign_in, patient_Oriented: true }
//                                                     })
//                                                 }
//                                                 size="small"
//                                             />
//                                         }
//                                         label={<Typography variant="body2">Yes</Typography>}
//                                     />
//                                     <FormControlLabel
//                                         control={
//                                             <Checkbox
//                                                 name="patient_Oriented"
//                                                 checked={formdata.sign_in.patient_Oriented === false}
//                                                 onChange={() =>
//                                                     setFormdata({
//                                                         ...formdata,
//                                                         sign_in: { ...formdata.sign_in, patient_Oriented: false }
//                                                     })
//                                                 }
//                                                 size="small"
//                                             />
//                                         }
//                                         label={<Typography variant="body2">No</Typography>}
//                                     />



//                                     {/* KNOWN ALLERGY */}
//                                     <Typography variant="body2">Does Patient Have a Known Allergy?</Typography>
//                                     <FormControlLabel
//                                         control={<Checkbox name="Known_Allergy" checked={formdata.sign_in.Known_Allergy === "yes"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, Known_Allergy: "yes" } })} size="small" />}
//                                         label={<Typography variant="body2">Yes</Typography>}
//                                     />
//                                     <FormControlLabel
//                                         control={<Checkbox name="Known_Allergy" checked={formdata.sign_in.Known_Allergy === "no"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, Known_Allergy: "no" } })} size="small" />}
//                                         label={<Typography variant="body2">No</Typography>}
//                                     />
//                                     {/* DIFFICULT AIRWAY */}
//                                     <Typography variant="body2">Difficult Airway/Aspiration Risk?</Typography>
//                                     <FormControlLabel
//                                         control={<Checkbox name="difficulty_Risk" checked={formdata.sign_in.difficulty_Risk === "yes"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, difficulty_Risk: "yes" } })} size="small" />}
//                                         label={<Typography variant="body2">Yes, and Equipment/Assistance Available</Typography>}
//                                     />
//                                     <FormControlLabel
//                                         control={<Checkbox name="difficulty_Risk" checked={formdata.sign_in.difficulty_Risk === "no"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, difficulty_Risk: "no" } })} size="small" />}
//                                         label={<Typography variant="body2">No</Typography>}
//                                     />
//                                     {/* RISK OF BLOOD LOSS */}
//                                     <Typography variant="body2">Risk of 500ML Blood Loss (7ML/KG in Children)?</Typography>
//                                     <FormControlLabel
//                                         control={<Checkbox name="risk_Of_Blood_Loss" checked={formdata.sign_in.risk_Of_Blood_Loss === "yes"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, risk_Of_Blood_Loss: "yes" } })} size="small" />}
//                                         label={<Typography variant="body2">Yes, and Adequate Intravenous Access and Fluids Planned</Typography>}
//                                     />
//                                     <FormControlLabel
//                                         control={<Checkbox name="risk_Of_Blood_Loss" checked={formdata.sign_in.risk_Of_Blood_Loss === "no"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, risk_Of_Blood_Loss: "no" } })} size="small" />}
//                                         label={<Typography variant="body2">No</Typography>}
//                                     />
//                                 </FormGroup>
//                             </Box>
//                         </Box>
//                     </DialogContent>
//                     <DialogActions>
//                         <Button onClick={handleSave} variant="contained">Save</Button>
//                     </DialogActions>
//                 </Dialog>
//                 {/* <List>
//                             <ListItem>Plan Name: {formdata.anesthesia_Plan}</ListItem>
//                             <ListItem>Plan Date: {formdata.anesthesiaPlanDate}</ListItem>
//                             <ListItem>Plan Author: {formdata.anesthesiaPlanAuthor}</ListItem>
//                         </List> */}
//             </Box >
//         </>
//     )
// }

// export default OtRoomAnaesthesiaMonitoring


import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DynamicTable from "../../../../Dynamic-table";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import axios from "axios";

interface OtAnaesthesiaMonitoring {
    patient_Identified: string;
    mrNoChecked: string;
    consentChecked: string;
    siteChecked: string;
    patient_Oriented: boolean;
}
function convertTime(inputTime: string): string {
    // Create a Date object from the input time string
    const date = new Date(inputTime);

    // Format the date to DD.MM.YYYY:HH:MM AM/PM
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    // Get the hour and minutes from the date object
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    if (hours === 0) {
        hours = 12; // 0 becomes 12 (midnight or noon)
    }

    // Format minutes
    const minute = String(minutes).padStart(2, '0');

    // Return the formatted string with the actual time
    return `${day}.${month}.${year}:${String(hours).padStart(2, '0')}:${minute} ${ampm}`;
}
interface TemplateData {
    details: string;
}

// Define the type for each Template
interface Template {
    _id: string;
    templateName: string;
    templateData: TemplateData;  // This includes the template details
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    isDeleted: boolean;
}
interface maintenance_Of_Anesthesia {
    HR: string;
    cardiac_Rhythm: string;
    RR: string;
    BP: string;
    airway_Security_Patency: string;
    SpO2: string;
    level_Of_Anaesthesia: string;
    drugs_Misc: any
    misc: any
}
interface Vitals {
    Hr: string;

    Rr: string;
    Bp: string;

    spO2: string;

    temp: string
}
interface maintenance_Of_Anesthesia {
    HR: string;
    RR: string;
    BP: string;
    SpO2: string;
    airway_Security_Patency: string;
    cardiac_Rhythm: string;
    level_Of_Anaesthesia: string;
    drugs_Misc: any;
    misc: any;
}

interface FormData {
    ot_anesthesia_monitoring: OtAnaesthesiaMonitoring;
    maintenance_Of_Anesthesia: maintenance_Of_Anesthesia;
    vitals: Vitals
    anesthesia_Plan: string,
    detail: string
}
interface OtRoomAnaesthesiaMonitoringProps {
    onChange: (data: any) => void;
    data: FormData;
    otherTabsData: any;
    profile: any;
}
const OtRoomAnaesthesiaMonitoring: React.FC<OtRoomAnaesthesiaMonitoringProps> = ({ onChange, data, otherTabsData, profile }) => {
    const [formdata, setFormdata] = useState({
        sign_in: {
            patient_Confirmed: true,
            site_Marked: true,
            anaesthesia_Safety_Check: true,
            pulse_Oximeter: true,
            patient_Known_Allergy: "yes",
            difficulty_Risk: "yes",
            risk_Of_Blood_Loss: "yes",
            saved_At: null,
            patient_Oriented: true
        },
        maintenance_Of_Anesthesia: {
            HR: '',
            cardiac_Rhythm: 'Regular',
            RR: '',
            BP: '',
            airway_Security_Patency: 'Patent',
            SpO2: '',
            level_Of_Anaesthesia: 'Deep',
            drugs_Misc: '',
            misc: ''
        },
        vitals: {
            Hr: '',
            Rr: '',
            Bp: '',
            spO2: '',
            temp: '',
        },
        anesthesia_Plan: '',
        detail: ""
    });

    const [anesthesiaRecords, setAnesthesiaRecords] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false)
    const [editingIndex, setEditingIndex] = useState(null);
    const [isMultiline, setIsMultiline] = useState(false);
    const [isMultilinetwo, setIsMultilinetwo] = useState(false);
    const handleFocustwo = () => {
        setIsMultilinetwo(true);
    };

    const handleBlurtwo = () => {
        setIsMultilinetwo(false);
    };
    const handleFocus = () => {
        setIsMultiline(true);
    };

    const handleBlur = () => {
        setIsMultiline(false);
    };
    const [templates, setTemplates] = useState<Template[]>([]); // An array of Template objects

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/getAllTemplates?ope_type=Anesthesia`,
                    {
                        headers: {
                            accept: '*/*',
                            Authorization: `Bearer ${localStorage.getItem("token")}`, // Replace with actual token
                        },
                    }
                );

                if (response.data?.isSuccess) {
                    setTemplates(response?.data?.data); // Store the fetched templates
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };
        fetchTemplates();
    }, []);

    // Handle the change in the "Anesthesia Plan" select dropdown
    const handleAnesthesiaPlanChange = (event: SelectChangeEvent<string>) => {
        const selectedTemplate = event.target.value;

        // Find the template data corresponding to the selected template name
        const selectedTemplateData = templates?.find(
            (template) => template.templateName === selectedTemplate
        );

        // If template data is found, update formdata
        if (selectedTemplateData) {
            setFormdata((prevData) => ({
                ...prevData,
                anesthesia_Plan: selectedTemplate, // Store the selected template name
                detail: selectedTemplateData.templateData.details || '', // Autofill the detail field with template details
            }));
        }
    };

    const handleSaveMaintenance = () => {
        // const newRecord = { ...formdata.maintenance_Of_Anesthesia };
        const newRecord = {
            ...formdata.maintenance_Of_Anesthesia,
            // timestamp: new Date().toLocaleString() // Save current date and time
            timestamp: new Date().toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            })
        };
        if (editingIndex !== null) {
            setAnesthesiaRecords((prevRecords: any) =>
                prevRecords?.map((record: any, index: any) =>
                    index === editingIndex ? newRecord : record
                )
            );
            setEditingIndex(null);
        } else {
            setAnesthesiaRecords((prevRecords: any) => [...prevRecords, newRecord]);
        }
        setFormdata((prev) => ({
            ...prev,
            maintenance_Of_Anesthesia: {
                HR: '',
                cardiac_Rhythm: 'Regular',
                RR: '',
                BP: '',
                airway_Security_Patency: 'Patent',
                SpO2: '',
                level_Of_Anaesthesia: 'Deep',
                drugs_Misc: '',
                misc: ''
            },
        }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        // if (name in formdata?.ot_anesthesia_monitoring) {
        //     setFormdata((prev) => ({
        //         ...prev,
        //         ot_anesthesia_monitoring: {
        //             ...prev?.ot_anesthesia_monitoring,
        //             [name]: value,
        //         },
        //     }));

        // } 
        if (name in formdata?.maintenance_Of_Anesthesia) {
            setFormdata((prev) => ({
                ...prev,
                maintenance_Of_Anesthesia: {
                    ...prev?.maintenance_Of_Anesthesia,
                    [name]: value,
                },
            }));
        } else if (name in formdata?.vitals) {
            setFormdata((prev) => ({
                ...prev,
                vitals: {
                    ...prev.vitals,
                    [name]: value,
                },
            }));
        }
    };

    const handleSignInChange = (event: any) => {
        const { name, value, checked, type } = event.target;
        setFormdata((prevState) => ({
            ...prevState,
            sign_in: {
                ...prevState.sign_in,
                [name]: type === 'checkbox' ? checked : value,
            },
        }));
    };
    const handleSave = () => {
        const currentTime = new Date().toISOString();
        // const currentTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Karachi" });
        setFormdata((prevState: any) => ({
            ...prevState,
            sign_in: {
                ...prevState.sign_in,
                savedAt: currentTime,
            },
        }));
        handleClose();
    };

    const handleClose = () => {
        setIsOpen(false)
    };
    const handleOpen = () => {
        setIsOpen(true)
    };
    useEffect(() => {
        onChange({ maintenance_Of_Anesthesia: anesthesiaRecords, vitals: formdata?.vitals, anesthesia_Plan: formdata?.anesthesia_Plan, detail: formdata?.detail, sign_in: formdata?.sign_in })
    }, [formdata, anesthesiaRecords])
    useEffect(() => {
        if (data) {
            setFormdata((prevData: any) => {
                const updatedData = { ...prevData, ...data };
                // onChange(updatedData);
                return updatedData;
            });
        }
        if (data?.maintenance_Of_Anesthesia) {
            setAnesthesiaRecords(data?.maintenance_Of_Anesthesia)
            setFormdata((prev) => ({
                ...prev,
                maintenance_Of_Anesthesia: {
                    HR: '',
                    cardiac_Rhythm: '',
                    RR: '',
                    BP: '',
                    airway_Security_Patency: '',
                    SpO2: '',
                    level_Of_Anaesthesia: '',
                    drugs_Misc: '',
                    misc: ''
                },
            }));
        }
    }, [data]);

    const handleDelete = (index: any) => {
        setAnesthesiaRecords((prevRecords: any) =>
            prevRecords.filter((_: any, i: any) => i !== index)
        );
    };

    const handleEdit = (index: any) => {
        setEditingIndex(index);
        setFormdata((prev: any) => ({
            ...prev,
            maintenance_Of_Anesthesia: anesthesiaRecords[index],
        }));
    };
    return (
        <>
            <Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ mb: 1 }} flexDirection={"row"}>
                    <Typography variant="h6">OT Room Anaesthesia Monitoring</Typography>
                    {/* <Button variant="contained" onClick={handleOpen}>sign_in</Button> */}
                    <Button variant="contained"
                        sx={{
                            background: formdata?.sign_in && Object.values(formdata.sign_in).some(value => value === true || value === "yes" || value === "no") ? '#009E65' : '#FFBF00',
                            "&:hover": {
                                background: formdata?.sign_in && Object.values(formdata.sign_in).some(value => value === true || value === "yes" || value === "no") ? '#009E65' : '#FFBF00',
                            },
                        }}
                        onClick={handleOpen}>sign_in
                    </Button>
                </Box>
                <Grid container spacing={1}>
                    <Grid item md={5.5} sm={12} xs={12}>
                        <Box sx={{ bgcolor: "rgba(229, 229, 229, 1)", textAlign: "center", }}>
                            <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600", py: .5 }}>
                                Vitals
                            </Typography>
                        </Box>
                        <Box sx={{ bgcolor: "rgba(245, 245, 245, 1)", p: 1 }}>
                            <Grid container spacing={1}>
                                <Grid item md={2.4} sm={4} xs={6}>
                                    <TextField
                                        label="Temp" // Label for the input
                                        placeholder="92-107"
                                        size="small"
                                        className="search-field"
                                        value={formdata?.vitals?.temp}
                                        name="temp"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;
                                            // Allow only numeric inputs
                                            if (/^\d*$/.test(value)) {
                                                const numericValue = value ? Number(value) : null;

                                                // Update state and validate range
                                                if (numericValue === null || (numericValue >= 92 && numericValue <= 107)) {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        vitals: {
                                                            ...prev.vitals,
                                                            temp: value, // Update if valid
                                                        },
                                                    }));
                                                } else {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        vitals: {
                                                            ...prev.vitals,
                                                            temp: value, // Allow invalid value to show red border
                                                        },
                                                    }));
                                                }
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span style={{ fontSize: "10px" }}>F</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            backgroundColor: "white",
                                            ...(formdata?.vitals?.temp &&
                                                (Number(formdata.vitals.temp) < 92 || Number(formdata.vitals.temp) > 107)
                                                ? { border: "1px solid red" } // Add red border if invalid
                                                : {}),
                                            borderRadius: "4px",
                                        }}
                                    />
                                </Grid>

                                <Grid item md={2.4} sm={4} xs={6}>
                                    <TextField
                                        label="HR"
                                        placeholder="50-250"
                                        size="small"
                                        name="Hr"
                                        value={formdata?.vitals?.Hr}
                                        className="search-field"
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            // Allow only numeric input
                                            if (/^\d*$/.test(value)) {
                                                const numericValue = value ? Number(value) : null;

                                                // Update state if value is within range
                                                if (numericValue === null || (numericValue >= 50 && numericValue <= 250)) {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        vitals: {
                                                            ...prev.vitals,
                                                            Hr: value, // Update if valid
                                                        },
                                                    }));
                                                } else {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        vitals: {
                                                            ...prev.vitals,
                                                            Hr: value, // Allow invalid value to show red border
                                                        },
                                                    }));
                                                }
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span style={{ fontSize: "12px" }}>/Min</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            backgroundColor: "white",
                                            ...(formdata?.vitals?.Hr &&
                                                (Number(formdata.vitals.Hr) < 50 || Number(formdata.vitals.Hr) > 250)
                                                ? { border: "1px solid red" } // Add red border if invalid
                                                : {}),
                                            borderRadius: "4px",
                                        }}
                                    />
                                </Grid>

                                <Grid item md={2.4} sm={4} xs={6}>
                                    <TextField
                                        label="RR" // Label for the input
                                        placeholder="1-80"
                                        size="small"
                                        name="Rr"
                                        value={formdata?.vitals?.Rr}
                                        className="search-field"
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            // Allow only numeric input
                                            if (/^\d*$/.test(value)) {
                                                const numericValue = value ? Number(value) : null;

                                                // Update state if value is within range
                                                if (numericValue === null || (numericValue >= 1 && numericValue <= 80)) {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        vitals: {
                                                            ...prev.vitals,
                                                            Rr: value, // Update if valid
                                                        },
                                                    }));
                                                } else {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        vitals: {
                                                            ...prev.vitals,
                                                            Rr: value, // Update state for invalid value to show red border
                                                        },
                                                    }));
                                                }
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span style={{ fontSize: "10px" }}>Min</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            backgroundColor: "white",
                                            border: formdata?.vitals?.Rr && (Number(formdata.vitals.Rr) < 1 || Number(formdata.vitals.Rr) > 80)
                                                ? "1px solid red" // Red border for invalid input
                                                : "none",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </Grid>

                                <Grid item md={2.4} sm={4} xs={6}>
                                    <TextField
                                        label="BP" // Label for the input
                                        placeholder="120/80"
                                        size="small"
                                        className='search-field'
                                        value={formdata.vitals.Bp}
                                        name="Bp"
                                        onChange={handleChange}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '10px' }}>mgHg</span>
                                                </InputAdornment>
                                            )
                                        }}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item md={2.4} sm={4} xs={6}>
                                    <Box display="flex" alignItems="center" className="search-field" bgcolor={"white"}>
                                        <TextField
                                            label="SPO₂" // Label for the input
                                            placeholder="SPO2"
                                            name="spO2"
                                            size="small"
                                            value={formdata.vitals.spO2}
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value) && (value === '' || parseFloat(value) <= 100)) {
                                                    handleChange(e);
                                                }
                                            }}
                                            fullWidth
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    fontSize: '10px',
                                                    borderRadius: '12px',
                                                    backgroundColor: "white",
                                                },
                                            }}
                                        />
                                        <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px', }}>
                                            %
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={1}>
                            {/* Anesthesia Plan Select */}
                            <Grid item md={4} xs={6} sm={6}>
                                <Typography sx={{ fontSize: '14px', color: '#3F3F3F', fontWeight: '600', py: 0.5 }}>
                                    Anesthesia Given
                                </Typography>
                                <FormControl fullWidth size="small">
                                    <Select
                                        name="anesthesia_Plan"
                                        value={formdata.anesthesia_Plan || 'plan'} // Bound to state
                                        onChange={handleAnesthesiaPlanChange} // Update formdata on selection
                                        sx={{
                                            borderRadius: '12px',
                                            border: '0px solid #D8D8D8',
                                            background: '#F5F5FB',
                                            fontSize: '12px',
                                            height: '38px',
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                        }}
                                    >
                                        <MenuItem value="plan">Select Anesthesia Plan</MenuItem>
                                        {templates?.map((template) => (
                                            <MenuItem key={template?._id} value={template?.templateName}>
                                                {template?.templateName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Anesthesia Plan Details */}
                            <Grid item md={8} xs={6} sm={6}>
                                <Typography sx={{ fontSize: '14px', color: '#3F3F3F', fontWeight: '600', py: 0.5 }}>
                                    Details:
                                </Typography>
                                <TextField
                                    size="small"
                                    label="Detail"
                                    value={formdata?.detail} // Bind value of the detail field
                                    onFocus={handleFocustwo}
                                    onChange={(e) =>
                                        setFormdata((prev) => ({
                                            ...prev,
                                            detail: e.target.value,
                                        }))
                                    }
                                    onBlur={handleBlurtwo}
                                    multiline={isMultilinetwo}
                                    minRows={isMultilinetwo ? 3 : 1}
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                            background: "#F5F5FB",
                                            fontSize: "12px",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            "&:hover fieldset": {
                                                border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            top: "-2px",
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6.5} sm={12} xs={12}>
                        <Box sx={{ bgcolor: "rgba(229, 229, 229, 1)", textAlign: "center", }}>
                            <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600", py: .5 }}>
                                Maintenance of Anesthesia
                            </Typography>
                        </Box>
                        <Box sx={{ bgcolor: "rgba(245, 245, 245, 1)", p: 1 }}>
                            <Grid container spacing={1}>
                                <Grid item md={2} sm={4} xs={6}>
                                    <Box>
                                        <TextField required placeholder="Drugs" name="drugs_Misc" fullWidth
                                            label="Drugs"
                                            size="small"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        border: "none",
                                                    },
                                                    "&:hover fieldset": {
                                                        border: "none",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        border: "none",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    top: "-2px",
                                                },
                                                bgcolor: "white"
                                            }}
                                            value={formdata?.maintenance_Of_Anesthesia?.drugs_Misc} // Bind to state
                                            onChange={handleChange} // Maintain existing handler
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            multiline={isMultiline}
                                            minRows={isMultiline ? 3 : 1}
                                            InputProps={{
                                                style: {
                                                    borderRadius: "12px",
                                                    // background: "#F5F5FB",
                                                    fontSize: "12px",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item md={2.5} sm={4} xs={6} >

                                    <TextField
                                        label="HR" // Label for the input
                                        placeholder="50-250"
                                        size="small"
                                        sx={{ backgroundColor: "white" }}
                                        className='search-field'
                                        value={formdata?.maintenance_Of_Anesthesia?.HR}
                                        name="HR"
                                        onChange={(e: any) => {
                                            const value = e.target.value;
                                            // Allow only digits (and decimal point if needed)
                                            if (/^\d*\.?\d*$/.test(value) || value === '') {
                                                const numericValue = value ? Number(value) : null; // Convert to number if not empty
                                                // Update state if the value is valid (0-300)
                                                if (numericValue === null || (numericValue >= 0 && numericValue <= 250)) {
                                                    handleChange(e); // Call the existing handler
                                                }
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '12px' }}>/Min</span>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2.5} sm={4} xs={6}>
                                    <TextField
                                        label="RR" // Label for the input
                                        placeholder="1-80"
                                        size="small"
                                        name="RR"
                                        value={formdata?.maintenance_Of_Anesthesia?.RR} // Bind to state
                                        className="search-field"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;

                                            // Allow only numeric inputs
                                            if (/^\d*$/.test(value)) {
                                                const numericValue = value ? Number(value) : null;

                                                // Update state and validate range
                                                if (numericValue === null || (numericValue >= 1 && numericValue <= 80)) {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        maintenance_Of_Anesthesia: {
                                                            ...prev.maintenance_Of_Anesthesia,
                                                            RR: value, // Update if valid
                                                        },
                                                    }));
                                                } else {
                                                    setFormdata((prev) => ({
                                                        ...prev,
                                                        maintenance_Of_Anesthesia: {
                                                            ...prev.maintenance_Of_Anesthesia,
                                                            RR: value, // Update for invalid value to show feedback
                                                        },
                                                    }));
                                                }
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span style={{ fontSize: "12px" }}>ml</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            backgroundColor: "white",
                                            border: formdata?.maintenance_Of_Anesthesia?.RR &&
                                                (Number(formdata.maintenance_Of_Anesthesia.RR) < 1 || Number(formdata.maintenance_Of_Anesthesia.RR) > 80)
                                                ? "1px solid red" // Add red border if invalid
                                                : "1px solid transparent", // Transparent border for valid input
                                            borderRadius: "4px",
                                        }}
                                    />
                                </Grid>

                                <Grid item md={2.5} sm={4} xs={6}>
                                    <TextField
                                        label="BP" // Label for the input
                                        placeholder="120/80"
                                        size="small"
                                        className='search-field'
                                        value={formdata?.maintenance_Of_Anesthesia?.BP} // Bind to state
                                        onChange={handleChange} // Maintain existing handler
                                        name="BP"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <span style={{ fontSize: '12px' }}>mgHg</span>
                                                </InputAdornment>
                                            )
                                        }}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item md={2.5} sm={4} xs={6}>
                                    <Box display="flex" alignItems="center" className="search-field" bgcolor={"white"}>
                                        <TextField
                                            label="SPO₂" // Label for the input
                                            placeholder="SPO2"
                                            name="SpO2"
                                            size="small"
                                            value={formdata?.maintenance_Of_Anesthesia?.SpO2}
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value) && (value === '' || parseFloat(value) <= 100)) {
                                                    handleChange(e);
                                                }
                                            }}
                                            fullWidth
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    fontSize: '12px',
                                                    borderRadius: '12px',
                                                    backgroundColor: "white",
                                                },
                                            }}
                                        />
                                        <Typography sx={{ padding: "10px", ml: 1, fontWeight: "500", fontSize: '12px', }}>
                                            %
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item md={4} sm={4} xs={6}>
                                    <Typography sx={{ fontSize: "12px", color: "#3F3F3F", fontWeight: "600" }}>
                                        Airway Security Patency   :                                </Typography>
                                    <RadioGroup
                                        name="airway_Security_Patency"
                                        row
                                        value={formdata?.maintenance_Of_Anesthesia?.airway_Security_Patency} // Bind to state
                                        onChange={handleChange} // Maintain existing handler
                                        sx={{ alignItems: 'center' }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel value="Patent" control={<Radio sx={{
                                                "& .MuiSvgIcon-root": {
                                                    height: 15,
                                                    width: 15,
                                                }
                                            }} />} label={<span style={{ fontSize: '10px' }}>Patent</span>} />
                                            <FormControlLabel value="NotPatent" control={<Radio sx={{
                                                "& .MuiSvgIcon-root": {
                                                    height: 15,
                                                    width: 15,
                                                }
                                            }} />} label={<span style={{ fontSize: '10px' }}> Not Patent </span>} /></Box>
                                    </RadioGroup>
                                </Grid>
                                <Grid item md={4} sm={4} xs={6}>
                                    <Typography sx={{ fontSize: "12px", color: "#3F3F3F", fontWeight: "600" }}>
                                        Cardiac Rhythm    :
                                    </Typography>
                                    <RadioGroup
                                        name="cardiac_Rhythm"
                                        row
                                        value={formdata?.maintenance_Of_Anesthesia?.cardiac_Rhythm}
                                        onChange={handleChange}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel value="Regular" control={<Radio sx={{
                                                "& .MuiSvgIcon-root": {
                                                    height: 15,
                                                    width: 15,
                                                }
                                            }} />} label={<span style={{ fontSize: '10px' }}>Regular</span>} />
                                            <FormControlLabel value="Irregular" control={<Radio sx={{
                                                "& .MuiSvgIcon-root": {
                                                    height: 15,
                                                    width: 15,
                                                }
                                            }} />} label={<span style={{ fontSize: '10px' }}>Irregular</span>} /></Box>
                                    </RadioGroup>
                                </Grid>
                                <Grid item md={4} sm={4} xs={6}>
                                    <Typography sx={{ fontSize: "12px", color: "#3F3F3F", fontWeight: "600" }}>
                                        Level of Anaesthesia :                                </Typography>
                                    <RadioGroup
                                        name="level_Of_Anaesthesia"
                                        row
                                        value={formdata.maintenance_Of_Anesthesia.level_Of_Anaesthesia} // Bind to state
                                        onChange={handleChange} // Maintain existing handler
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel value="Deep" sx={{
                                                display: 'flex',
                                                alignItems: "center",

                                            }} control={<Radio sx={{
                                                "& .MuiSvgIcon-root": {
                                                    height: 15,
                                                    width: 15,
                                                }
                                            }} />} label={<span style={{ fontSize: "10px" }}>Deep</span>} />
                                            <FormControlLabel value="superficial" control={<Radio sx={{
                                                "& .MuiSvgIcon-root": {
                                                    height: 15,
                                                    width: 15,
                                                }
                                            }} />} label={<span style={{ fontSize: '10px' }}>SuperFicial</span>} />
                                        </Box>
                                    </RadioGroup>
                                </Grid>
                                <Grid item md={2.5} sm={12} xs={12}>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                                        MISC(Optional)
                                    </Typography>
                                    <TextField
                                        required
                                        placeholder="Misc"
                                        name="misc"
                                        fullWidth
                                        className="search-field"
                                        size="small"
                                        value={formdata.maintenance_Of_Anesthesia.misc} // Bind to state
                                        onChange={handleChange} // Maintain existing handler
                                        sx={{ border: "1px solid rgba(229, 229, 229, 1)", bgcolor: "white" }}
                                    />
                                </Grid>
                                <Grid item md={1} sx={{ mt: 2.5 }}>
                                    <Button
                                        disabled={!formdata?.maintenance_Of_Anesthesia?.SpO2 || !formdata?.maintenance_Of_Anesthesia?.BP || !formdata?.maintenance_Of_Anesthesia?.RR || !formdata?.maintenance_Of_Anesthesia?.HR || !formdata?.maintenance_Of_Anesthesia?.drugs_Misc}
                                        size='small' onClick={handleSaveMaintenance} variant="contained">Save</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 1 }}>

                    <Grid item md={12} sm={12} xs={12}>
                        <DynamicTable headers={["SN", "Date & Time", "HR", "BP", "RR", "SpO2", "Cardiac Rhythm", "Airway Sec.Pt", "Lvl of Anesthesia", "Drugs", "Misc", "Action"]}
                            data={anesthesiaRecords?.map((item: any, index: number) => [
                                index + 1,
                                convertTime(item?.timestamp),
                                item?.HR,
                                item?.BP,
                                item?.RR,
                                item?.SpO2,
                                item?.cardiac_Rhythm,
                                item?.airway_Security_Patency,
                                item?.level_Of_Anaesthesia,
                                item?.drugs_Misc,
                                item?.misc,

                            ])}
                            actionButtons={[
                                {
                                    icon: <EditIcon sx={{ color: '#FFB800', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(133, 96, 1)' } }} />,
                                    onClick: (rowIndex: number) => handleEdit(rowIndex)
                                },
                                {
                                    icon: <DeleteIcon sx={{ color: '#FF5454', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(191, 44, 44)' } }} />,
                                    onClick: (rowIndex: number) => handleDelete(rowIndex)
                                }
                            ]}
                        />
                    </Grid>

                </Grid>
                <Dialog open={isOpen}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <DialogTitle>
                            Anesthesia Plan
                        </DialogTitle>
                        <CancelOutlinedIcon sx={{ cursor: 'pointer', mr: 2 }} onClick={handleClose} />
                    </Box>
                    <DialogContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ border: '1px solid #000', height: '100%', width: '100%', p: 2 }}>
                                <Box sx={{ backgroundColor: '#000', color: '#fff', p: 1, mb: 2 }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                        SIGN IN
                                    </Typography>
                                </Box>
                                <FormGroup sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* PATIENT HAS CONFIRMED */}
                                    <Box sx={{ display: 'flex', alignItems: "flex-start", flexDirection: 'row', gap: .5 }}>
                                        <FormControlLabel
                                            control={<Checkbox name="patient_Confirmed" checked={formdata.sign_in.patient_Confirmed} onChange={handleSignInChange} size="small" />}
                                            label={""}
                                        />
                                        <Typography variant="body2">
                                            Patient has confirmed:
                                            <ul style={{ margin: 0, paddingLeft: '20px' }}>
                                                <li>
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: "11px" }}
                                                    />Name : {otherTabsData?.patientData?.patientName}
                                                </li>
                                                <li>
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: "11px" }}
                                                    />MRN : {otherTabsData?.patientData?.mrn}
                                                </li>
                                                <li>
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: "11px" }}
                                                    />Identity
                                                </li>
                                                <li>
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: "11px" }}
                                                    /> Site
                                                </li>

                                                <li>
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: "11px" }}
                                                    /> Procedure

                                                </li>

                                                <li>
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: "11px" }}
                                                    /> Consent

                                                </li>

                                            </ul>
                                        </Typography>
                                    </Box>
                                    <Divider />

                                    {/* SITE MARKED */}
                                    <FormControlLabel
                                        control={<Checkbox name="site_Marked" checked={formdata.sign_in.site_Marked} onChange={handleSignInChange} size="small" />}
                                        label={<Typography variant="body2">Site Marked/Not Applicable</Typography>}
                                    />
                                    <Divider />

                                    {/* ANAESTHESIA SAFETY CHECK */}
                                    <FormControlLabel
                                        control={<Checkbox name="anaesthesia_Safety_Check" checked={formdata.sign_in.anaesthesia_Safety_Check} onChange={handleSignInChange} size="small" />}

                                        label={<Typography variant="body2">Anaesthesia Safety Check Completed</Typography>}
                                    />
                                    <Divider />

                                    {/* PULSE OXIMETER */}
                                    <FormControlLabel
                                        control={<Checkbox name="pulse_Oximeter" checked={formdata.sign_in.pulse_Oximeter} onChange={handleSignInChange} size="small" />}
                                        label={<Typography variant="body2">Pulse Oximeter on Patient and Functioning</Typography>}
                                    />
                                    {/* <FormControlLabel
                                        control={<Checkbox name="patient_Oriented" checked={formdata.sign_in.patient_Oriented} onChange={handleSignInChange} size="small" />}
                                        label={<Typography variant="body2">Patient Oriented?</Typography>}
                                    /> */}
                                    <Divider />
                                    {/* Patient Oriented */}
                                    {/* Patient Oriented */}
                                    {/* Patient Oriented */}
                                    <Typography variant="body2">Is the Patient Oriented?</Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="patient_Oriented"
                                                checked={formdata.sign_in.patient_Oriented === true}
                                                onChange={() =>
                                                    setFormdata({
                                                        ...formdata,
                                                        sign_in: { ...formdata.sign_in, patient_Oriented: true }
                                                    })
                                                }
                                                size="small"
                                            />
                                        }
                                        label={<Typography variant="body2">Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="patient_Oriented"
                                                checked={formdata.sign_in.patient_Oriented === false}
                                                onChange={() =>
                                                    setFormdata({
                                                        ...formdata,
                                                        sign_in: { ...formdata.sign_in, patient_Oriented: false }
                                                    })
                                                }
                                                size="small"
                                            />
                                        }
                                        label={<Typography variant="body2">No</Typography>}
                                    />



                                    {/* KNOWN ALLERGY */}
                                    <Typography variant="body2">Does Patient Have a Known Allergy?</Typography>
                                    <FormControlLabel
                                        control={<Checkbox name="patient_Known_Allergy" checked={formdata.sign_in.patient_Known_Allergy === "yes"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, patient_Known_Allergy: "yes" } })} size="small" />}
                                        label={<Typography variant="body2">Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="patient_Known_Allergy" checked={formdata.sign_in.patient_Known_Allergy === "no"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, patient_Known_Allergy: "no" } })} size="small" />}
                                        label={<Typography variant="body2">No</Typography>}
                                    />
                                    {/* DIFFICULT AIRWAY */}
                                    <Typography variant="body2">Difficult Airway/Aspiration Risk?</Typography>
                                    <FormControlLabel
                                        control={<Checkbox name="difficulty_Risk" checked={formdata.sign_in.difficulty_Risk === "yes"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, difficulty_Risk: "yes" } })} size="small" />}
                                        label={<Typography variant="body2">Yes, and Equipment/Assistance Available</Typography>}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="difficulty_Risk" checked={formdata.sign_in.difficulty_Risk === "no"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, difficulty_Risk: "no" } })} size="small" />}
                                        label={<Typography variant="body2">No</Typography>}
                                    />
                                    {/* RISK OF BLOOD LOSS */}
                                    <Typography variant="body2">Risk of 500ML Blood Loss (7ML/KG in Children)?</Typography>
                                    <FormControlLabel
                                        control={<Checkbox name="risk_Of_Blood_Loss" checked={formdata.sign_in.risk_Of_Blood_Loss === "yes"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, risk_Of_Blood_Loss: "yes" } })} size="small" />}
                                        label={<Typography variant="body2">Yes, and Adequate Intravenous Access and Fluids Planned</Typography>}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox name="risk_Of_Blood_Loss" checked={formdata.sign_in.risk_Of_Blood_Loss === "no"} onChange={() => setFormdata({ ...formdata, sign_in: { ...formdata.sign_in, risk_Of_Blood_Loss: "no" } })} size="small" />}
                                        label={<Typography variant="body2">No</Typography>}
                                    />
                                </FormGroup>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
                {/* <List>
                            <ListItem>Plan Name: {formdata.anesthesia_Plan}</ListItem>
                            <ListItem>Plan Date: {formdata.anesthesiaPlanDate}</ListItem>
                            <ListItem>Plan Author: {formdata.anesthesiaPlanAuthor}</ListItem>
                        </List> */}
            </Box >
        </>
    )
}

export default OtRoomAnaesthesiaMonitoring
