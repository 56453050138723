import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Typography,
  Grid,
  Divider,
  Dialog,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { log } from "node:console";
import DischargeModal from "./DischargeModal";

// Helper function to format dates and times
const formatDate = (date: dayjs.Dayjs | null) =>
  date ? date.format("YYYY-MM-DD") : "";
const formatTime = (time: dayjs.Dayjs | null) =>
  time ? time.format("HH:mm:ss") : "";

interface FormData {
  mrn: number;
  date: dayjs.Dayjs;
  patientName: string;
  dateOfAdmission: dayjs.Dayjs;
  guardiansName: string;
  currentDate: dayjs.Dayjs;
  gender: string;
  dateOfDeath: dayjs.Dayjs;
  timeOfDeath: dayjs.Dayjs;
  diagnosis: string;
  causeOfDeath: string;
  deadBodyReceivedBy: string;
  doctorName: string;
  signature: string;
  dateOfDoc: dayjs.Dayjs;
  timeOfDoc: dayjs.Dayjs;
}

const DeathForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    mrn: 0,
    date: dayjs(),
    patientName: "",
    dateOfAdmission: dayjs(),
    guardiansName: "",
    // motherName: "",
    // spouseName: "",
    currentDate: dayjs(),
    gender: "",
    dateOfDeath: dayjs(),
    timeOfDeath: dayjs(),
    diagnosis: "",
    causeOfDeath: "",
    deadBodyReceivedBy: "",
    doctorName: "",
    signature: "",
    dateOfDoc: dayjs(),
    timeOfDoc: dayjs(),
  });
  const [open, setOpen] = useState<boolean>(false);
  const [dataById, setDataById] = useState<any>();
  const [deathStatus, setDeathStatus] = useState<boolean>(false);
  const location = useLocation();
  const { data, id } = location.state;
  // const id = (location.state as { id?: any })?.id;
  const isFromOPD = (location.state as { isFromOPD?: any })?.isFromOPD;
  const navigate = useNavigate();
  const handleCloseDischarge = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!id) {
      setFormData((prevData: any) => ({
        ...prevData,
        mrn: data?.mrn,
        date: dayjs(data?.date),
        patientName: data?.patientName,
        dateOfAdmission: dayjs(data?.dateOfAdmission),
        guardiansName: data?.guardiansName,
        currentDate: dayjs(),
        gender: data?.patientData?.gender,
        dateOfDeath: dayjs(data?.dateOfDeath),
        timeOfDeath: dayjs(data?.timeOfDeath),
        diagnosis: data?.diagnosis,
        causeOfDeath: data?.causeOfDeath,
        deadBodyReceivedBy: data?.deadBodyReceivedBy,
        doctorName: data?.doctorName,
        signature: data?.signature,
        dateOfDoc: dayjs(data?.dateOfDoc),
        timeOfDoc: dayjs(data?.timeOfDoc),
      }));
    }
  }, [data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const date = dayjs(value, "YYYY-MM-DD", true);
      if (date.isValid()) {
        setFormData({
          ...formData,
          [name]: date,
        });
      }
    };

  const handleTimeChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const time = dayjs(value, "HH:mm:ss", true);
      if (time.isValid()) {
        setFormData({
          ...formData,
          [name]: time,
        });
      }
    };

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      gender: event.target.value,
    });
  };

  const handleArrowClick = async () => {
    try {
      const url = isFromOPD
        ? `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateIpdAdmissionStatusById/${data?._id}`
        : `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateEmergencyStatusById/${data?._id}`;
      // Replace with your API endpoint
      const requestBody = isFromOPD
        ? { patientStatus: "Death" }
        : { emergencyStatus: "Death" };

      await axios.patch(url, requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  useEffect(() => {
    getDataBySearchId();
  }, []);

  const getDataBySearchId = async () => {
    try {
      const formName = "DeathForm";
      const response = await axios.get(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${data?._id}?formName=${formName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDataById(response?.data?.data);
      const fetchedData = response.data?.data?.formData?.formattedData;

      if (fetchedData) {
        const parseTime = (timeStr: string) => dayjs(timeStr, "HH:mm:ss", true);
        setFormData((prevData: any) => ({
          ...prevData,
          mrn: fetchedData?.mrn,
          date: dayjs(fetchedData?.date),
          patientName: fetchedData?.patientName,
          dateOfAdmission: dayjs(fetchedData?.dateOfAdmission),
          guardiansName: fetchedData?.guardiansName,
          currentDate: dayjs(),
          gender: fetchedData?.gender,
          dateOfDeath: dayjs(fetchedData?.dateOfDeath),
          timeOfDeath: parseTime(fetchedData?.timeOfDeath),
          diagnosis: fetchedData?.diagnosis,
          causeOfDeath: fetchedData?.causeOfDeath,
          deadBodyReceivedBy: fetchedData?.deadBodyReceivedBy,
          doctorName: fetchedData?.doctorName,
          signature: fetchedData?.signature,
          dateOfDoc: dayjs(fetchedData?.dateOfDoc),
          timeOfDoc: parseTime(fetchedData?.timeOfDoc),
        }));
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async () => {
    if (
      !formData.mrn ||
      !formData.patientName ||
      !formData.dateOfDeath ||
      !formData.timeOfDeath
    ) {
      toast.error("Please fill all required fields.");
      return;
    }

    const formattedData = {
      ...formData,
      date: formatDate(formData.date),
      dateOfAdmission: formatDate(formData.dateOfAdmission),
      dateOfDeath: formatDate(formData.dateOfDeath),
      timeOfDeath: formatTime(formData.timeOfDeath),
      dateOfDoc: formatDate(formData.dateOfDoc),
      timeOfDoc: formatTime(formData.timeOfDoc),
      currentDate: formatTime(formData.currentDate),
    };

    if (id && data) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/updateFormData/${id}`,
          {
            formName: "DeathForm",
            formData: { formattedData },
            searchId: dataById?.searchId,
            patientId: dataById?.patientId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setDeathStatus(true);
        setOpen(true);
        handleArrowClick();
        toast.success(response.data.success);
      } catch (error: any) {
        toast.error(error.message);
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/CreateFormData`,
          {
            formName: "DeathForm",
            formData: { formattedData },
            searchId: data?._id,
            patientId: data?.patientData?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setDeathStatus(true);
        setOpen(true);
        handleArrowClick();
        toast.success(response.data.success);
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          p: 0.2,
          background: "#fff",
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ fontWeight: "600", px: 2 }} variant="h6">
          Death Form
        </Typography>
      </Box>
      <Box p={2} mt={1} sx={{ background: "#fff", borderRadius: "8px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              MRN
            </Typography>
            <TextField
              placeholder="MRN"
              name="mrn"
              value={formData?.mrn}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
              size="small"
              className="search-field"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Date
            </Typography>
            <TextField
              placeholder="Date"
              name="currentDate"
              type="date"
              value={formatDate(formData?.currentDate)}
              onChange={handleDateChange("currentDate")}
              variant="outlined"
              className="search-field"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Patient Name
            </Typography>

            <TextField
              placeholder="Patient Name"
              name="patientName"
              value={formData?.patientName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
              size="small"
              className="search-field"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Date of Admission
            </Typography>
            <TextField
              placeholder="Date of Admission"
              name="dateOfAdmission"
              type="date"
              value={formatDate(formData?.dateOfAdmission)}
              onChange={handleDateChange("dateOfAdmission")}
              variant="outlined"
              className="search-field"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              S/O, D/O, W/O
            </Typography>

            <TextField
              placeholder="Father's Name"
              name="guardiansName"
              value={formData?.guardiansName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Gender
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                name="gender"
                value={formData?.gender}
                onChange={handleGenderChange}
                row
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio size="small" />}
                  label="Male"
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio size="small" />}
                  label="Female"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio size="small" />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Date of Death
            </Typography>
            <TextField
              placeholder="Date of Death"
              name="dateOfDeath"
              type="date"
              value={formatDate(formData?.dateOfDeath)}
              onChange={handleDateChange("dateOfDeath")}
              variant="outlined"
              className="search-field"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Time of Death
            </Typography>
            <TextField
              placeholder="Time of Death"
              name="timeOfDeath"
              type="time"
              value={formatTime(formData?.timeOfDeath)}
              onChange={handleTimeChange("timeOfDeath")}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Diagnosis
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                {Array.isArray(formData?.diagnosis) && formData?.diagnosis?.length > 0 ? (
                  <>
                    {formData?.diagnosis.slice(0, 2)}
                    {formData?.diagnosis.length > 2 && (
                      <Tooltip title={formData?.diagnosis.slice(2)} arrow placement="top">
                        <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                          +{formData?.diagnosis.length - 2} more
                        </span>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <span>No diagnoses available</span> // Handle case where no diagnoses exist
                )}
              </Typography>

            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Cause of Death
            </Typography>
            <TextField
              placeholder="Cause of Death"
              name="causeOfDeath"
              value={formData?.causeOfDeath}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              className="search-field"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Dead Body Received By
            </Typography>
            <TextField
              placeholder="Dead Body Received By"
              name="deadBodyReceivedBy"
              value={formData?.deadBodyReceivedBy}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: 2 }} />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Doctor Name
            </Typography>
            <TextField
              placeholder="Doctor Name"
              name="doctorName"
              value={formData?.doctorName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>

          <Grid item xs={12} sm={3} md={2}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Date of Death
            </Typography>
            <TextField
              placeholder="Date of Death"
              name="dateOfDoc"
              type="date"
              value={formatDate(formData?.dateOfDoc)}
              onChange={handleDateChange("dateOfDoc")}
              variant="outlined"
              className="search-field"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={2}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Time of Death
            </Typography>
            <TextField
              placeholder="Time of Death"
              name="timeOfDoc"
              type="time"
              value={formatTime(formData?.timeOfDoc)}
              onChange={handleTimeChange("timeOfDoc")}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "500", color: "#000" }}
            >
              Signature
            </Typography>
            <TextField
              placeholder="Signature"
              name="signature"
              value={formData?.signature}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              size="small"
              className="search-field"
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
          <Button onClick={() => navigate("/ipd-dashboard")}>Back</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>

        <Dialog
          open={open}
          onClose={handleCloseDischarge}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
          PaperProps={{
            sx: {
              borderRadius: "12px",
            },
          }}
        >
          <DischargeModal
            dischargeData={data}
            handleClose={handleCloseDischarge}
            deathStatus={deathStatus}
            isRefDeathComp={true}
          // isEmergency={isEmergency}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

export default DeathForm;
