
import { Box, Chip, Divider, Grid, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomAutocomplete from '../../../autocomplete-field'; // Assuming this is your custom component
import { fetchSigns, selectSigns } from '../../../../redux/reducers/OPD/Admin/signsSlice'; // Assuming this is the correct import for your fetchSigns action
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface Sign {
    _id: string;
    signName: string;
}

export const Examination: React.FC<any> = ({ onChange, data, profile, opdData }) => {
    const [selectedExamination, setSelectedExamination] = useState<Sign[]>([]);
    const [selectedSigns, setSelectedSigns] = useState<{ [key: string]: string }>({});
    const [inputValueSigns, setInputValueSigns] = useState("");
    const [dateTime, setDateTime] = useState<dayjs.Dayjs | null>(dayjs());
    const dispatch = useDispatch();
    const [examination, setExamination] = useState<any[]>([])
    const allSigns = useSelector(selectSigns) || [];

    useEffect(() => {
        setExamination(opdData?.signs)
    }, [opdData])
    useEffect(() => {
        dispatch(fetchSigns(inputValueSigns) as any);
    }, [dispatch, inputValueSigns]);

    useEffect(() => {
        if (data?.examinations) {
            setSelectedExamination(data?.examinations || []);
        }
        if (data?.examinations_Without_GPE) {
            setSelectedSigns(data?.examinations_Without_GPE || []);
        }
        if (data?.dateTime) {
            setDateTime(dayjs(data?.dateTime));
        }
    }, [data]);

    const signs = allSigns?.filter((sign: Sign) =>
        sign?.signName?.toLowerCase().startsWith("gpe")
    );
    const signsWithoutGPE = allSigns?.filter((sign: Sign) =>
        !sign?.signName?.toLowerCase().startsWith("gpe")
    );

    useEffect(() => {
        dispatch(fetchSigns(inputValueSigns) as any);
    }, [dispatch, inputValueSigns]);

    const handleSignsTextChange = (signName: string, newDetails: string) => {
        setSelectedSigns((prevSigns: any) => ({
            ...prevSigns,
            [signName]: newDetails,  // Update the details for the specific symptom
        }));
    };

    const deleteTheSigns = async (signName: string) => {
        setSelectedSigns((prevSigns: any) => {
            const updatedSigns = { ...prevSigns };
            delete updatedSigns[signName];  // Delete symptom by name
            return updatedSigns;
        });
    };

    const handleSelectChangeSign = (event: React.ChangeEvent<{}>, newValue: Sign[]) => {
        const existingSignNames = new Set(selectedExamination?.map((sign: any) => sign?.signName) || []);
        const updatedSigns = newValue?.reduce((acc: any[], sign: any) => {
            if (sign?.signName && !existingSignNames?.has(sign?.signName)) {
                acc.push(sign?.signName);
            }
            return acc;
        }, []);
        const finalExamSigns = [...selectedExamination, ...updatedSigns];
        setSelectedExamination(finalExamSigns);
    };

    const handleSelectChangeSignWithOutGPE = (event: any, newValue: any) => {
        const existingSignNames = new Set(Object.keys(selectedSigns || {}));
        const updatedSigns = newValue?.reduce((acc: any, sign: any) => {
            if (sign?.signName && !existingSignNames?.has(sign?.signName)) {
                acc[sign?.signName] = '';
            }
            return acc;
        }, {});
        const finalSigns = { ...selectedSigns, ...updatedSigns };
        setSelectedSigns(finalSigns);
    };

    useEffect(() => {
        onChange({
            examinations: selectedExamination,
            examinations_Without_GPE: selectedSigns,
            dateTime: dateTime?.format('DD.MM.YYYY hh:mm A'),
        });
    }, [selectedExamination, selectedSigns, dateTime]);

    const handleDeleteChip = (signToDelete: any) => {
        const updatedExamination = selectedExamination?.filter(
            (sign: any) => sign !== signToDelete
        );
        setSelectedExamination(updatedExamination);
    };

    const handleInputChangeSigns = (event: any, newInputValue: string) => {
        setInputValueSigns(newInputValue);
    };

    const handleBlurSigns = () => {
        setInputValueSigns('');
    };
    const handleCreateNewSigns = async (inputValue: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_OPD_API_URL}/signs/createSign?count=50000`,
                { signName: inputValue },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const newGeneric = response?.data?.data;
            if (newGeneric) {
                dispatch(fetchSigns(inputValueSigns) as any);
                toast.success(response?.data?.message);

            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    const handleCreateNewExamination = async (inputValue: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_OPD_API_URL}/signs/createSign?count=50000`,
                { signName: inputValue },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const newGeneric = response?.data?.data;
            if (newGeneric) {
                setSelectedExamination((prevSelected: any) => [...prevSelected, newGeneric]);
                dispatch(fetchSigns(inputValueSigns) as any);
                toast.success(response?.data?.message);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    const handleAddButtonClickSigns = () => {
        if (inputValueSigns?.trim() !== "") {
            handleCreateNewSigns(inputValueSigns);
            setInputValueSigns("");
        }
    };
    const handleAddButtonClickExamination = () => {
        if (inputValueSigns?.trim() !== "") {
            handleCreateNewExamination(inputValueSigns);
            setInputValueSigns("");
        }
    };
    const handleKeyPressSigns = (event: {
        key: string;
        preventDefault: () => void;
    }) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddButtonClickSigns();
        }
    };
    const handleKeyPressExamination = (event: {
        key: string;
        preventDefault: () => void;
    }) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddButtonClickExamination();
        }
    };

    return (
        <>
            <Box sx={{
                height: { md: '50vh', sm: '100%', xs: '100%' }, overflowY: 'auto'
            }}>
                <Grid container spacing={3}>
                    <Grid item md={3} sm={4} xs={6}>
                        <Box>
                            <CustomAutocomplete
                                multiple
                                options={signs}
                                getOptionLabel={(option) => option?.signName || ""}
                                filterOptions={(options, { inputValue }) =>
                                    options.filter((option: Sign) =>
                                        option?.signName?.toLowerCase().includes(inputValue.toLowerCase())
                                    )
                                }
                                onChange={handleSelectChangeSign}
                                // value={selectedExamination}
                                placeholder="Select G.P. Examination"
                                onTextFieldChange={(event) => handleInputChangeSigns(event, event.target.value)}
                                handleBlur={handleBlurSigns}
                                handleAddClick={handleAddButtonClickExamination}
                                onKeyPress={handleKeyPressExamination}
                            />
                        </Box>
                        <Box mt={0.3}>
                            {selectedExamination?.map((sign: any) => (
                                <Chip
                                    key={sign}
                                    label={sign}
                                    onDelete={() => handleDeleteChip(sign)}
                                    sx={{
                                        mr: .4,
                                        background: "#CFCFCF",
                                        borderRadius: "8px",
                                        fontSize: "12px",
                                        height: "24px",
                                        "&:hover": {
                                            background: "#055FFC",
                                            color: "#fff",
                                            "& .MuiChip-deleteIcon": {
                                                color: "#fff",
                                            },
                                            "& .MuiChip-icon": {
                                                color: "#fff",
                                            },
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Grid>
                    <Grid item md={5} sm={4} xs={6}>
                        <CustomAutocomplete
                            options={signsWithoutGPE}
                            getOptionLabel={(option) => option?.signName || ""}
                            filterOptions={(options, { inputValue }) =>
                                options.filter((option: any) =>
                                    option?.signName?.toLowerCase().includes(inputValue.toLowerCase())
                                )
                            }
                            onChange={handleSelectChangeSignWithOutGPE}
                            handleAddClick={handleAddButtonClickSigns}
                            onTextFieldChange={(event) => handleInputChangeSigns(event, event.target.value)}
                            onKeyPress={handleKeyPressSigns}
                            // value={selectedSigns}
                            placeholder="Select Systematic Examination"
                            multiple
                        />
                        {Object.keys(selectedSigns)?.length > 0 && (
                            <Box
                                sx={{
                                    height: '10rem',
                                    background: '#E6EFFF',
                                    border: '2px dotted #055FFC',
                                    mt: 1,
                                    padding: 0.5,
                                    borderRadius: '12px',
                                    overflowY: 'auto',
                                    "&::-webkit-scrollbar": {
                                        width: "3px",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        background: "transparent",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        background: "#888",
                                        borderRadius: "5px",
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                        background: "#555",
                                    },
                                }}
                            >
                                {Object.keys(selectedSigns)
                                    ?.sort((a: any, b: any) => {
                                        const aName = a?.exam || '';
                                        const bName = b?.exam || '';
                                        if (aName?.startsWith('CNS') && !bName.startsWith('CNS')) return -1;
                                        if (bName?.startsWith('CNS') && !aName.startsWith('CNS')) return 1;
                                        if (aName?.startsWith('CVS') && !bName.startsWith('CVS')) return -1;
                                        if (bName?.startsWith('CVS') && !aName.startsWith('CVS')) return 1;
                                        return aName?.localeCompare(bName);
                                    })
                                    ?.map((signName: any, index: any) => (
                                        <Box display="flex" alignItems="center" key={signName} justifyContent="space-between" mt={0.5}>
                                            <Tooltip title={signName} arrow placement="top">
                                                <Typography sx={{ color: '#000', width: '40%', fontSize: '11px' }}>
                                                    {signName?.length > 16
                                                        ? `${signName?.substring(0, 16)}...`
                                                        : signName}
                                                </Typography>
                                            </Tooltip>
                                            <TextField
                                                sx={{
                                                    width: "90%",
                                                    backgroundColor: '#F5F5FB',
                                                    borderRadius: '12px',
                                                    '& .MuiInputBase-root': {
                                                        height: '24px',
                                                        borderRadius: '12px',
                                                        padding: 0,
                                                        backgroundColor: 'transparent',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                }}
                                                value={selectedSigns[signName]}  // Get the details for the symptom
                                                onChange={(e) => handleSignsTextChange(signName, e.target.value)}  // Update the details
                                                size="small"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                    cursor: 'pointer',
                                                                    height: '20px',
                                                                    lineHeight: '24px',
                                                                }}
                                                                onClick={() => deleteTheSigns(signName)}  // Delete symptom by name
                                                            >
                                                                X
                                                            </span>
                                                        </InputAdornment>
                                                    ),
                                                    style: {
                                                        fontSize: "12px",
                                                        padding: '0 8px',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    ))}
                            </Box>
                        )}
                    </Grid>
                    <Grid item md={4} sm={4} xs={6}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                                <span style={{
                                    fontSize: '12px',
                                    color: '#fff',
                                    fontWeight: '500',
                                    backgroundColor: '#077DD3',
                                    padding: '2px 8px',
                                    borderRadius: '4px',
                                    display: 'inline-block',
                                }}>
                                    Previous examination:</span>
                                {examination?.slice(0, 2)?.join(', ')}
                                {examination?.length > 2 && (
                                    <Tooltip title={examination?.slice(2)?.join(', ')} arrow placement='top'>
                                        <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                                            +{examination?.length - 2} more
                                        </span>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
            <Box >
                <Grid container spacing={1}>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Name </Typography>
                        <TextField value={profile?.data?.fullName} disabled fullWidth className='search-field' size='small' />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Time </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={dateTime}
                                minDate={dayjs().startOf("day")}
                                disabled
                                sx={{
                                    "& fieldset": { border: "none" },
                                    "& .MuiInputBase-root": { height: "38px", fontSize: "12px", overflow: "hidden" },
                                    "& .MuiOutlinedInput-root": { borderRadius: "8px", border: "none", backgroundColor: "#F5F5FB" },
                                    width: "100%",
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Box></>
    );
};
