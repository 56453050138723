import { Box, Grid, TextField, Radio, RadioGroup, FormControlLabel, FormControl, Select, MenuItem, Checkbox, ListItemText, Typography, Autocomplete, Button, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MedicineDoses, { MedicineDose } from '../../../../OPD/add-patient-record/MedicineDoses';
import axios from 'axios';
import dayjs from 'dayjs';

interface FormData {
    guidance: string;
    medicines: MedicineDose[];
}
interface PostTemp {
    handleClose: () => void;
    templateData?: any
    fetchTemplates?: (optype: any) => void
}

const PostTemplate: React.FC<PostTemp> = ({ handleClose, templateData, fetchTemplates }) => {
    const currentDate = dayjs();
    const [isMultiline, setIsMultiline] = useState(false);
    const [medicineData, setMedicineData] = useState<MedicineDose[]>([]);
    const [formData, setFormData] = useState<FormData>({
        guidance: '',
        medicines: [],
    });
    const [templateName, setTemplateName] = useState<string>('')
    const [freq, setFreq] = useState<any[]>([]);
    const [dateTime, setDateTime] = useState<string>(currentDate.format('DD-MM-YYYY HH:mm:ss'));

    const handleBlur = () => setIsMultiline(false);
    const handleFocus = () => setIsMultiline(true);

    const handleMedicineDoses = (medicineDoses: MedicineDose[], deletedInfo?: { medicine: MedicineDose; deletedTime: string; deletedBy: string }) => {
        const updatedFormData: any = {
            ...formData,
            medicines: medicineDoses,
        };
        setFormData(updatedFormData);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'templateName') {
            setTemplateName(value);
        }
    };

    const fetchSchedules = () => {
        axios.get(`${process.env.REACT_APP_OPD_API_URL}/dosage-schedules/getAllByPagination?pageNo=1&count=50000`, {
            headers: {
                accept: "*/*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(response => {
                setFreq(response?.data?.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchSchedules();
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            let res;
            if (templateData) {
                // If templateData exists, update the template
                res = await axios.patch(
                    `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/updateTemplateById/${templateData._id}`,
                    {
                        ope_type: 'post',
                        templateName,
                        templateData: formData,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    }
                );
                handleClose();
                if (fetchTemplates) {
                    fetchTemplates("post")
                }
            } else {
                // If no templateData, create a new template
                res = await axios.post(
                    `${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/addNewTemplate`,
                    {
                        ope_type: 'post',
                        templateName,
                        templateData: formData,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    }
                );
            }
            // Close the dialog or handle success response
            handleClose();
            if (fetchTemplates) {
                fetchTemplates("post")
            }

        } catch (error) {
            console.error(error);
        }
    };
    console.log("formData", formData)
    useEffect(() => {
        if (templateData) {
            setTemplateName(templateData?.templateName);
            setFormData({
                guidance: templateData?.templateData?.guidance || "",
                medicines: templateData?.templateData?.medicines || []
            });
            setMedicineData(templateData?.templateData?.medicines || [])
        }
    }, [templateData]);
    return (
        <Box>
            <Typography variant="h6">New Template</Typography>
            <Box sx={{
                borderRadius: '8px',
                minHeight: '50vh',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                },
            }}>
                <Typography variant="h6">New Post Operation Template</Typography>
                <Grid container spacing={1}>
                    <Grid item md={6} sm={4} xs={12}>
                        <TextField
                            size="small"
                            placeholder="Template Name"
                            variant="outlined"
                            fullWidth
                            name="templateName"
                            value={templateName}
                            className='search-field'
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item md={8} sm={8} xs={12} spacing={1}>
                        <TextField
                            size="small"
                            placeholder="Treatment Guidance"
                            variant="outlined"
                            fullWidth
                            name="guidance"
                            value={formData?.guidance}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    background: "#F5F5FB",
                                    fontSize: "12px",
                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "&:hover fieldset": {
                                        border: "none",
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: "none",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    top: "-2px",
                                },
                            }}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            multiline={isMultiline}
                            minRows={isMultiline ? 3 : 1}
                        />
                    </Grid>

                    <Grid item md={12} sx={{ mt: 1, mb: 1 }}>
                        <MedicineDoses
                            onMedicineDoses={handleMedicineDoses}
                            medicineDataIfExist={medicineData}
                            setMedicineData={setMedicineData}
                            medicineData={medicineData}
                        />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='flex-end' sx={{ mt: 3 }}>
                    <Button size='small' variant='contained' onClick={handleSubmit}>
                        {templateData ? "update" : "Save"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PostTemplate;

