import dayjs from 'dayjs';

/**
 * Checks if the given date and time is more than 30 minutes ago.
 * 
 * @param dateTime - The date and time to compare.
 * @returns `true` if the date is more than 30 minutes ago, otherwise `false`.
 */
export const isDateTimeGreaterThan30MinutesAgo = (dateTime: string): boolean => {
    const currentDateTime = dayjs();
    const inputDateTime = dayjs(dateTime, 'DD.MM.YYYY hh:mm A');

    return currentDateTime.diff(inputDateTime, 'minute') < 30;
};
