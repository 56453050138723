import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const MedicineScheduleForm: React.FC<any> = ({onClose}) => {
    const [formData, setFormData] = useState({
        abr_notation: '',
        eng_notation: '',
        urdu_notation: '',
        math_notation: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_OPD_API_URL}/dosage-schedules/addDosageSchedule`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            if (response.data.isSuccess === false) {
                toast.error(response.data.message);
            } else {
                toast.success(response.data.message);
                setFormData({
                    abr_notation: '',
                    eng_notation: '',
                    urdu_notation: '',
                    math_notation: ''
                });
                onClose()
            }
        } catch (error: any) {
            toast.error(error.message);
        }

    };


    return (
        <Box>
            <Box sx={{ background: '#fff', p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '14px', color: '#000' }}>Add Medicine Frequency</Typography>
                <CancelOutlinedIcon onClick={onClose} sx={{cursor:'pointer'}} />
            </Box>

            <Box mt={2} p={1}>
                <Grid container spacing={2}>



                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>Enter Abr Notation</Typography>
                        <TextField size="small"
                            placeholder='Enter Abr Notation'
                            name='abr_notation'
                            value={formData.abr_notation}
                            fullWidth
                            required
                            onChange={handleChange}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    border: '1px solid #D8D8D8',
                                    background: '#F5F5FB',
                                    fontSize: '12px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>Enter Eng Notation</Typography>
                        <TextField size="small"
                            placeholder='Enter Eng Notation'
                            name='eng_notation'
                            value={formData.eng_notation}
                            fullWidth
                            required
                            onChange={handleChange}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    border: '1px solid #D8D8D8',
                                    background: '#F5F5FB',
                                    fontSize: '12px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>Enter Urdu Notation</Typography>
                        <TextField size="small"
                            placeholder='Enter Urdu Notation'
                            name='urdu_notation'
                            value={formData.urdu_notation}
                            fullWidth
                            required
                            onChange={handleChange}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    border: '1px solid #D8D8D8',
                                    background: '#F5F5FB',
                                    fontSize: '12px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>Enter Math Notation</Typography>
                        <TextField size="small"
                            placeholder='Enter Math Notation'
                            name='math_notation'
                            value={formData.math_notation}
                            fullWidth
                            required
                            onChange={handleChange}
                            InputProps={{
                                style: {
                                    borderRadius: "12px",
                                    border: '1px solid #D8D8D8',
                                    background: '#F5F5FB',
                                    fontSize: '12px'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='flex-end' mt={2} >
                    <Button variant='contained' onClick={handleSubmit}>Save</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default MedicineScheduleForm;
