import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    MenuItem,
    Divider,
    Autocomplete,
    Checkbox,
    Button,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import MedicineDoses, { MedicineDose } from "../../../../OPD/add-patient-record/MedicineDoses";
import axios from "axios";
import debounce from "lodash.debounce";
import CustomAutocomplete from "../../../../autocomplete-field";
import PreTemplateModal from "./PreTemplateModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import RulesRegulationModal from "./Rules&Regulation";

interface PreOperationProps {
    onChange: (data: any) => void;
    data: FormData;
    otherTabsData: any;
    profile: any;
}

interface FormData {
    inclusion: string;
    procedure_Notes: string;
    operative_Findings: string;
    drain: boolean;
    specimen: boolean;
    condition_At_End_Of_Surgery: string;
    handed_Over_By: string;  // New field
    handed_Over_To: string;  // New field
    lab_Name: string;       // New field
    lab_No: string;         // New field
    reporting: string;     // New field
    time_Out: Object; // New field
}
const PreOperation: React.FC<PreOperationProps> = ({ onChange, data, otherTabsData, profile }) => {
    const [opnTemp, setOpnTemp] = useState<boolean>(false);
    const [temp, setTemp] = useState<any[]>([])
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({
        inclusion: '',
        procedure_Notes: '',
        operative_Findings: '',
        drain: false,
        specimen: false,
        condition_At_End_Of_Surgery: '',
        handed_Over_By: '',   // Initialize new field
        handed_Over_To: '',   // Initialize new field
        lab_Name: '',        // Initialize new field
        lab_No: '',          // Initialize new field
        reporting: '',      // Initialize new field
        time_Out: {
            team_Members_Introduced: true,
            critical_Events_Reviewed: true,
            patient_Specific_Concerns_Reviewed: true,
            sterility_Confirmed: true,
            antibiotic_Given: 'yes',
            imaging_Displayed: 'yes'
        },
    });
    const [isMultiline, setIsMultiline] = useState(false);
    const handleBlur = () => setIsMultiline(false);
    const handleFocus = () => setIsMultiline(true);
    const [isMultilinetwo, setIsMultilinetwo] = useState(false);
    const handleBlurtwo = () => setIsMultilinetwo(false);
    const handleFocustwo = () => setIsMultilinetwo(true);

    const handleOpenClose = () => {
        setIsOpen(!isOpen)
    }

    const handleTimeOutUpdate = (updatedTimeOut: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            time_Out: updatedTimeOut,
        }));
    };

    // useEffect(() => {
    //     onChange({ time_Out: formData?.time_Out, reporting_date: formData?.reporting })
    // }, [formData])
    useEffect(() => {
        // Iterate over formData fields and send only changed values to onChange
            // Send the updated key-value to onChange dynamically
            onChange(formData);
        
    }, [formData]);  // Ensure onChange and formData are included in the dependency array    

    // useEffect(() => {
    //     if (data) {
    //         setFormData(data);
    //     }
    // }, [data]);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData : any) => ({
            ...prevData,
            [name as keyof FormData]: value,
        }));

        // onChange({ ...formData, [name as keyof FormData]: value });
    };
    const handleCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, checked } = e.target;
        setFormData((prevData : any) => ({
            ...prevData,
            [name as keyof FormData]: checked, // Store the boolean value of the checkbox (true or false)
        }));

        // onChange({ ...formData, [name as keyof FormData]: checked }); // Update parent state
    };

    useEffect(() => {
        if (data) {
            setFormData(data);
        }
        const timeoutId = setTimeout(() => {
            if (data?.time_Out) {
                handleTimeOutUpdate(data?.time_Out);
            }
        }, 500); // 2000 milliseconds = 2 seconds
        return () => clearTimeout(timeoutId);
    }, [data]);

    const getOpTemplate = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-operation-template/getAllTemplates?ope_type=per`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            setTemp(response.data?.data || [])
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        getOpTemplate()
    }, [])
    const handleSelectChangeTemplate = (e: any, value?: string) => {
        const selectedTemplate = e.target.value;
        setFormData((prevData : any) => ({
            ...prevData,
            operative_Findings: selectedTemplate[0]?.templateData?.operativeFindings,
            procedure_Notes: selectedTemplate[0]?.templateData?.procedureNotes,
            inclusion: selectedTemplate[0]?.templateData?.inclusion
        }));
        // onChange({ ...formData, selectedTemplate });
    };
    const handleAddButtonClickTemplate = () => {
        setOpnTemp(true)
    }
    const handleCloseDialog = () => {
        setOpnTemp(false);
        getOpTemplate()
    };
    const handleDateChange = (newValue: any) => {
        const updatedDate = newValue ? newValue.toISOString() : ''; // Convert to ISO string
        setFormData((prevData : any) => ({
            ...prevData,
            reporting: updatedDate, // Update reporting date in formData
        }));
        // onChange({ ...formData, reporting: updatedDate });

    };
    return (
        <Box >
            <Grid container>
                <Grid item md={4} sm={4} xs={8}>
                    <CustomAutocomplete
                        multiple
                        options={temp || []}

                        onChange={(event, newValue) => {
                            handleSelectChangeTemplate({
                                target: {
                                    value: newValue.map((diag: any) => diag),
                                },
                            });
                        }}
                        getOptionLabel={(option) => option?.templateName

                        }
                        filterOptions={(options, { inputValue }) =>
                            options.filter((option: any) =>
                                option?.templateName
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase(),)
                            )
                        }
                        placeholder="Template"
                        handleAddClick={handleAddButtonClickTemplate}
                    />
                </Grid>
                <Grid item md={8} sm={8} xs={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button size='small' variant='contained' sx={{
                            background: formData?.time_Out && Object?.values(formData?.time_Out)?.some(value => value === true) ? '#009E65' : '#FFBF00',
                            "&:hover": {
                                background: formData?.time_Out && Object?.values(formData?.time_Out)?.some(value => value === true) ? '#009E65' : '#FFBF00',
                            },
                        }} onClick={handleOpenClose}>
                            Time Out
                        </Button>
                    </Box>
                </Grid>

            </Grid>
            <Typography variant="h5">Per-Operation Template</Typography>
            <Grid container spacing={1}>
                <Grid item md={3} sm={3} xs={6}>
                    <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600" }}>
                        Incision :
                    </Typography>
                    <TextField
                        name="inclusion"
                        value={formData?.inclusion}
                        onChange={handleInputChange}
                        className="search-field"
                        size="small"
                        placeholder="Enter Incision"
                        fullWidth
                    />
                </Grid>

                <Grid item md={3} sm={2} xs={6}>
                    <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600" }}>
                        Drain :
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={formData?.drain || false} // Check if `drain` is true, default to false
                                onChange={handleCheckboxChange}
                                name="drain"
                            />
                        }
                        label="Yes"
                    />
                </Grid>

                <Grid item md={3} sm={4} xs={6}>
                    <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600" }}>
                        Condition At End Of Surgery :
                    </Typography>
                    <RadioGroup
                        name="condition_At_End_Of_Surgery"
                        row
                        value={formData?.condition_At_End_Of_Surgery}
                        onChange={handleInputChange}
                    >
                        <FormControlLabel value="Unstable" control={<Radio size="small" />} label="Unstable" />
                        <FormControlLabel value="Stable" control={<Radio size="small" />} label="Stable" />
                    </RadioGroup>
                </Grid>
                <Grid item md={3} sm={3} xs={6}>
                    <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600" }}>
                        Doctor Name :
                    </Typography>
                    <TextField
                        value={profile?.data?.fullName}
                        className="search-field"
                        size="small"
                        disabled
                        fullWidth
                    />
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                    <TextField
                        name="procedure_Notes"
                        value={formData?.procedure_Notes}
                        onChange={handleInputChange}
                        size="small"
                        fullWidth
                        InputProps={{
                            style: {
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "12px",
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                top: "-2px",
                            },
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        multiline={isMultiline}
                        minRows={isMultiline ? 3 : 1}
                        label="Enter Procedure Note"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <TextField
                        name="operative_Findings"
                        value={formData?.operative_Findings}
                        onChange={handleInputChange}
                        size="small"
                        fullWidth
                        InputProps={{
                            style: {
                                borderRadius: "12px",
                                background: "#F5F5FB",
                                fontSize: "12px",
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "&:hover fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "none",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                top: "-2px",
                            },
                        }}
                        onFocus={handleFocustwo}
                        onBlur={handleBlurtwo}
                        multiline={isMultilinetwo}
                        minRows={isMultilinetwo ? 3 : 1}
                        label="Operative Findings"
                    />
                </Grid>

            </Grid>
            <Grid container spacing={1}>
                <Grid item md={3} sm={4} xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={formData?.specimen || false}
                                onChange={handleCheckboxChange}
                                name="specimen"
                            />
                        }
                        label="Specimen Sent Lab"
                    />
                </Grid>
                {formData.specimen && (
                    <Grid container spacing={1}>
                        <Grid item md={3} sm={4} xs={6}>
                            <TextField
                                name="handed_Over_By"
                                value={formData?.handed_Over_By}
                                onChange={handleInputChange}
                                className="search-field"
                                size="small"
                                label="Handed Over By"
                                fullWidth
                            />
                        </Grid>

                        <Grid item md={3} sm={4} xs={6}>
                            <TextField
                                name="handed_Over_To"
                                value={formData?.handed_Over_To}
                                onChange={handleInputChange}
                                className="search-field"
                                size="small"
                                label="Handed Over To"
                                fullWidth
                            />
                        </Grid>

                        <Grid item md={3} sm={4} xs={6}>

                            <TextField
                                name="lab_Name"
                                value={formData?.lab_Name}
                                onChange={handleInputChange}
                                className="search-field"
                                size="small"
                                label="Lab Name"
                                fullWidth
                            />
                        </Grid>

                        <Grid item md={3} sm={4} xs={6}>

                            <TextField
                                name="lab_No"
                                value={formData?.lab_No}
                                onChange={handleInputChange}
                                className="search-field"
                                size="small"
                                label="Lab No"
                                fullWidth
                            />
                        </Grid>

                        <Grid item md={3} sm={4} xs={6}>
                            {/* <Typography sx={{ fontSize: "14px", color: "#3F3F3F", fontWeight: "600" }}>
                                Reporting Date:
                            </Typography> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label='Reporting Date '
                                    name="reporting"
                                    value={formData?.reporting ? dayjs(formData?.reporting) : null}  // Ensure dayjs object
                                    onChange={handleDateChange}  // Use the separate date change handler
                                    sx={{
                                        "& fieldset": {
                                            border: "none",
                                        },
                                        "& .MuiInputBase-root": {
                                            height: "38px",
                                            fontSize: "12px",
                                            overflow: "hidden",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            border: "none",
                                            backgroundColor: "#F5F5FB",
                                        },
                                        "& .MuiCalendarPicker-root": {
                                            overflow: "hidden",
                                        },
                                        "& .css-4jnixx-MuiStack-root": {
                                            paddingTop: "0px",
                                        },
                                        width: "100%",
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <PreTemplateModal handleCloseDialog={handleCloseDialog} opnTemp={opnTemp} />
            <RulesRegulationModal isOpen={isOpen} handleOpenClose={handleOpenClose} onTimeOutUpdate={handleTimeOutUpdate} initialFormState={formData?.time_Out} />

        </Box>
    );
};

export default PreOperation;







