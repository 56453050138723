



import { Box, Grid, IconButton, InputAdornment, TextField, Typography, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Dialog, DialogTitle, DialogContent, Button, DialogActions, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DynamicTable from '../../components/Dynamic-table';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Alert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';  // Import the CloseIcon for clearing the selection
import DeleteIcon from '@mui/icons-material/Delete';
interface HospitalData {
    hospitalName: string;
    patientRegistrations: number;
    appointments: number;
    users: number;
    prescriptions: number;
    ipdAdmission: number;
    _id: string
}

interface ApiResponse {
    data: HospitalData[];
    totalCount: number;
}

interface OverviewData {
    projects: number;
    appointments: number;
    patients: number;
    ipdAdmission: number;
}

const HospitalStatistics: React.FC = () => {
    const [data, setData] = useState<HospitalData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [overviewData, setOverviewData] = useState<OverviewData | null>(null);
    const [totalcount, setTotalCount] = useState<any>(0);
    const [selectedFilter, setSelectedFilter] = useState<string>('');  // State for filter selection
    const [searchTerm, setSearchTerm] = useState<string>('');
    const token = localStorage.getItem('token');
    const [openDialog, setOpenDialog] = useState<boolean>(false); // State for dialog
    const [selectedId, setSelectedId] = useState<string | null>(null); // Selected row _id

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchData = async () => {
        setLoading(true);
        const { date, dateTo } = calculateDateRange(selectedFilter);  // Get date range from the selected filter

        try {
            const response = await axios.get<ApiResponse>(
                `${process.env.REACT_APP_ADMIN_API_URL}/projects/getAllProjectsWithDetails`,
                {
                    params: {
                        count: rowsPerPage,
                        pageNo: page + 1,
                        sort: 'accending',
                        date,
                        dateTo,
                        search: searchTerm,
                    },
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response?.data) {
                setData(response.data.data);
                setTotalCount(response.data.totalCount);
            } else {
                console.error('Failed to fetch data:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const GridData = [
        {
            icon: <LocalHospitalOutlinedIcon sx={{ color: "rgba(249, 137, 45, 1)", fontSize: "30px" }} />,
            count: overviewData ? overviewData.projects : "00",
            title: "Total Hospitals",
            bgColor: "rgba(249, 137, 45, 0.1)"
        },
        {
            icon: <PersonOutlineOutlinedIcon sx={{ color: "rgba(0, 123, 255, 1)", fontSize: "30px" }} />,
            count: overviewData ? overviewData.patients : "00",
            title: "Total Patients",
            bgColor: "rgba(0, 123, 255, 0.1)"
        },
        {
            icon: <EventAvailableOutlinedIcon sx={{ color: "rgba(40, 167, 69, 1)", fontSize: "30px" }} />,
            count: overviewData ? overviewData.appointments : "00",
            title: "Total Appointments",
            bgColor: "rgba(40, 167, 69, 0.1)"
        },
        {
            icon: <ConfirmationNumberOutlinedIcon sx={{ color: "rgba(255, 193, 7, 1)", fontSize: "30px" }} />,
            count: overviewData ? overviewData.ipdAdmission : "00",
            title: "Total Admissions",
            bgColor: "rgba(255, 193, 7, 0.1)"
        }
    ];

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, selectedFilter]);

    const fetchOverviewData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ADMIN_API_URL}/projects/getStateOverview`,
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.data?.isSuccess) {
                setOverviewData(response.data.data);
            } else {
                console.error('Failed to fetch overview data:', response.data);
            }
        } catch (error) {
            console.error('Error fetching overview data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (event: SelectChangeEvent<string>) => {
        setSelectedFilter(event.target.value as string);
    };

    const calculateDateRange = (filter: string) => {
        const today = dayjs();
        let startDate: string, endDate: string;

        switch (filter) {
            case 'today':
                startDate = today.startOf('day').toISOString();
                endDate = today.endOf('day').toISOString();
                break;
            case 'lastThreeDays':
                startDate = today.subtract(2, 'days').startOf('day').toISOString();
                endDate = today.endOf('day').toISOString();
                break;
            case 'lastWeek':
                startDate = today.subtract(6, 'days').startOf('day').toISOString();  // Include today and previous 6 days (7 days total)
                endDate = today.endOf('day').toISOString();
                break;
            case 'fortnight':
                startDate = today.startOf('day').toISOString();  // Include today as the start of the 14-day range
                endDate = today.add(13, 'days').endOf('day').toISOString();  // 14-day range (today + next 13 days)
                break;
            case 'thisMonth':
                startDate = today.startOf('month').toISOString();
                endDate = today.endOf('month').toISOString();
                break;
            case 'lastMonth':
                startDate = today.subtract(1, 'month').startOf('month').toISOString();
                endDate = today.subtract(1, 'month').endOf('month').toISOString();
                break;
            case 'lastThreeMonths':
                startDate = today.subtract(3, 'months').startOf('day').toISOString();
                endDate = today.endOf('day').toISOString();
                break;
            case 'lastSixMonths':
                startDate = today.subtract(6, 'months').startOf('day').toISOString();
                endDate = today.endOf('day').toISOString();
                break;
            default:
                startDate = '';
                endDate = '';
        }
        return { date: startDate, dateTo: endDate };
    };
    const handleDelete = async (id: string) => {
        try {
            await axios.delete(`${process.env.REACT_APP_ADMIN_API_URL}/projects/deleteProject/${id}`, {
                headers: {
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`,
                }
            });
            // Refresh data after successful delete
            fetchData();
            setOpenDialog(false); // Close dialog
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };
    const openConfirmationDialog = (id: string) => {
        setSelectedId(id);
        setOpenDialog(true);
    };

    // Close the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Delete the project after confirmation
    const confirmDelete = () => {
        if (selectedId) {
            handleDelete(selectedId);
        }
    };


    useEffect(() => {
        fetchOverviewData();
    }, []);

    // Clear the selected filter when the cross icon is clicked
    const handleClearFilter = () => {
        setSelectedFilter('');
    };

    return (
        <Box>
            <Box sx={{ borderRadius: '10px', background: '#fff' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '24px', color: '#2A2A2A', px: 1.5, py: 1 }}>
                    Super Admin
                </Typography>
            </Box>

            <Grid container spacing={1}>
                {GridData.map((item, index) => (
                    <Grid item md={3} sm={6} xs={12} key={index}>
                        <Box sx={{ borderRadius: '10px', background: '#fff', mt: 1, height: "95px", display: "flex", alignItems: "center", px: 2 }}>
                            <Box sx={{
                                backgroundColor: item.bgColor, height: "55px", width: "55px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center"
                            }}>
                                {item.icon}
                            </Box>
                            <Box sx={{ pl: 1.2 }}>
                                <Typography variant='h6' sx={{ fontSize: "22px", fontWeight: "600" }}>
                                    {item.count}
                                </Typography>
                                <Typography variant='h6' sx={{ fontSize: "14px", fontWeight: "500", mt: -1 }}>
                                    {item.title}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ borderRadius: '10px', background: '#fff', mt: 1, pt: 1.5 }}>
                <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", width: "100%", gap: 1 }}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                fetchData();
                            }
                        }}
                        fullWidth
                        sx={{ width: "20%" }}
                        className='search-field'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={fetchData}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <FormControl sx={{ width: "20%" }} size="small">
                        <InputLabel>Select Duration</InputLabel>
                        <Select
                            value={selectedFilter}
                            onChange={handleFilterChange}
                            label="Select Duration"
                            endAdornment={
                                selectedFilter && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClearFilter}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                            sx={{
                                borderRadius: "12px",
                                border: "0px solid #D8D8D8",
                                background: "#F5F5FB",
                                fontSize: "12px",
                                height: "38px",
                                "& fieldset": {
                                    border: "none",
                                },

                            }}
                        >
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="lastThreeDays">Last 3 Days</MenuItem>
                            <MenuItem value="lastWeek">Last Week</MenuItem>
                            <MenuItem value="fortnight">Fortnight</MenuItem>
                            <MenuItem value="thisMonth">This Month</MenuItem>
                            <MenuItem value="lastMonth">Last Month</MenuItem>
                            <MenuItem value="lastThreeMonths">Last 3 Months</MenuItem>
                            <MenuItem value="lastSixMonths">Last 6 Months</MenuItem>
                        </Select>
                    </FormControl>

                </Box>

                <Grid container>
                    <Grid item md={12} sm={12} xs={12} sx={{ px: 1.5, py: 1 }}>
                        <DynamicTable
                            headers={[
                                'Hospital Name',
                                'Patients',
                                'Appointments',
                                'Users',
                                'Prescriptions',
                                'Admissions',
                                'Action'
                            ]}
                            data={data.map((item) => [
                                item.hospitalName,
                                item.patientRegistrations,
                                item.appointments,
                                item.users,
                                item.prescriptions,
                                item.ipdAdmission,
                            ])}
                            actionButtons={[
                                {
                                    icon: (
                                        <Tooltip title="Delete Hospital" placement="top">
                                            <DeleteIcon
                                                sx={{
                                                    color: "red",
                                                    minWidth: "20.09px",
                                                    minHeight: "20.09px",
                                                    height: 20.09,
                                                    width: 29.09,
                                                    "&:hover": {
                                                        background: "none",
                                                        color: "red",
                                                    },
                                                }}
                                            />
                                        </Tooltip>
                                    ),
                                    onClick: (rowIndex: number) => openConfirmationDialog(data[rowIndex]._id),
                                },
                            ]}

                            loading={loading}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            totalRows={totalcount}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this project?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='text'>
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default HospitalStatistics;
