import { BrowserRouter } from 'react-router-dom';
import RoutesHOC from './routes/RoutesHOC';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTokenExpirationCheck from './hooks/useTokenExpirationCheck';
import ErrorBoundary from './components/error/ErrorBoundary';
const App = () => {
  useTokenExpirationCheck();
  return (
    <div>
      {/* <ErrorBoundary > */}
      <BrowserRouter>
        <RoutesHOC />
        <ToastContainer />
      </BrowserRouter>
      {/* </ErrorBoundary> */}

    </div>
  );
}

export default App;
