import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Grid,
  Typography,
  Divider,
  Checkbox,
  Dialog,
  Select,
  MenuItem,
  Tooltip,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicTable from "../../Dynamic-table";
import { useNavigate } from "react-router-dom";
import MedicineDoses, { MedicineDose } from "../../OPD/add-patient-record/MedicineDoses";
function formatDate(timestamp: string): string {
  const date = dayjs(timestamp);
  return date.format("DD/MM/YYYY - h:mma").toUpperCase();
}
interface DischargeModalProps {
  dischargeData: any;
  handleClose: () => void;
  isEmergency?: any;
  isRefDeathComp?: boolean;
  refStatus?: boolean;
  deathStatus?: boolean;
  handleCloseRefer?: (() => void | undefined) | undefined
  referal?: boolean
  refId?: any
}

const DischargeModal: React.FC<DischargeModalProps> = ({
  dischargeData,
  handleClose,
  isEmergency,
  refStatus,
  deathStatus,
  isRefDeathComp,
  referal,
  handleCloseRefer
}) => {
  const [formData, setFormData] = useState<any>({
    mrn: 0,
    patientName: "",
    guardiansName: "",
    sex: "",
    age: "",
    weight: "",
    admissionDateAndTime: dayjs().format('DD.MM.YYYY hh:mm A'),
    dateOfDischarge: dayjs().format('DD.MM.YYYY hh:mm A'),
    diagnosisSignificant: "",
    surgeonName: "",
    procedureDone: "",
    anaesthesia: "",
    procedureNote: "",
    dischargeAdvisedBy: "",
    conditionOnDischarge: "",
    lama: true,
    dor: false,
    systematicExamination: {},
    discharge_note: '',
    medicines: [],
    examination: [],
    diagnosis: [],
    referalNote: '',
    referTo: '',
    resonOfRefer: ''
  });

  const [secondOpen, setSecondOpen] = useState(false);
  const [apiData, setApiData] = useState<any>(null);
  const [discountPercentage, setDiscountPercentage] = useState<number | undefined>();
  const [discountRupees, setDiscountRupees] = useState<number | undefined>();
  const [netCharges, setNetCharges] = useState(0);
  const [totalNetCharges, setTotalNetCharges] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [toPayAmount, setToPayAmount] = useState<number>(0);
  const [searchId, SetSerachId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [isRefDeathComps, setIsRefDeathComps] = useState<any>(isRefDeathComp)
  const [payableamount, setPayableAmount] = useState(0);
  const navigate = useNavigate();
  const [medicineData, setMedicineData] = useState<MedicineDose[]>([]);
  const [open, setOpen] = useState<boolean>(false)

  const handleAdvanceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = Math.max(0, Number(e.target.value));
    setAdvanceAmount(value);
  };

  const calculateNetCharges = () => {
    let discount = discountRupees ?? 0;
    const percentage = discountPercentage ?? 0;

    if (percentage > 0) {
      discount = (totalNetCharges * percentage) / 100;
    }

    const netCharges = parseFloat((totalNetCharges - discount).toFixed(1));
    setNetCharges(netCharges);

    const payableamount = parseFloat((netCharges - advanceAmount).toFixed(1));

    setPayableAmount(payableamount);
    if (toPayAmount > 0) {
      setRemainingAmount(payableamount - toPayAmount);
    } else {
      setRemainingAmount(payableamount);
    }
  };

  const handleDiscountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    const numericValue = Math.max(0, Number(value.replace(/^0+/, "")));

    if (name === "discountPercentage") {
      const percentage = Math.min(100, numericValue);
      setDiscountPercentage(percentage || undefined);
      setDiscountRupees((totalNetCharges * percentage) / 100 || 0);
    } else if (name === "discountRupees") {
      const discount = Math.min(totalNetCharges, numericValue);
      setDiscountRupees(discount || undefined);
      setDiscountPercentage((discount / totalNetCharges) * 100 || 0);
    }
    calculateNetCharges();
  };

  useEffect(() => {
    setSelectedStatus("paid")
  }, [selectedStatus])

  useEffect(() => {
    if (apiData) {
      const servicesTotalNetCharges = apiData?.services?.reduce(
        (total: number, service: any) => total + service?.totalCharges,
        0
      );
      let totalcherges =
        servicesTotalNetCharges +
        apiData?.rooms.reduce((sum: number, obj: any) => {
          return sum + obj.totalCharges;
        }, 0);

      setTotalNetCharges(totalcherges);
      calculateNetCharges();
      setAdvanceAmount(apiData?.advancePayment);
    }
  }, [apiData, discountPercentage, discountRupees, totalNetCharges]);
  useEffect(() => {
    getDisAndTotal();
  }, [searchId, totalNetCharges, apiData?.advancePayment]);

  useEffect(() => {
    if (totalNetCharges > 0) {
      calculateNetCharges();
    }
  }, [discountPercentage, discountRupees]);
  const handlePayableChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value === "") {
      setToPayAmount(0);
      setRemainingAmount(payableamount);
      return;
    }
    let numericValue = Math.max(0, Number(value));
    if (numericValue > payableamount) {
      numericValue = payableamount;
    }
    setToPayAmount(numericValue);

    // Recalculate the remaining amount
    setRemainingAmount(parseFloat((payableamount - numericValue).toFixed(1)));
  };

  const resetForm = () => {
    setFormData({
      mrn: 0,
      patientName: "",
      guardiansName: "",
      sex: "",
      age: "",
      weight: "",
      admissionDateAndTime: dayjs(),
      dateOfDischarge: dayjs(),
      briefHistory: "",
      diagnosisSignificant: "",
      surgeonName: "",
      procedureNote: "",
      dischargeAdvisedBy: "",
      conditionOnDischarge: "",
      lama: true,
      dor: false,
      medicines: [],
      examination: [],
      diagnosis: [],
      procedureDone: '',
      referalNote: '',
      referTo: '',
      resonOfRefer: ''
    });
  };
  const calculateAge = (dob: string) => {
    const dobDate = dayjs(dob);
    const today = dayjs();
    const age = today.diff(dobDate, "year");
    return age;
  };
  useEffect(() => {
    const dob = dischargeData?.patientData?.dob;
    const age = calculateAge(dob);
    setFormData({
      mrn: dischargeData?.mrn,
      patientName: dischargeData?.patientName,
      guardiansName: dischargeData?.guardiansName,
      sex: dischargeData?.patientData?.gender?.charAt(0),
      age: age?.toString(),
      weight: dischargeData?.weight,
      admissionDateAndTime: dayjs(dischargeData?.admissionDateAndTime),
      surgeonName: dischargeData?.doctorName,
      dateOfDischarge: dayjs(formData?.dateOfDischarge).format('DD.MM.YYYY hh:mm A'),
    });
  }, [dischargeData]);
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  // const handleDateTimeChange = (formattedDate: any) => {
  //   if (formattedDate) {
  //     setFormData({
  //       ...formData,
  //       admissionDateAndTime: formattedDate,
  //     });
  //   }
  // };
  const handleDischargeDateTimeChange = (formattedDate: any) => {
    if (formattedDate) {
      setFormData({
        ...formData,
        dateOfDischarge: formattedDate,
      });
    }
  };
  const getDisAndTotal = () => {
    if (searchId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_IPD_API_URL}/ipd-clearance/findOneByAdmissionId/${searchId}`,
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          const data = response?.data?.data;
          setDiscountRupees(data?.discountRS);
          setDiscountPercentage(
            (data?.discountRS / totalNetCharges) * 100 || 0
          );
          setSelectedStatus(data?.paidStatus);
          setToPayAmount(data?.paidAmmount);
          setNetCharges(data?.netCharges);
          setRemainingAmount(data?.dueAmmount);
        })
        .catch((error) => {
          console.error("Error fetching discharge data:", error);
        });
    }
  };

  const updateStatus = async (id: any, isEmergency: boolean) => {
    const url = isEmergency
      ? `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateEmergencyStatusById/${id}`
      : `${process.env.REACT_APP_IPD_API_URL}/ipd-admission/updateIpdAdmissionStatusById/${id}`;

    const data = isEmergency
      ? { emergencyStatus: referal ? "Referred" : "Discharge" }
      : { patientStatus: referal ? "Referred" : "Discharge" };

    try {
      await axios.patch(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const fetchApiData = async (id: any) => {
    const url = `${process.env.REACT_APP_IPD_API_URL}/ipd-admission-services/getByIpdAdmissionId/${id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setApiData(response?.data?.data);
    } catch (error) {
      toast.error("Failed to fetch API data.");
    }
  };

  useEffect(() => {
    if (isRefDeathComps) {
      fetchApiData(dischargeData?._id)
      setSecondOpen(true)
      SetSerachId(dischargeData?._id)
    }
  }, [isRefDeathComp])

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/CreateFormData`,
        {
          formName: referal ? "Patient Refferal Form" : "DischargeForm",
          formData: formData,
          searchId: dischargeData?._id,
          patientId: dischargeData?.patientData?._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setSecondOpen(true);
      SetSerachId(response?.data?.data?.searchId);
      setIsRefDeathComps(true)

      await fetchApiData(dischargeData?._id);
      toast.success(response?.data?.message);
      resetForm();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleDischarge = async () => {
    let data = JSON.stringify({
      ipdAdmissionId: dischargeData?._id,
      totalCharges: totalNetCharges || 0,
      discountRS: discountRupees || 0,
      netCharges: netCharges || 0,
      paidAmmount: toPayAmount || 0,
      dueAmmount: remainingAmount || 0,
      paidStatus: selectedStatus,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_IPD_API_URL}/ipd-clearance/createIPDClearance`,
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      toast.success(response?.data?.message);
      // if (
      //   (refStatus === false && deathStatus === false) ||
      //   isEmergency === false
      // ) {
      await updateStatus(dischargeData?._id, isEmergency);
      // }
      // handleSecondClose();
      handleClose();
      navigate("/ipd-dashboard");
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };
  const getDischargeData = async (searchId: string, formName: string) => {
    const url = `${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${searchId}?formName=${formName}`;
    try {
      const response = await axios.get(url, {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const fetchData = response?.data?.data?.formData;
      const age = calculateAge(fetchData?.dob);

      const admissionDate = dayjs(fetchData?.admissionDateAndTime)
      if (fetchData?.mrn) {
        setFormData({
          mrn: fetchData?.mrn,
          patientName: fetchData?.patientName,
          guardiansName: fetchData?.guardiansName,
          sex: fetchData?.patientData?.gender.charAt(0),
          age: age?.toString(),
          weight: fetchData?.weight,
          admissionDateAndTime: admissionDate,
          surgeonName: fetchData?.doctorName,
          dateOfDischarge: dayjs(fetchData?.dateOfDischarge),
          ...fetchData,
        });
      } else {
        // setFormData({
        //   ...formData,
        // })
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };
  const handleSecondClose = async () => {
    await getDischargeData(searchId, "DischargeForm");
    setSecondOpen(false);
    setIsRefDeathComps(false)
    if (isRefDeathComp) {
      handleClose()
      navigate(`/referal-form/${searchId}`, { state: { refData: dischargeData } })
      // handleCloseRefer()
      // console.log('come back');

      //  await  getDischargeData(dischargeData?._id, "Patient Refferal Form");
    }
  };
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
  }

  const fetchFormData = async (formName: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_IPD_API_URL}/ipd-form-data/getFormDataBySearchId/${dischargeData?._id}?formName=${formName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const fetchedData = await response?.data?.data?.formData
      if (formName === "History") {
        setFormData((prevData: any) => ({
          ...prevData,
          briefHistory: fetchedData?.detailed_History,
          presentingComplaints: fetchedData?.presenting_Complaints
        }));
      } else if (formName == "Examination") {
        setFormData((prevData: any) => ({
          ...prevData,
          examinations: fetchedData?.examinations,
          systematicExamination: fetchedData?.examinations_Without_GPE
        }))
      }
      else if (formName == "OT") {
        setFormData((prevData: any) => ({
          ...prevData,
          procedureDone: fetchedData?.status
        }))
      }
      else if (formName == "ot_room_anaesthesia_monitoring") {
        setFormData((prevData: any) => ({
          ...prevData,
          anaesthesia: fetchedData?.detail
        }))
      }
      else if (formName == "Treatment_Chart") {
        setMedicineData(fetchedData?.medicines || [])
      }
      else if (formName == "Pre_Surgical") {
        setFormData((prevData: any) => ({
          ...prevData,
          diagnosis: fetchedData?.diagnosis
        }))
      } else if (formName === "DischargeForm") {
        if (fetchedData) {
          setMedicineData(fetchedData?.medicines || []);
          setFormData((prevData: any) => ({
            ...prevData,
            conditionOnDischarge: fetchedData?.conditionOnDischarge,
            dischargeAdvisedBy: fetchedData?.dischargeAdvisedBy,
            procedureNote: fetchedData?.procedureNote,
            discharge_note: fetchedData?.discharge_note,
            dischargeDate: fetchedData?.dischargeDate,
            lama: fetchedData?.lama,
            dor: fetchedData?.dor,
            dateOfDischarge: fetchedData?.dateOfDischarge,
            diagnosisSignificant: fetchedData?.diagnosisSignificant
          }));
        } else {
          fetchFormData('Treatment_Chart');
        }
      } else if (formName === "Patient Refferal Form") {
        if (fetchedData) {
          setMedicineData(fetchedData?.medicines || []);
          setFormData((prevData: any) => ({
            ...prevData,
            conditionOnDischarge: fetchedData?.conditionOnDischarge,
            dischargeAdvisedBy: fetchedData?.dischargeAdvisedBy,
            dischargeDate: fetchedData?.dischargeDate,
            dateOfDischarge: fetchedData?.dateOfDischarge,
            resonOfRefer: fetchedData?.resonOfRefer,
            referTo: fetchedData?.referTo,
            referalNote: fetchedData?.referalNote,
            diagnosisSignificant: fetchedData?.diagnosisSignificant
          }));
        } else {
          fetchFormData('Treatment_Chart');
        }
      }
    }
    catch (error) {
      toast.error('Failed to fetch form data.');
    }
  };

  useEffect(() => {
    // Always fetch the common forms
    fetchFormData('History');
    fetchFormData('Examination');
    fetchFormData('Pre_Surgical');
    fetchFormData('OT');
    fetchFormData('ot_room_anaesthesia_monitoring');
    fetchFormData('Treatment_Chart');

    // Fetch either the referral form or discharge form based on the `referral` prop
    if (referal) {
      fetchFormData('Patient Refferal Form');
    } else {
      fetchFormData('DischargeForm');
    }
  }, [referal]);

  const handleMedicineDoses = (medicineDoses: MedicineDose[], deletedInfo?: { medicine: MedicineDose; deletedTime: string; deletedBy: string }) => {
    const updatedFormData: any = {
      ...formData,
      medicines: medicineDoses,
    };
    setFormData(updatedFormData);
  };

  return (
    <Box sx={{ p: 1, background: '#fff' }}>
      {!isRefDeathComps ? <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            {referal ? "Refferal Form" : "Discharge Form"}
          </Typography>
          <Box display='flex' alignItems='center' gap={1}>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Name : {formData?.patientName}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Guardian : {formData?.guardiansName}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              MRN : {formData?.mrn}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Sex : {formData?.sex}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Age : {formData?.age}
            </Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
              Weight : {formData?.weight}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: "4px", mb: 1 }} />
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                Admission Date and Time:
              </Typography>
              <Box
                sx={{
                  "& .css-a643pv-MuiStack-root": {
                    paddingTop: "0px",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disabled
                    value={
                      dayjs(formData?.admissionDateAndTime)
                    }
                    format='DD.MM.YYYY hh:mm A'
                    // onChange={(newValue) => {
                    //   const formattedDate =
                    //     newValue && dayjs(newValue).isValid()
                    //       ? dayjs(newValue)
                    //       : null;
                    //   handleDateTimeChange(formattedDate);
                    // }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "38px",
                        fontSize: "12px",
                        overflow: "hidden",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        border: "none",
                        backgroundColor: "#F5F5FB",
                      },
                      "& .MuiCalendarPicker-root": {
                        overflow: "hidden",
                      },
                      "& .css-4jnixx-MuiStack-root": {
                        paddingTop: "0px",
                      },
                      width: "100%",
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={2.4}>
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                Discharge Date and Time:
              </Typography>
              <Box
                sx={{
                  "& .css-a643pv-MuiStack-root": {
                    paddingTop: "0px",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={
                      dayjs(formData?.dateOfDischarge)
                    }
                    format='DD.MM.YYYY hh:mm A'
                    onChange={(newValue) => {
                      const formattedDate =
                        newValue && dayjs(newValue)?.isValid()
                          ? newValue?.toISOString()
                          : null;
                      handleDischargeDateTimeChange(formattedDate);
                    }}
                    minDate={dayjs().startOf("day")}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "38px",
                        fontSize: "12px",
                        overflow: "hidden",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        border: "none",
                        backgroundColor: "#F5F5FB",
                      },
                      "& .MuiCalendarPicker-root": {
                        overflow: "hidden",
                      },
                      "& .css-4jnixx-MuiStack-root": {
                        paddingTop: "0px",
                      },
                      width: "100%",
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Presenting Complaints :
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#000', p: 0.5 }} className="search-field">
                {
                  formData?.presentingComplaints
                    ? Object.keys(formData?.presentingComplaints)?.slice(0, 2)?.map((key) => {
                      const value = formData?.presentingComplaints[key];
                      if (typeof value === 'object' && value !== null) {
                        return Object.keys(value)?.join(', ');
                      }
                      return key;
                    }).join(', ')
                    : 'No Presenting Complaints'
                }

                {formData?.presentingComplaints && Object.keys(formData?.presentingComplaints)?.length > 2 && (
                  <Tooltip
                    placement="top"
                    title={Object.keys(formData?.presentingComplaints)
                      .slice(2)
                      .map((key) => {
                        const value = formData?.presentingComplaints[key];
                        if (typeof value === 'object' && value !== null) {
                          return Object.keys(value).join(', ');
                        }
                        return key;
                      })
                      .join(', ')}
                    arrow
                  >
                    <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                      +{Object.keys(formData?.presentingComplaints).length - 2} more
                    </span>
                  </Tooltip>
                )}
              </Typography>

            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Brief History :
              </Typography>
              <TextField
                fullWidth
                placeholder="Brief History"
                name="briefHistory"
                multiline
                value={formData?.briefHistory}
                size="small"
                className="search-field"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                GPE Examination :
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#000', p: 0.5 }} className="search-field">
                {
                  formData?.examinations?.slice(0, 2)?.map((test: any) => test)?.join(', ') || 'No Examinations'
                }
                {formData?.examinations?.length > 2 && (
                  <Tooltip placement='top'
                    title={formData?.examinations?.slice(2)?.map((test: any) => test)?.join(', ')}
                    arrow
                  >
                    <span style={{ cursor: 'pointer', color: '#1976d2' }}> +{formData?.examinations?.length - 2} more</span>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Systematic Examination :
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#000', p: 0.5 }} className="search-field">
                {
                  formData?.systematicExamination
                    ? Object.keys(formData?.systematicExamination)?.slice(0, 2)?.map((key) => {
                      const value = formData?.systematicExamination[key];
                      if (typeof value === 'object' && value !== null) {
                        return Object.keys(value)?.join(', ');
                      }
                      return key;
                    }).join(', ')
                    : 'No Examinations'
                }
                {formData?.systematicExamination && Object.keys(formData?.systematicExamination)?.length > 2 && (
                  <Tooltip
                    placement="top"
                    title={Object.keys(formData?.systematicExamination)
                      ?.slice(2)
                      ?.map((key) => {
                        const value = formData?.systematicExamination[key];
                        if (typeof value === 'object' && value !== null) {
                          return Object.keys(value)?.join(', ');
                        }
                        return key;
                      })
                      ?.join(', ')}
                    arrow
                  >
                    <span style={{ cursor: 'pointer', color: '#1976d2' }}>
                      +{Object.keys(formData?.systematicExamination)?.length - 2} more
                    </span>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "8px",
                  textTransform: "capitalize",
                }}
              >
                Diagnosis Investigation Significant Results: Photocopy given to
                patient :
              </Typography>
              <RadioGroup
                name="diagnosisSignificant"
                value={formData?.diagnosisSignificant}
                onChange={handleChange}
                row
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}
              >
                <FormControlLabel sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  margin: 0,
                  gap: 0.5,
                }}
                  value="significant"
                  control={<Radio size="small" sx={{
                    "& .MuiSvgIcon-root": {
                      height: 12,
                      width: 12,
                    },
                    "&.MuiRadio-root": {
                      padding: 0,
                    },
                    "& .MuiButtonBase-root": {
                      padding: 0,
                      "&:hover": {
                        background: "none",
                      },
                    },
                  }} />}
                  label={<span style={{ fontSize: '10px', }}>Yes</span>}
                />
                <FormControlLabel sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  margin: 0,
                  gap: 0.5,
                }}
                  value="notSignificant"
                  control={<Radio size="small" sx={{
                    "& .MuiSvgIcon-root": {
                      height: 12,
                      width: 12,
                    },
                    "&.MuiRadio-root": {
                      padding: 0,
                    },
                    "& .MuiButtonBase-root": {
                      padding: 0,
                      "&:hover": {
                        background: "none",
                      },
                    },
                  }} />}
                  label={<span style={{ fontSize: '10px', }}>No</span>}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Diagnosis :
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#000', p: 0.5 }} className="search-field">
                {
                  formData?.diagnosis?.slice(0, 2)?.map((test: any) => test)?.join(', ') || 'No Diagnosis'
                }
                {formData?.diagnosis?.length > 2 && (
                  <Tooltip placement='top'
                    title={formData?.diagnosis?.slice(2)?.map((test: any) => test)?.join(', ')}
                    arrow
                  >
                    <span style={{ cursor: 'pointer', color: '#1976d2' }}> +{formData?.diagnosis?.length - 2} more</span>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Surgeon Name :
              </Typography>
              <TextField
                disabled
                fullWidth
                placeholder="Surgeon Name"
                name="surgeonName"
                value={formData.surgeonName}
                onChange={handleChange}
                className="search-field"
                size="small"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Procedure Done :
              </Typography>
              <TextField
                fullWidth
                placeholder="Procedure Done"
                name="procedureDone"
                value={formData?.procedureDone}
                // onChange={handleChange}
                className="search-field"
                disabled
                size="small"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Anaesthesia :
              </Typography>
              <TextField
                fullWidth
                placeholder="Anaesthesia"
                name="anaesthesia"
                value={formData?.anaesthesia}
                // onChange={handleChange}
                disabled
                className="search-field"
                size="small"
              />
            </Grid>
            {!referal && <Grid item xs={6} sm={4} md={2.4}>
              <Typography
                sx={{ fontWeight: "bold", color: "#000", fontSize: "12px" }}
              >
                Discharge Note
              </Typography>
              <TextField
                fullWidth
                placeholder="Discharge Note"
                name="discharge_note"
                value={formData?.discharge_note}
                onChange={handleChange}
                className="search-field"
                size="small"
              />
            </Grid>}

            {!referal && <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Procedure Note :
              </Typography>
              <TextField
                fullWidth
                placeholder="Procedure Note"
                name="procedureNote"
                multiline
                rows={4}
                value={formData?.procedureNote}
                onChange={handleChange}
                className="search-field"
                size="small"
              />
            </Grid>}
            {referal && <Grid item xs={6} sm={4} md={2.4}>
              <Typography
                sx={{ fontWeight: "600", color: "#000", fontSize: "12px" }}
              >
                Refer Note
              </Typography>
              <TextField
                fullWidth
                placeholder={referal ? "Refer Note" : "Discharge Note"}
                name="referalNote"
                value={formData?.referalNote}
                onChange={handleChange}
                className="search-field"
                size="small"
              />
            </Grid>}
            {!referal && <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Discharge Advised By :
              </Typography>
              <TextField
                fullWidth
                placeholder="Discharge Advised By"
                name="dischargeAdvisedBy"
                value={formData.dischargeAdvisedBy}
                className="search-field"
                onChange={handleChange}
                size="small"
              />
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.lama}
                      onChange={handleChange}
                      name="lama"
                      size="small"
                    />
                  }
                  label="LAMA"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.dor}
                      onChange={handleChange}
                      size="small"
                      name="dor"
                    />
                  }
                  label="DOR"
                />
              </Box>
            </Grid>}
            {
              referal && (
                <Grid item xs={6} sm={4} md={2.4}>
                  <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                    Referring To :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Referring To"
                    name="referTo"
                    value={formData.referTo}
                    onChange={handleChange}
                    className="search-field"
                    size="small"
                  />
                </Grid>
              )
            }
            {
              referal && (
                <Grid item xs={6} sm={4} md={2.4}>
                  <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                    Reason of Refer :
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Referring Reason"
                    name="resonOfRefer"
                    value={formData?.resonOfRefer}
                    onChange={handleChange}
                    className="search-field"
                    size="small"
                  />
                </Grid>
              )
            }
            <Grid item xs={6} sm={4} md={2.4}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                Condition at the time of  {referal ? "Refer" : "Discharge"} :
              </Typography>
              <RadioGroup
                row
                name="conditionOnDischarge"
                value={formData.conditionOnDischarge}
                onChange={handleChange}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="satisfactory"
                  control={<Radio size="small" />}
                  label="Satisfactory"
                />
                <FormControlLabel
                  value="fair"
                  control={<Radio size="small" />}
                  label="Fair"
                />
                <FormControlLabel
                  value="poor"
                  control={<Radio size="small" />}
                  label="Poor"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item md={12} sm={12} xs={12} sx={{ mt: 1, mb: 1 }}>
              <MedicineDoses
                onMedicineDoses={handleMedicineDoses}
                medicineDataIfExist={medicineData}
                setMedicineData={setMedicineData}
                medicineData={medicineData}
                treatment={true}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
            gap={1}
            alignItems="center"
          >
            <Button onClick={handleClose} variant="outlined">
              Back
            </Button>
            <Button onClick={handleOpenModal} variant="contained" color="primary">
              {referal ? "Reffer" : "Clearance"}
            </Button>
          </Box>
          <Dialog open={open} >
            <DialogContent>
              <Typography>Are You Sure? You Want To {referal ? "Refer" : "Discharge"} Patient </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                No
              </Button>
              <Button
                onClick={
                  handleSubmit // Run submit function when "Yes" is clicked
                  // handleCloseModal(); // Close the modal
                }
                variant="contained"
                color="primary"
              >
                Yes
              </Button>
            </DialogActions>

          </Dialog>
        </Box>

      </> :
        <Dialog
          open={secondOpen}
          // onClose={handleSecondClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: "12px",
              p: 2,
            },
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Clearance Form
          </Typography>
          {apiData?.rooms?.length > 0 && (
            <Box>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}
              >
                Room Charges
              </Typography>
              <DynamicTable
                headers={[
                  "SN",
                  "Category",
                  "Floor",
                  "Room",
                  "Bed No",
                  "Allocation Date & Time",
                  "Leave Date & Time",
                  "Status",
                  "Total Charges",
                ]}
                data={apiData?.rooms.map((room: any, index: any) => [
                  index + 1,
                  room?.roomCategoryName,
                  room?.floorName,
                  room?.roomName,
                  room?.bedNo,
                  formatDate(room?.allotedDate),
                  formatDate(room?.vacantDate),
                  room?.survivingTime,
                  room?.totalCharges,
                ])}
              />
            </Box>
          )}
          <Typography
            sx={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}
          >
            Services Charges
          </Typography>
          <DynamicTable
            headers={[
              "Services",
              "Unit Charges",
              "Unit Count",

              "Discount",
              "Net Charges",
            ]}
            data={apiData?.services?.map((test: any) => [
              test?.servicesName,
              test?.unitCharges,
              test?.unitCount,

              test?.discount,
              test?.totalCharges,
            ])}
          />

          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{
              backgroundColor: "#ECECEC",
              marginTop: "20px",
              borderRadius: "8px",
              px: 0.5,
              py: 1,
              "&.MuiGrid-root": {
                paddingTop: "0px",
              },
            }}
          >
            {[
              {
                label: "Charges:",
                value: totalNetCharges ? `${totalNetCharges?.toFixed(1)} /-` : "0",
              },
              {
                label: "Discount %:",
                value: (
                  <TextField
                    type="text"
                    name="discountPercentage"
                    value={
                      discountPercentage !== undefined
                        ? +discountPercentage.toFixed(2)
                        : ""
                    }
                    onChange={handleDiscountChange}
                    className="search-field"
                    sx={{
                      width: "100px",
                      backgroundColor: "white",
                    }}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                    }}
                    size="small"
                  />
                ),
              },
              {
                label: "Discount Rs:",
                value: (
                  <TextField
                    type="text"
                    name="discountRupees"
                    value={discountRupees !== undefined ? discountRupees : ""}
                    onChange={handleDiscountChange}
                    className="search-field"
                    size="small"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                    }}
                    sx={{ width: "100px", backgroundColor: "white" }}
                  />
                ),
              },
              {
                label: "Net Charges:",
                value: (
                  <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    {netCharges ? `${netCharges.toFixed(1)} Rs` : "0 Rs"}
                  </Typography>
                ),
              },
              {
                label: "Advance:",
                value: `${advanceAmount ? advanceAmount.toFixed(1) : 0} Rs`,
              },
              {
                label: "Payable Amount:",
                value: `${payableamount ? payableamount.toFixed(1) : 0} Rs`,
              },
              {
                label: "Enter Amount:",
                value: (
                  <TextField
                    type="text"
                    value={toPayAmount !== undefined ? toPayAmount : ""}
                    onChange={handlePayableChange}
                    className="search-field"
                    size="small"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                    }}
                    sx={{ width: "100px", backgroundColor: "white" }}
                  />
                ),
              },
              {
                label: "Remaining:",
                value: `${remainingAmount ? remainingAmount.toFixed(1) : 0} Rs`,
              },
            ].map(({ label, value }) => (
              <Grid
                item
                key={label}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  ml: 1,
                }}
              >
                <Typography sx={{ fontSize: "12px", marginRight: "4px" }}>
                  {label}
                </Typography>

                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  {value}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
            gap={1}
            alignItems="center"
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
              Status
            </Typography>
            <Select
              sx={{ height: "40px", fontSize: "14px" }}
              value={selectedStatus}  // Ensure that this matches the state
              onChange={(event: any) => setSelectedStatus(event.target.value)} // Update state on change
            >
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unpaid">Unpaid</MenuItem>
            </Select>
            {/* <Button onClick={handleSecondClose} variant="outlined">
              Back
            </Button> */}

            <Button
              // disabled={!selectedStatus}
              onClick={handleDischarge}
              variant="contained"
              color="primary"
            >
              Discharge
            </Button>
          </Box>
        </Dialog>
      }
    </Box>
  );
};

export default DischargeModal;
