import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationModal from "../../../../Modals/delete-modal";
import DynamicTable from "../../../../Dynamic-table";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Loader from "../../../../loader/loader";

interface MedicineTypeProps {
  onClose: (value: boolean) => void;
  showTypeForm: boolean;
}

const MedicineType: React.FC<MedicineTypeProps> = ({
  onClose,
  showTypeForm,
}) => {
  const [medicineName, setMedicineName] = useState("");
  const [editData, setEditData] = useState(null); // State to hold data being edited
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [medicineTypeData, setMedicineTypeData] = useState<any>();
  const [searchInput, setSearchInput] = useState("");
  const [newUnitName, setNewUnitName] = useState<any>("");
  const [unitNames, setUnitNames] = useState<any[]>([]);
  const [medicineTypeAbrivation, setMedicineTypeAbrivation] = useState("");
  const [checkAbr, setCheckAbr] = useState(false);
  const [total, setTotal] = useState<any>();
  const [loading, setloading] = useState(false);
  const [loadingBtn, setloadingBtn] = useState(false);

  const fetchData = async () => {
    setloading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_OPD_API_URL}/medicinetype/getAll`,
        {
          params: {
            pageNo: page + 1,
            count: rowsPerPage,
            search: searchInput,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMedicineTypeData(response.data?.data);
      setTotal(response.data?.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setloading(false);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (id: any, name: string, units: any, abr: any) => {
    setCheckAbr(true);
    setEditData(id); // Set data being edited
    setMedicineName(name); // Fill text field with data
    setUnitNames(units);
    setMedicineTypeAbrivation(abr);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, searchInput]);

  const handleSubmit = async () => {
    setloadingBtn(true);
    if (editData) {
      // Update existing medicine type
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_OPD_API_URL}/medicinetype/updateById/${editData}`,
          {
            medicineTypeName: medicineName,
            units: unitNames,
            medicineTypeAbrivation: medicineTypeAbrivation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.data.isSuccess) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);

          // Clear form and reset state after successful update
          setMedicineName("");
          setMedicineTypeAbrivation("");
          setUnitNames([]);
          setEditData(null); // Reset edit state

          fetchData();
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setloadingBtn(false);
      }
    } else {
      // Add new medicine type
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_OPD_API_URL}/medicinetype/createMedicineType`,
          {
            medicineTypeName: medicineName,
            units: unitNames,
            medicineTypeAbrivation: medicineTypeAbrivation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.data.isSuccess) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);

          // Clear form and reset state after successful add
          setMedicineName("");
          setMedicineTypeAbrivation("");
          setUnitNames([]);

          fetchData();
          if (showTypeForm) {
            onClose(false);
          }
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setloadingBtn(false);
      }
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteClick = (id: any) => {
    setIsDeleteModalOpen(true);
    setSelectedItemId(id);
  };

  const handleDeleteConfirm = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authorization token is missing");
        return;
      }

      const response = await axios.delete(
        `${process.env.REACT_APP_OPD_API_URL}/medicinetype/deleteById/${selectedItemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);

      // Reset the state if the deleted item was being edited
      if (editData === selectedItemId) {
        setEditData(null);
        setMedicineName("");
        setMedicineTypeAbrivation("");
        setUnitNames([]);
      }

      fetchData(); // Refresh data after deletion
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsDeleteModalOpen(false); // Close modal after deletion
      setSelectedItemId(""); // Reset selected item ID
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleAddTestName = () => {
    const trimmedName = newUnitName.trim();
    if (trimmedName !== "") {
      if (!unitNames.includes(trimmedName)) {
        setUnitNames([...unitNames, trimmedName]);
        setNewUnitName("");
      } else {
        toast.error(`Test name "${trimmedName}" already exists.`);
      }
    }
  };

  const handleDeleteTestName = (index: number) => {
    const updatedTestNames = [...unitNames];
    updatedTestNames.splice(index, 1);
    setUnitNames(updatedTestNames);
  };

  useEffect(() => {
    if (medicineName && !checkAbr) {
      setMedicineTypeAbrivation(medicineName.substring(0, 3));
    }
  }, [medicineName]);

  return (
    <Box>
      <Box
        sx={{
          background: "#fff",
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "600", width: "70%" }}>
          Add Medicine Type
        </Typography>
        <Box
          display="flex"
          sx={{ justifyContent: showTypeForm ? "flex-end" : "space-between" }}
          alignItems="center"
          width="40%"
          gap={1}
        >
          <TextField
            placeholder="Search Medicine Type"
            variant="outlined"
            value={searchInput}
            onChange={handleSearchInputChange}
            fullWidth
            size="small"
            className="search-field"
            sx={{ display: showTypeForm ? "none" : "" }}
          />
          <CancelOutlinedIcon
            onClick={() => onClose(false)}
            sx={{ cursor: "pointer" }}
          />
        </Box>
      </Box>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={showTypeForm ? 12 : 4} sm={12} xs={12}>
            <Box
              sx={{
                background: "#fff",
                p: 2,
                borderRadius: "4px",
                minHeight: "65vh",
                position: "relative",
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                Medicine Type :{" "}
              </Typography>
              <TextField
                size="small"
                placeholder="Enter Type"
                name="name"
                value={medicineName}
                fullWidth
                required
                onChange={(e: any) => setMedicineName(e.target.value)}
                className="search-field"
              />

              <Typography sx={{ fontSize: "16px", mt: 2 }}>
                Medicine Abriviation :{" "}
              </Typography>
              <TextField
                size="small"
                placeholder="Enter Abriviation"
                name="name"
                value={medicineTypeAbrivation}
                fullWidth
                required
                onChange={(e: any) => setMedicineTypeAbrivation(e.target.value)}
                className="search-field"
              />

              <Box mt={2} display="flex" flexDirection="column" gap={1}>
                <Typography sx={{ fontSize: "16px" }}>
                  Medicine Unit :{" "}
                </Typography>
                <TextField
                  size="small"
                  required
                  placeholder="Enter Unit"
                  name="name"
                  value={newUnitName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewUnitName(e.target.value)
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === "Enter") {
                      handleAddTestName();
                    }
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disabled={!newUnitName}
                          variant="contained"
                          sx={{ px: 1, py: 0.2 }}
                          onClick={handleAddTestName}
                        >
                          +
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  className="search-field"
                />
                <Box display="flex" flexWrap="wrap" mt={1} gap={0.2}>
                  {unitNames?.map((name, index) => (
                    <Chip
                      key={index}
                      label={name}
                      onDelete={() => handleDeleteTestName(index)}
                      sx={{
                        borderRadius: "8px",
                        background: "#c0c0c0",
                        fontSize: "12px",
                        height: "24px",
                        "&:hover": {
                          background: "#055FFC",
                          color: "#fff",
                          "& .MuiChip-deleteIcon": {
                            color: "#fff", // Change delete icon color on hover
                          },
                          "& .MuiChip-icon": {
                            color: "#fff", // Change icon color on hover if you have an icon
                          },
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                position="absolute"
                top="80%"
                right="10%"
              >
                {loadingBtn ? (
                  <Loader />
                ) : (
                  <Button
                    variant="contained"
                    disabled={!medicineName || unitNames.length < 1}
                    onClick={handleSubmit}
                  >
                    {editData ? "Update" : "Save"}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            sx={{ display: showTypeForm ? "none" : "" }}
          >
            <DynamicTable
              headers={[
                "SN",
                "Medicine",
                "Medicine Abbriviation",
                "Units",
                "Action",
              ]}
              data={medicineTypeData?.map((item: any, index: any) => [
                page * rowsPerPage + index + 1,
                item?.medicineTypeName,
                item?.medicineTypeAbrivation,
                item?.units?.length === 1 ? (
                  item?.units?.[0] // Show normally if only one unit
                ) : (
                  <Select
                    size="small"
                    placeholder="Units"
                    sx={{
                      borderRadius: "12px",
                      border: "0px solid #D8D8D8",
                      background: "#F5F5FB",
                      fontSize: "12px",
                      boxShadow: "none", // Remove any default shadow that might look like a border
                      "& fieldset": {
                        // Target the fieldset for better border control
                        border: "none", // Remove default border if present
                      },
                    }}
                    labelId={`units-select-${item._id}`}
                    defaultValue={item?.units?.[0]} // Set default value to the first unit
                    renderValue={(selected) => selected || "Select Unit"}
                  >
                    {item.units?.map((unit: string, unitIndex: number) => (
                      <MenuItem key={unitIndex} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                ),
              ])}
              loading={loading}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              totalRows={total} // Update this based on the total count from the API response
              actionButtons={[
                {
                  icon: (
                    <EditIcon
                      sx={{
                        color: "#FFB800",
                        minWidth: "20.09px",
                        minHeight: "20.09px",
                        height: 20.09,
                        width: 29.09,
                        "&:hover": {
                          background: "none",
                          color: "rgb(133, 96, 1)",
                        },
                      }}
                    />
                  ),
                  onClick: (rowIndex: any) =>
                    handleEditClick(
                      medicineTypeData[rowIndex]?._id,
                      medicineTypeData[rowIndex]?.medicineTypeName,
                      medicineTypeData[rowIndex]?.units,
                      medicineTypeData[rowIndex]?.medicineTypeAbrivation
                    ),
                },
                {
                  icon: (
                    <DeleteIcon
                      sx={{
                        color: "#FF5454",
                        minWidth: "20.09px",
                        minHeight: "20.09px",
                        height: 20.09,
                        width: 29.09,
                        "&:hover": {
                          background: "none",
                          color: "rgb(191, 44, 44)",
                        },
                      }}
                    />
                  ),
                  onClick: (rowIndex: any) =>
                    handleDeleteClick(medicineTypeData[rowIndex]?._id),
                },
              ]}
            />
          </Grid>
        </Grid>
        <DeleteConfirmationModal
          open={isDeleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleConfirm={handleDeleteConfirm}
        />
      </Box>
    </Box>
  );
};

export default MedicineType;
